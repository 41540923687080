import httpService, { customBaseQuery, ServerResponse } from './http.service';
import { storageService } from './storage.service';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

const PAYMENT_ENDPOINT = '/payment';

interface ConfirmTransaction {
	transaction_id: string;
	payment_gateway: string;
}

const getCheckoutTicket = async (sum: number): Promise<string> => {
	const { data } = await httpService.get<ServerResponse<string>>(
		`${PAYMENT_ENDPOINT}/checkout/${sum}`
	);
	return data.data;
};

const confirmMonerisPayment = async (ticket: string): Promise<void> => {
	await httpService.post<ServerResponse<string>>(`${PAYMENT_ENDPOINT}/confirm`, {
		ticket
	});
};

const confirmTransaction = async (payload: ConfirmTransaction): Promise<void> => {
	const { data } = await httpService.post<ServerResponse<string>>('users/addbalance', {
		...payload,
		user_id: storageService.getUserId()
	});
	if (data.status !== 200) return Promise.reject(new Error(data.message));
};

const isAvailableMembership = async (): Promise<boolean> => {
	const { data } = await httpService.get<{ message?: string }>(
		`${PAYMENT_ENDPOINT}/membership/available`
	);
	return !data.message;
};

const purchaseMembership = async (promo_code = ''): Promise<void> => {
	await httpService.post(`${PAYMENT_ENDPOINT}/membership`, {
		promo_code: promo_code
	});

};

const renewMembership = async (promo_code = ''): Promise<void> => {
	await httpService.patch(`${PAYMENT_ENDPOINT}/membership`, {
		promo_code: promo_code
	});
};


const paymentService = {
	getCheckoutTicket,
	confirmMonerisPayment,
	confirmTransaction,
	isAvailableMembership,
	purchaseMembership,
	renewMembership
};
export default paymentService;

export const paymentApi = createApi({
	reducerPath: 'paymentApi',
	baseQuery: customBaseQuery,
	tagTypes: ['MembershipPrice'],
	endpoints: (build) => ({
		getMembershipPrice: build.query<any, string | void>({
			query: (promo_code = '', isExpired = false) => ({
				url: `${PAYMENT_ENDPOINT}/membership/price`,
				params: { promo_code, isExpired }
			}),
			transformResponse: (resp: ServerResponse<any>) => {
				return resp;
			},
			providesTags: ['MembershipPrice']
		}),
		getExpiredMembershipPrice: build.query<any, boolean>({
			query: (isExpired = false) => ({
				url: `${PAYMENT_ENDPOINT}/membership/price`,
				params: { isExpired }
			}),
			transformResponse: (resp: ServerResponse<any>) => {
				return resp;
			},
			providesTags: ['MembershipPrice']
		}),
	})
});

export const { useGetMembershipPriceQuery, useGetExpiredMembershipPriceQuery } = paymentApi;
