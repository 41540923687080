import React from 'react';
import {
	DropdownMenuContent,
	DropdownMenuContentProps
} from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import style from './dropdown.module.scss';

type Props = {} & DropdownMenuContentProps;

export function DropdownMenu({ children, ...props }: Props) {
	return (
		<DropdownMenuContent
			{...props}
			className={classNames(style.menu, props.className)}
		>
			{children}
		</DropdownMenuContent>
	);
}
