import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { SupportRequest } from 'common/interfaces/user.interface';
import { useAppSelector } from 'hooks/redux';
import { selectUserData } from 'store/user/user.slice';
import { Input, InputTextArea } from 'components/ui/Input';
import { Button } from 'components/ui/Button';
import { FormAlert } from 'components/ui/FormAlert';
import userService from 'services/user.service';

const initialValues: SupportRequest = {
	email: '',
	name: '',
	subject: '',
	message: ''
};
const validationSchema = Yup.object({
	email: Yup.string().required().email(),
	name: Yup.string().required(),
	subject: Yup.string().required().min(5).max(100),
	message: Yup.string().required().min(5).max(200)
});

export function SupportForm() {
	const [error, setError] = useState<string>();
	const [success, setSuccess] = useState<string>();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isSubmitting }
	} = useForm<SupportRequest>({
		resolver: yupResolver(validationSchema),
		defaultValues: initialValues,
		mode: 'onTouched'
	});
	const user = useAppSelector(selectUserData);

	useEffect(() => {
		if (user) {
			reset({
				email: user.email,
				name: user.username
			});
		}
	}, [user]);

	const onSubmit = async (values: SupportRequest) => {
		if (!executeRecaptcha) return;
		const token = await executeRecaptcha('support');
		try {
			await userService.sendSupportMail({
				...values,
				app_type: 1,
				recaptcha: token
			});

			setSuccess('Message has been sent.');
		} catch (e: any) {
			setError(e.message);
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Input
					{...register('email')}
					label="Email"
					error={!!errors.email}
					helperText={errors.email?.message}
					disabled={!!user}
					fullWidth
				/>
				<Input
					{...register('name')}
					label="Username"
					error={!!errors.name}
					helperText={errors.name?.message}
					disabled={!!user}
					fullWidth
				/>

				<Input
					{...register('subject')}
					label="Subject"
					error={!!errors.subject}
					helperText={errors.subject?.message}
					fullWidth
				/>
				<InputTextArea
					{...register('message')}
					label="Message to support"
					error={!!errors.message}
					helperText={errors.message?.message}
					rows={6}
					maxLength={200}
					fullWidth
				/>

				<Button
					className="plr-160 mt-32"
					type="submit"
					color="primary"
					disabled={isSubmitting}
					fullWidth
				>
					Submit
				</Button>
			</form>
			{error && <FormAlert type="error">{error}</FormAlert>}
			{success && <FormAlert type="success">{success}</FormAlert>}
		</>
	);
}
