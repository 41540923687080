import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useGetProfileQuery } from 'services/user.service';

interface PrivateRouteProps extends React.PropsWithChildren {
	redirectPath?: string;
}

export function PrivateRoute({ redirectPath = '/login', children }: PrivateRouteProps) {
	const location = useLocation();

	const state = typeof location.state === 'object' ? location.state : {};

	if (location?.state?.auth) {
		if (location.state.auth === 'signup') {
			redirectPath = '/sign-up';
		}
	}
	const { data, isLoading, isSuccess, isError } = useGetProfileQuery();

	if (!isLoading && !data && (isSuccess || isError)) {
		return (
			<Navigate
				to={redirectPath}
				state={{ ...state, from: location.pathname }}
				replace
			/>
		);
	}

	return children ? <>{children}</> : <Outlet />;
}
