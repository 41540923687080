export const zeroPad = (num: number, places: number) =>
	num.toString().padStart(places, '0');

export const getDateString = (countDown: number) => {
	const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
	const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

	return [
		days === 0 ? undefined : days,
		zeroPad(hours, 2),
		zeroPad(minutes, 2),
		zeroPad(seconds, 2)
	]
		.filter((item) => item)
		.join(':');
};
