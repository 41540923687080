import IPhone from 'assets/images/reviews/[Mockup] iPhone X (3).png';
import Card from 'assets/images/reviews/feature2-card-new.png';

import { ParallaxModule, DependentImage, BaseImage } from 'components/ui/ParallaxModule';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

export const WinningPhoneFeature = () => {
	const { isMobile } = useWindowDimensions();

	const dependentImage = (
		<DependentImage
			src={Card}
			alt="Winning Card"
			width={isMobile ? '200px' : '400px'}
			centered="both"
			topOffset="30%"
		/>
	);
	const baseImage = (
		<BaseImage
			height="100%"
			src={IPhone}
			alt="IPhone image"
			dependentImage={dependentImage}
		/>
	);
	return (
		<div
			style={{
				position: 'relative'
			}}
		>
			<ParallaxModule baseImage={baseImage} rotate="10deg" />
		</div>
	);
};
