import { BaseLayout } from 'components/layout/BaseLayout';
import { Container } from 'components/layout/Grid';
import { Footer } from 'components/layout/Footer';
import {TermsContent} from './TermsContent';
export const Terms = () => {
	return (
		<BaseLayout>
			<Container className="ptb-80" centered={true}>
			<TermsContent></TermsContent>
			</Container>
			<Footer />
		</BaseLayout>
	);
};
