import React from 'react';
import * as Yup from 'yup';
import classNames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useMobileDevice } from 'hooks/useMobileDevice';
import { registerUser } from 'store/user/user.actions';
import { selectUserError } from 'store/user/user.slice';
import { FormAlert } from 'components/ui/FormAlert';
import { InputGroup, Input, InputPassword, Empty } from 'components/ui/Input';
import { Button } from 'components/ui/Button';
import { PasswordChecklist } from 'components/ui/PasswordChecklist';
import { Typography } from 'components/ui/Typography';
import { Checkbox } from 'components/ui/Checkbox';
import { Link } from 'components/ui/Link';
import { Delimiter } from 'components/ui/Delimiter';
import { FormTip } from 'components/ui/FormTip';
import { RegisterData } from 'common/interfaces/auth.interface';
import { DatePicker } from 'components/ui/DatePicker';
import { SlideCaptcha } from 'components/ui/SlideCaptcha';
import { PlacesAutocomplete, PreparedPlace } from 'components/ui/PlacesAutocomplete';

import style from './register-form.module.scss';

const initialValues: RegisterData = {
	email: '',
	username: '',
	password: '',
	fname: '',
	lname: '',
	birthdate: '',
	address: '',
	address2: '',
	country: '',
	state: '',
	city: '',
	zipCode: '',
	mobileNo: '',
	is_communication: false,
	agree_rules: false,
	captchaChecked: false
};
const validationSchema = Yup.object({
	email: Yup.string().required().email(),
	username: Yup.string().required(),
	password: Yup.string()
		.required()
		.min(8)
		.test('passwordRequirements', 'Information', (value) =>
			[/\d/, /^.*[!@#$%^&*()_+\-=\]{};':"\\|,.<>?].*$/].every((pattern) =>
				pattern.test(value || '')
			)
		),
	fname: Yup.string().required(),
	lname: Yup.string().required(),
	birthdate: Yup.string().required(),
	address: Yup.string().required(),
	address2: Yup.string().optional(),
	country: Yup.string().required(),
	state: Yup.string().required(),
	city: Yup.string().required(),
	zipCode: Yup.string().required(),
	is_communication: Yup.boolean().optional(),
	captchaChecked: Yup.boolean().oneOf([true], 'Captcha is required').required(),
	agree_rules: Yup.boolean().oneOf([true], 'Checkbox selection is required').required()
});

type Props = {
	data: {
		phone: string;
		referral?: string;
	};
};

export function RegisterForm({ data }: Props) {
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		control,
		formState: { errors, isSubmitting }
	} = useForm<RegisterData>({
		resolver: yupResolver(validationSchema),
		defaultValues: initialValues,
		mode: 'onTouched'
	});

	const { isIOS } = useMobileDevice();
	const dispatch = useAppDispatch();
	const error = useAppSelector(selectUserError);
	const watchPassword = watch('password');
	const watchAddress = watch('address');

	const onSubmit = (values: typeof initialValues) => {
		if (data?.phone) {
			dispatch(
				registerUser({
					...values,
					mobileNo: data.phone,
					referByCode: data.referral
				})
			);
			if (isIOS) {
				window.location.href = 'https://apps.apple.com/app/id1557241541';
			}
		}
	};

	const setAutocompletedAddress = (place: PreparedPlace) => {
		setValue('address', place.street);
		setValue('country', place.country);
		setValue('city', place.city);
		setValue('state', place.state);
		setValue('zipCode', place.zipCode);
	};

	return (
		<>
			<form
				className={classNames('width-registerDesktop', style.form)}
				onSubmit={handleSubmit(onSubmit)}
			>
				<Typography className="m-0 pb-24" color="neutral_07" variant="h6">
					Account Info
				</Typography>
				<InputGroup className="mb-24">
					<Input
						{...register('email')}
						label="Email"
						error={!!errors.email}
						helperText={errors.email?.message}
						fullWidth={true}
						placeholder="Type your email"
					/>
					<Input
						{...register('username')}
						label="Username"
						error={!!errors.username}
						helperText={errors.username?.message}
						fullWidth={true}
						placeholder="Choose a username"
					/>
				</InputGroup>
				<InputGroup className="mb-48">
					<InputPassword
						{...register('password')}
						label="Password"
						error={!!errors.password}
						fullWidth={true}
						className="mb-16"
						placeholder="Your password"
					/>
					<Empty />
					<PasswordChecklist value={watchPassword} />
					<Empty />
				</InputGroup>

				<Delimiter className={classNames('bg-info_bg', 'mtb-48')} height={1} />

				<Typography className="m-0 pb-24" color="neutral_07" variant="h6">
					Personal Details
				</Typography>
				<InputGroup className="mb-24">
					<Input
						{...register('fname')}
						label="Your first name"
						error={!!errors.fname}
						helperText={errors.fname?.message}
						fullWidth={true}
						placeholder="Your first name"
					/>
					<Input
						{...register('lname')}
						label="Last name"
						error={!!errors.lname}
						helperText={errors.lname?.message}
						fullWidth={true}
						placeholder="Your last name"
					/>
				</InputGroup>

				<FormTip text="Make sure it matches first and last names on your government ID." />

				<InputGroup className="mb-48">
					<DatePicker
						{...register('birthdate')}
						onChange={(date) => {
							setValue('birthdate', date.toISOString(), {
								shouldValidate: true
							});
						}}
						label="Birthdate"
						error={!!errors.birthdate}
						helperText={errors.birthdate?.message}
						fullWidth={true}
						placeholder="Your birthdate"
					/>
					<Empty />
					<FormTip text="To sign up, you need to be at least 18. Other users won't see your birthdate." />
				</InputGroup>

				<Delimiter className={classNames('bg-info_bg', 'mtb-48')} height={1} />

				<Typography className="m-0 pb-24" color="neutral_07" variant="h6">
					Location
				</Typography>
				<InputGroup className="mb-24">
					<div>
						<Input
							{...register('address')}
							label="Address"
							error={!!errors.address}
							helperText={errors.address?.message}
							fullWidth={true}
							placeholder="Your primary address"
						/>
						<PlacesAutocomplete
							value={watchAddress}
							onSelect={setAutocompletedAddress}
						/>
					</div>

					<Input
						{...register('address2')}
						label="Address 2 (Optional)"
						error={!!errors.address2}
						helperText={errors.address2?.message}
						fullWidth={true}
						placeholder="Your secondary address"
					/>
				</InputGroup>
				<InputGroup className="mb-24">
					<Input
						{...register('country')}
						label="Country"
						error={!!errors.country}
						helperText={errors.country?.message}
						fullWidth={true}
						placeholder="Your country"
					/>
					<Input
						{...register('state')}
						label="State/Province"
						error={!!errors.state}
						helperText={errors.state?.message}
						fullWidth={true}
						placeholder="Your State/Province"
					/>
				</InputGroup>
				<InputGroup>
					<Input
						{...register('city')}
						label="City"
						error={!!errors.city}
						helperText={errors.city?.message}
						fullWidth={true}
						placeholder="Your city"
					/>
					<Input
						{...register('zipCode')}
						label="Postal code"
						error={!!errors.zipCode}
						helperText={errors.zipCode?.message}
						fullWidth={true}
						placeholder="Your post code"
					/>
				</InputGroup>

				<SlideCaptcha
					className="mtb-40"
					onSuccess={() => setValue('captchaChecked', true)}
				/>

				<div>
					<label htmlFor="is_communication" className={style.checkbox}>
						<Controller
							control={control}
							name="is_communication"
							render={({ field: { value, ...field } }) => (
								<Checkbox
									{...field}
									id="is_communication"
									checked={value}
									onCheckedChange={(status: boolean) =>
										setValue(field.name, status)
									}
								/>
							)}
						/>

						<Typography variant="body1" className="pl-16">
							Yes, I do consent to receiving texts, emails and other
							communications from Bounty Sports in accordance with the
							<Link className="plr-8" underline="always" href="/privacy">
								Privacy Policy
							</Link>
						</Typography>
					</label>
				</div>
				<div>
					<label htmlFor="agree_rules" className={style.checkbox}>
						<Controller
							control={control}
							name="agree_rules"
							render={({ field: { value, ...field } }) => (
								<Checkbox
									{...field}
									checked={value}
									id="agree_rules"
									onCheckedChange={(status: boolean) =>
										setValue(field.name, status)
									}
								/>
							)}
						/>

						<Typography variant="body1" className="pl-16">
							I have read the rules and I agree to the
							<Link className="plr-8" underline="always" href="/terms">
								Terms & Conditions
							</Link>
							and{' '}
							<Link className="plr-8" underline="always" href="/privacy">
								privacy
							</Link>{' '}
							policy of Bounty Sports
						</Typography>
					</label>
				</div>

				<Button
					className="plr-160 mt-32 md:plr-30"
					type="submit"
					color="primary"
					disabled={isSubmitting}
				>
					Sign Up & Play
				</Button>
			</form>

			{error && <FormAlert type="error">{error}</FormAlert>}
		</>
	);
}
