import React from 'react';
import { IconProps } from './Icon.model';

export const Icon = React.forwardRef<SVGSVGElement, React.PropsWithChildren<IconProps>>(
	({ path, component: Component, label, size = 20, color, ...props }, ref) => {
		return (
			<React.Fragment>
				{!path && Component && (
					<Component
						role="img"
						focusable="false"
						aria-hidden={label ? undefined : true}
						width={size}
						height={size}
						fill={color}
						ref={ref}
						title={label}
						{...props}
					/>
				)}
				{path && <img src={path} alt={label} />}
			</React.Fragment>
		);
	}
);
