import React from 'react';
import { Typography } from 'components/ui/Typography';
import { ArrowRightIcon } from 'components/ui/Icon';
import style from '../league.module.scss';

interface WalletButtonProps {
	title: string;
	description: string;
	icon: JSX.Element;
	onClick?: () => any;
}

export const WalletButton = ({
	title,
	description,
	icon,
	onClick
}: WalletButtonProps) => {
	return (
		<div className={style.wallet_btn} onClick={onClick}>
			<div className={style.wallet_btn_icon} onClick={onClick}>
				{icon}
			</div>
			<div>
				<Typography
					component="h6"
					variant="h6"
					variantMd="p1"
					weight="bold"
					className="m-0"
				>
					{title}
				</Typography>
				<Typography className="m-0">{description}</Typography>
			</div>

			<ArrowRightIcon
				className={style.wallet_btn_arrow}
				fillClass="primary-mobile"
			/>
		</div>
	);
};
