import React from 'react';
import { Outlet } from 'react-router-dom';
import { useGetAvailableContestsQuery } from 'services/contest.service';
import { Body } from 'components/layout/Body';
import { Header } from 'components/layout/Header';
import { Footer } from 'components/layout/Footer';
import { Main } from 'components/layout/Main';
import { Banner } from './Banner';
import { ContestsSlider } from './ContestsSlider';
import { CTA } from './CTA';
import { Reviews } from './Reviews';
import { Features } from './Features';
import style from './style.module.scss';
import { LukeBannerFeature } from './LukeBannerFeature';
import {useGetProfileTermsQuery} from "../../services/user.service";
import {useAcceptNewTerms} from "../../hooks/useAcceptNewTerms";
const ComponentsGrid = () => {
	const { data } = useGetAvailableContestsQuery(3);
	const { data:profile } = useGetProfileTermsQuery();
	useAcceptNewTerms(profile);
	return (
		<>
			<Body>
				<Header />
				<Main className={style.main}>
					<Banner />
					<ContestsSlider items={data || []} />
					<Features />
					<LukeBannerFeature />
					<CTA />
					<Reviews />
				</Main>
				<Footer />
				<Outlet />
			</Body>
		</>
	);
};

export default ComponentsGrid;
