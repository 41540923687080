import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { Input } from 'components/ui/Input';
import { Button } from 'components/ui/Button';
import { Typography } from 'components/ui/Typography';
import { requestRoundUp } from 'services/promotion.service';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormAlert } from '../../ui/FormAlert';

const initialValues = {
	email: ''
};
const validationSchema = Yup.object({
	email: Yup.string().required().email()
});

export function RoundUpForm() {
	const [error, setError] = useState<string>();
	const [success, setSuccess] = useState<string>();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isSubmitting }
	} = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: initialValues,
		mode: 'onTouched'
	});

	const onSubmit = async (values: any) => {
		if (!executeRecaptcha) return;
		const token = await executeRecaptcha('promotion');
		try {
			await requestRoundUp({
				...values,
				recaptcha: token
			});
			setError('');
			setSuccess('Request was delivered!');
		} catch (e: any) {
			setError(e.message);
			setSuccess('');
		}
	};

	return (
		<>
			<Typography variant="h3" weight="semi-bold" className="m-0 ptb-20">
				Round Up Your Balance
			</Typography>
			<Typography variant="p2">
				We noticed that your balance is not high enough to enroll this week.
			</Typography>
			<Typography variant="p2">
				Enter your email and click the button to round up your balance and play
				for{' '}
				<Typography variant="p2" component="span" weight="semi-bold">
					FREE
				</Typography>
				.
			</Typography>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Input
					{...register('email')}
					label="Email"
					placeholder="Your BountySports Email"
					error={!!errors.email}
					helperText={errors.email?.message}
					fullWidth
				/>

				<Button className="plr-160 mt-32" type="submit" color="primary" fullWidth>
					Round Up
				</Button>
			</form>
			{error && <FormAlert type="error">{error}</FormAlert>}
			{success && <FormAlert type="success">{success}</FormAlert>}
		</>
	);
}
