import React, { useEffect } from "react";

import style from './dashboard.module.scss';
import classNames from 'classnames';
import { useGetMembershipQuery } from 'services/nft.service';
import { Typography } from 'components/ui/Typography';
import { Progress } from 'components/ui/Progress';
import { Button } from "../../../components/ui/Button";

const MAX_PROGRESS = 100;

export const MembershipInfo = () => {
	const { isSuccess: isSuccessMembership, data: membership } = useGetMembershipQuery();
	const [startDate, setStartDate] = React.useState<Date | null>(null);
	const [endDate, setEndDate] = React.useState<Date | null>(null);
	const [progress, setProgress] = React.useState<number>(0);
	const [isExpiring, setIsExpiring] = React.useState<boolean>(false);
	useEffect(() => {
		if(isSuccessMembership) {
			setStartDate(new Date(membership?.startsAt));
			setEndDate(new Date(membership?.expiredAt));
			setIsExpiring(membership?.isExpiring);
		}
	}, [membership]);
	useEffect(() => {
		if (startDate && endDate) {
			const today = new Date();
			const q = Math.abs(today.getTime()-startDate.getTime());
			const d = Math.abs(endDate.getTime()-startDate.getTime());
			setProgress( Math.round((q/d)*100));
		}
	}, [startDate, endDate]);
	return (
		<>
			<div className={classNames(style.membership, 'pl-40')}>
				{isExpiring && (
					<div className={classNames(style.membership_expiring)}>
						<Typography variant={'p1'} color={'states-info'} className={style.notification_text}>
							Membership Expiring: If you don't renew, you will lose all privileges relating to your League membership.
						</Typography>
					</div>
				)}
				<div className={classNames(style.membership_inner)}>
					<Typography variant={'p1'} color={'primary'} className={classNames('p-0 m-0', style.membership_title)}>
						Your subscription info:
					</Typography>
					<Typography variant={'body2'} color={'primary'} className={ classNames('p-0 m-0', style.membership_start)}>
						Started on: {startDate?.toLocaleDateString()}
					</Typography>
					<Typography variant={'body2'} color={'primary'} textAlign={'right'} className={ classNames('p-0 m-0', style.membership_end)}>
						Expires on: {endDate?.toLocaleDateString()}
					</Typography>
					<Progress value={progress} max={MAX_PROGRESS} isDanger={isExpiring} className={style.membership_progress} />
				</div>
			</div>
		</>
	);
};
