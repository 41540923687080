import cn from 'classnames';
import { Typography } from 'components/ui/Typography';
import { LifeTimeMemberships } from 'components/ui/Memberships';
import { Button } from 'components/ui/Button';
import { DownArrowIcon } from 'components/ui/Icon';
import useWindowDimensions from 'hooks/useWindowDimensions';
import hbService, { LOCATION_TYPE } from 'services/hubspot.service';

import {
	intervalToDuration,
	addDays,
	subSeconds,
	fromUnixTime,
	getUnixTime
} from 'date-fns';
import style from './style.module.scss';

//icons
import { ArrowRightIcon } from 'components/ui/Icon';

import { InfoMemberships } from 'components/ui/Memberships';

import { BannerLeague } from 'components/layout/BannerLeague';
import { useGetMembershipPriceQuery } from '../../services/payment.service';
import { useEffect, useState } from 'react';

const dataBenefits = [
	{
		icon: '🌟',
		title: 'Next Trip',
		subtitle: 'Las Vegas Raiders Trip for 2'
	},
	{
		icon: '💰',
		title: 'Average Member Earnings',
		subtitle: '$180 after 10 weeks'
	},
	{
		icon: '🏆',
		title: 'Top Earner',
		subtitle: 'Ryan, $580 after 10 weeks'
	}
];

export const Banner = () => {
	const { isTable, isLaptopSmall } = useWindowDimensions();

	const { data, isSuccess } = useGetMembershipPriceQuery();

	const [regularPrice, setRegularPrice] = useState(0);
	const [salePrice, setSalePrice] = useState(0);

	useEffect(() => {
		if (isSuccess) {
			setRegularPrice(data.basic_price);
			if (data.basic_price !== data.current_price) {
				setSalePrice(data.current_price);
			}
		}
	}, [data, isSuccess]);

	const scrollToBlock = (section: string) => {
		let exploreBlock = document.querySelector(section);
		exploreBlock?.scrollIntoView({ behavior: 'smooth' });
	};
	return (
		<BannerLeague dataBenefits={dataBenefits} scrollTo=".features">
			<Typography className={style.banner_memberships} component="div">
				<Typography className={style.banner_memberships_top} component="div">
					{!isLaptopSmall ? (
						<LifeTimeMemberships
							preSalePrice={salePrice}
							regularPrice={regularPrice}
							showTimeAndPresale={Boolean(salePrice)}
							saleTime={
								(getUnixTime(new Date('12/31/2022')) -
									getUnixTime(new Date())) *
								1000
							}
						/>
					) : (
						<LifeTimeMemberships
							titleColor="color-default"
							preSalePrice={salePrice}
							regularPrice={regularPrice}
							showTimeAndPresale={Boolean(salePrice)}
						/>
					)}
					{isTable && (
						<>
							<Button
								className="mt-24"
								fullWidth
								size="txl"
								variant="presale"
								disableShadow
								href="/sale/membership"
								onClick={() => {
									hbService.hbRegisterClick(LOCATION_TYPE.banner);
								}}
								endIcon={
									<ArrowRightIcon size={18} fillClass="neutral-black" />
								}
								state={{
									from: '/sale/membership'
								}}
							>
								claim sale offer
							</Button>
							<Typography
								className="mt-60"
								component="div"
								color="primary"
								onClick={() => scrollToBlock('.mobile_presale')}
							>
								<DownArrowIcon size={17} color="#fff" />
							</Typography>
							<LifeTimeMemberships
								className={cn(style.mobile_presale, 'mobile_presale')}
								hideTitle
								preSalePrice={salePrice}
								regularPrice={regularPrice}
								showTimeAndPresale={Boolean(salePrice)}
								saleTime={
									(getUnixTime(new Date('12/31/2022')) -
										getUnixTime(new Date())) *
									1000
								}
								centered
							/>
						</>
					)}
				</Typography>
				<InfoMemberships />
			</Typography>
		</BannerLeague>
	);
};
