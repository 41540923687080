import React from 'react';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { Typography } from 'components/ui/Typography';
import { Button } from 'components/ui/Button';
import { Input } from 'components/ui/Input';
import style from './payment.module.scss';

interface SelectAmountProps {
	items: number[];
}

export function SelectAmount({ items }: SelectAmountProps) {
	const {
		register,
		setValue,
		watch,
		formState: { errors }
	} = useFormContext<{ sum: number }>();
	const sumValue = watch('sum');

	return (
		<>
			<Typography className="m-0 mb-20" variant="p1" weight="semi-bold">
				Select Amount
			</Typography>

			<div>
				<div className={classNames(style.items, 'mb-16')}>
					{items.map((item) => (
						<Button
							key={item}
							className={classNames('p-0', 'ptb-16', style.item)}
							type="button"
							color="primary"
							variant={sumValue === item ? 'default' : 'outlined'}
							onClick={() => setValue('sum', item)}
						>
							{item}$
						</Button>
					))}
				</div>

				<Input
					{...register('sum')}
					type="number"
					placeholder="Other"
					error={!!errors.sum}
					helperText={errors.sum?.message}
					step="0.01"
				/>
			</div>
		</>
	);
}
