import React from 'react';
import { useAppSelector } from 'hooks/redux';
import {
	selectEnrolledContests,
	selectNftEnrolledContests
} from 'store/contest/contest.slice';
import {
	useGetAvailableContestsQuery,
	useGetLiveContestsQuery
} from 'services/contest.service';
import { Typography } from 'components/ui/Typography';
import { Button } from 'components/ui/Button';
import { ContestsList } from '../ContestsList';
import {Contest} from "../../../common/interfaces/contest.interface";

type Props = {
	isNft?: boolean;
	toAvailable: () => void;
};

export default function EnrolledContests({ isNft, toAvailable }: Props) {
	const { data: liveItemsFetch } = useGetLiveContestsQuery();
	const { upcoming: upcomingItems } = useAppSelector(
		isNft ? selectNftEnrolledContests : selectEnrolledContests
	);
	const liveItems: Contest[] = [];
	   if(liveItemsFetch){
		   liveItemsFetch.forEach((item) => {
			   if(isNft){
				   if(item.isNFT) liveItems.push(item)  ;
			   }else{
				   liveItems.push(item) ;
			   }
		   });
	   }

	return (
		<>
			{!liveItems?.length && !upcomingItems.length ? (
				<div>
					<Typography variant="h5" weight="bold">
						You are not enrolled to any Live or Upcoming Contests
					</Typography>
					<Button onClick={toAvailable}>Browse Contests</Button>
				</div>
			) : (
				<div>
					<Typography
						variant="h5"
						variant2Xl="p1"
						variantXl="p1"
						weight="semi-bold"
						className="m-0 mb-16"
					>
						{upcomingItems.length ? 'Upcoming' : 'No Upcoming Contests'}
					</Typography>
					<ContestsList items={upcomingItems} />

					<Typography
						variant="h5"
						variant2Xl="p1"
						variantXl="p1"
						weight="semi-bold"
						className="m-0 mb-16 mt-54"
					>
						{liveItems?.length ? 'Live' : 'No Live Contests'}
					</Typography>
					{liveItems ? <ContestsList items={liveItems} /> : <></>}
				</div>
			)}
		</>
	);
}
