import React from 'react';
import classNames from 'classnames';
import { Icon } from './Icon';
import { ExternalIconProps } from './Icon.model';
import { ReactComponent } from 'assets/images/icons/check-circle.svg';
import style from './icons.module.scss';
interface CheckCircleProps extends ExternalIconProps {
	colorClass?: string;
}
export function CheckCircleIcon({ colorClass, className, ...props }: CheckCircleProps) {
	return (
		<Icon
			className={classNames(
				className,
				colorClass && style[`--color-${colorClass}`]
			)}
			{...props}
			component={ReactComponent}
		/>
	);
}
