import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useLogout } from 'hooks/useLogout';
import { useGetProfileQuery } from 'services/user.service';
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle
} from 'components/ui/Dropdown';
import { Avatar } from 'components/ui/Avatar';
import { Delimiter } from 'components/ui/Delimiter';
import { ArrowRightIcon } from 'components/ui/Icon';
import { Typography } from '../Typography';
import { Name } from './Name';

import spacing from 'assets/styles/spacing.module.scss';
import style from './profile-dropdown.module.scss';

interface ProfileDropdownProps {
	avatar?: string;
}

export const ProfileDropdown = (props: ProfileDropdownProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { data: profile } = useGetProfileQuery();
	const logout = useLogout();

	const itemClasses = classNames('mb-28', style.item);
	const itemIcon = <ArrowRightIcon size={24} />;

	return (
		<Dropdown open={isOpen} onOpenChange={setIsOpen}>
			<DropdownToggle className={style.toggle}>
				<Avatar
					src={props.avatar ? props.avatar : ''}
					alt={`${profile?.fname} ${profile?.lname}`}
				/>
			</DropdownToggle>
			<DropdownMenu
				onFocusOutside={() => setIsOpen(false)}
				className={classNames(
					spacing['mt-16'],
					spacing['p-24'],
					'bg-info_bg',
					'width-profileDropdownDesktop',
					style.menu
				)}
			>
				<Name
					className="mb-36"
					avatar={props.avatar ? props.avatar : ''}
					name={`${profile?.fname} ${profile?.lname}`}
				/>
				<DropdownItem className={itemClasses}>
					<Link to="/profile/account">
						<Typography variant="p1" component="span" weight="semi-bold">
							Account & Personal Info
						</Typography>
						{itemIcon}
					</Link>
				</DropdownItem>
				<DropdownItem className={itemClasses}>
					<Link to="/profile/notifications">
						<Typography variant="p1" component="span" weight="semi-bold">
							Notifications
						</Typography>
						{itemIcon}
					</Link>
				</DropdownItem>
				<DropdownItem className={itemClasses}>
					<Link to="/profile/contests">
						<Typography variant="p1" component="span" weight="semi-bold">
							Contest History
						</Typography>
						{itemIcon}
					</Link>
				</DropdownItem>
				<DropdownItem className={itemClasses}>
					<Link to="/profile/referral">
						<Typography variant="p1" component="span" weight="semi-bold">
							Referral Program
						</Typography>
						{itemIcon}
					</Link>
				</DropdownItem>
				<Delimiter className={classNames('bg-neutral_03', 'mb-28')} height={1} />
				<DropdownItem className={itemClasses}>
					<Link to="/how-to-play">
						<Typography variant="p1" component="span" weight="semi-bold">
							How to Play
						</Typography>
						{itemIcon}
					</Link>
				</DropdownItem>
				<DropdownItem className={itemClasses}>
					<Link to="/support">
						<Typography variant="p1" component="span" weight="semi-bold">
							Support
						</Typography>
						{itemIcon}
					</Link>
				</DropdownItem>
				<Delimiter className={classNames('bg-neutral_03', 'mb-28')} height={1} />
				<DropdownItem className={itemClasses}>
					<Typography
						variant="p1"
						component="span"
						weight="semi-bold"
						onClick={logout}
					>
						<span
							style={{
								cursor: 'pointer'
							}}
						>
							Log Out
						</span>
					</Typography>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};
