import classNames from 'classnames';
import { Container, Grid, GridItem } from 'components/layout/Grid';
import { Typography } from '../../components/ui/Typography';
import { Button } from 'components/ui/Button';
import { Avatar } from 'components/ui/Avatar';
import { Review } from 'components/ui/Review';
import BannerImage from 'assets/images/banner-iphone.png';
import ReviewAvatarImage from 'assets/images/avatar.png';
import DownloadAppStore from 'assets/images/download-app-store.png';
import { useGetProfileQuery } from 'services/user.service';

import MasterCard from 'assets/images/payments/mastercard.svg';
import PayPal from 'assets/images/payments/paypal.svg';
import ApplePay from 'assets/images/payments/apple-pay.svg';
import Visa from 'assets/images/payments/visa.svg';
import Moneris from 'assets/images/payments/moneris.svg';
import style from './style.module.scss';
import { Link } from '../../components/ui/Link';
import { LukeBanner } from './LukeBanner';
import { Input } from '../../components/ui/Input';
import { useMobileDevice } from '../../hooks/useMobileDevice';
import useWindowDimensions from '../../hooks/useWindowDimensions';

interface BannerProps {
	showReferral?: boolean;
	bannerButton?: JSX.Element;
}

const defaultButton = (
	<Button
		href="/sign-up"
		color="primary"
		className={classNames('mt-32 bxl:mt-20 xl:ptb-12', style.mobile_full)}
	>
		Sign Up Today
	</Button>
);

export const Banner = ({
	showReferral = false,
	bannerButton = defaultButton
}: BannerProps) => {
	const { data: loggedIn } = useGetProfileQuery();
	const { isMobile } = useWindowDimensions();
	return (
		<div className={classNames(style.banner, 'pt-120 bxl:pt-92', 'pb-60 bxl:pb-40')}>
			<Container>
				<Grid
					className={style.banner_grid}
					cols={4}
					gap={[0, 30]}
					width={100}
					layout={[
						'banner-text banner-text banner-text banner-image',
						'. . banner-bottom banner-bottom'
					]}
					customProps={{
						zIndex: 10,
						position: 'relative',
						alignItems: 'end'
					}}
				>
					<GridItem
						area="banner-text"
						customProps={{
							alignSelf: 'center'
						}}
						className="mb-150 sm:mb-20 md:mb-40"
					>
						<Typography
							textTransform="uppercase"
							variant="h1"
							variant2Xl="h2"
							variantXl="h3"
							variantMd="h4"
							weight="bold"
							textStyle="italic"
							className="m-0"
						>
							<Typography component="span" color="desktop" weight="bold">
								play
							</Typography>{' '}
							like a fan, <br />
							<Typography component="span" color="desktop" weight="bold">
								win
							</Typography>{' '}
							like a champion
						</Typography>
						<Typography
							component="p"
							className={classNames('m-0 mt-20', style.mobile_subtitle)}
						>
							Pick Winning Teams | Play Friends & Fans | Win Cash Prizes
						</Typography>
						{!loggedIn && (
							<>
								{showReferral && (
									<Input
										value="LONDON5"
										label="REFERRAL CODE"
										disabled={true}
										fullWidth={isMobile}
										className={classNames('mt-32', style.mobile_full)}
										highlighted={true}
									/>
								)}
								{bannerButton}
							</>
						)}
					</GridItem>
					<GridItem
						className={classNames(style.hide_mobile)}
						area="banner-image"
						customProps={{
							position: 'relative',
							minHeight: '45vh'
						}}
					>
						<img
							className={classNames(style.image, 'pb-20')}
							src={BannerImage}
							alt=""
						/>
					</GridItem>
					<GridItem className={style.hide_mobile}>
						<LukeBanner />
					</GridItem>
					{!isMobile && (
						<GridItem
							customProps={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-end',
								alignSelf: 'center'
							}}
						>
							<Typography textAlign="right" className="p-0 m-0">
								<Typography
									component="span"
									variant="h4"
									className="p-0 m-0"
								>
									4.8
								</Typography>
								<Typography
									component="span"
									variant="p3"
									className="p-0 m-0"
								>
									out of 5
								</Typography>
							</Typography>
							<Typography
								textAlign="right"
								component="p"
								variant="small"
								textTransform="uppercase"
								className="p-0 m-0"
							>
								Rating on AppStore
							</Typography>
							<a href="https://apps.apple.com/app/id1557241541">
								<img
									style={{
										maxWidth: '200px'
									}}
									src={DownloadAppStore}
									alt=""
									className="mt-32"
								/>
							</a>
						</GridItem>
					)}
					{!isMobile && (
						<GridItem
							area="banner-bottom"
							customProps={{
								alignSelf: 'center'
							}}
						>
							<Grid
								className={classNames(
									style.banner_grid,
									style.hide_mobile
								)}
								cols={5}
								gap={[0, 20]}
								width={100}
								customProps={{
									alignItems: 'center',
									height: '100%'
								}}
							>
								<GridItem>
									<img src={MasterCard} />
								</GridItem>
								<GridItem>
									<img src={PayPal} />
								</GridItem>
								<GridItem>
									<img src={ApplePay} />
								</GridItem>
								<GridItem>
									<img src={Visa} />
								</GridItem>
								<GridItem>
									<img src={Moneris} />
								</GridItem>
							</Grid>
						</GridItem>
					)}
				</Grid>
			</Container>
		</div>
	);
};
