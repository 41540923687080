import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from 'components/ui/Modal';

import { Content } from './Content';

export const DownloadAppModal = NiceModal.create(() => {
	const modal = useModal();

	return (
		<Modal
			title="Want to get in on the action?"
			className="p-40 width-popupDesktop"
			titleClassName="ptb-24 m-0"
			isOpen={modal.visible}
		>
			<Content modal={modal} />
		</Modal>
	);
});
