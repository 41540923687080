import React from 'react';
import { Icon } from './Icon';
import { ExternalIconProps } from './Icon.model';
import { ReactComponent } from 'assets/images/icons/close.svg';

import style from './icons.module.scss';

export function CloseIcon(props: ExternalIconProps) {
	return (
		<Icon
			{...props}
			className={style['close-icon']}
			size={24}
			component={ReactComponent}
		/>
	);
}
