import { Grid, GridItem, Container } from 'components/layout/Grid';
import { Typography } from 'components/ui/Typography';
import {
	IPhoneFeature,
	IconsFeature,
	WinningPhoneFeature,
	WinFeature
} from './Features/index';
import useWindowDimensions from 'hooks/useWindowDimensions';

import style from './style.module.scss';
import classNames from 'classnames';

interface FeaturesProps {
	customButton?: JSX.Element;
}

// @ts-ignore
// import videoSrc from 'assets/videos/main_bg.mp4';

export const Features = ({ customButton = <></> }: FeaturesProps) => {
	const { isDesktop, isDesktopSmall, isMobile } = useWindowDimensions();
	return (
		<div className={classNames(style.features, isMobile ? 'pb-20' : 'pb-160')}>
			<Container centered={true}>
				<Grid
					cols={2}
					width={100}
					customProps={{
						alignItems: 'center',
						justifyContent: 'center'
					}}
					className={style.mobile_grid}
				>
					<GridItem
						customProps={
							!isDesktopSmall
								? {
										width: '85%'
								  }
								: { width: '100%' }
						}
					>
						<Typography
							component="h2"
							variant="h3"
							weight="bold"
							textTransform="capitalize"
							textAlign={isMobile ? 'center' : ''}
						>
							Find A{' '}
							<Typography component="span" color="desktop" weight="bold">
								Contest
							</Typography>
						</Typography>
						<Typography variant="p1" textAlign={isMobile ? 'center' : ''}>
							Bounty Sports is the world’s first daily fantasy sports
							platform that levels the playing field - no advanced
							algorithms or one-sided odds, just fans vs fans.
						</Typography>
						{customButton}
					</GridItem>
					<GridItem>
						<Grid
							cols={2}
							gap={[0, 10]}
							width={100}
							customProps={{
								alignItems: 'center'
							}}
							className={classNames(style.mobile_grid, isMobile && 'mt-30')}
						>
							<GridItem
								customProps={
									isDesktopSmall
										? {
												position: 'relative',
												left: !isMobile ? '185px' : '40px',
												zIndex: 1
										  }
										: {}
								}
							>
								<IPhoneFeature />
							</GridItem>
							{!isMobile && (
								<GridItem>
									<IconsFeature />
								</GridItem>
							)}
						</Grid>
					</GridItem>
				</Grid>
				<Grid
					cols={2}
					width={100}
					customProps={{
						alignItems: 'center'
					}}
					className={classNames(
						style.mobile_grid,
						style.reverse,
						isMobile && 'mt-30'
					)}
				>
					<GridItem
						customProps={{
							width: !isDesktop ? '80%' : '100%'
						}}
					>
						<WinningPhoneFeature />
					</GridItem>
					<GridItem
						customProps={
							!isDesktopSmall
								? {
										width: '85%'
								  }
								: { width: '100%' }
						}
					>
						<Typography
							component="h2"
							variant="h3"
							weight="bold"
							textTransform="capitalize"
							textAlign={isMobile ? 'center' : ''}
						>
							Pick Winning{' '}
							<Typography component="span" color="desktop" weight="bold">
								Teams
							</Typography>
						</Typography>
						<Typography variant="p1" textAlign={isMobile ? 'center' : ''}>
							Bounty Sports is the world’s first daily fantasy sports
							platform that levels the playing field - no advanced
							algorithms or one-sided odds, just fans vs fans.
						</Typography>
						{customButton}
					</GridItem>
				</Grid>
				<Grid
					cols={2}
					width={100}
					customProps={{
						alignItems: 'center'
					}}
					className={classNames(style.mobile_grid, isMobile && 'mt-30')}
				>
					<GridItem
						customProps={
							!isDesktopSmall
								? {
										width: '85%'
								  }
								: { width: '100%' }
						}
					>
						<Typography
							component="h2"
							variant="h3"
							weight="bold"
							textTransform="capitalize"
							textAlign={isMobile ? 'center' : ''}
						>
							Win Cash{' '}
							<Typography component="span" color="desktop" weight="bold">
								Prizes
							</Typography>
						</Typography>
						<Typography variant="p1" textAlign={isMobile ? 'center' : ''}>
							Bounty Sports is the world’s first daily fantasy sports
							platform that levels the playing field - no advanced
							algorithms or one-sided odds, just fans vs fans.
						</Typography>
						{customButton}
					</GridItem>
					<GridItem
						customProps={
							!isDesktopSmall
								? {
										marginBottom: 100,
										marginLeft: isMobile ? 100 : 0
								  }
								: {
										marginBottom: isMobile ? 30 : 70,
										marginLeft: isMobile ? 50 : 0,
										marginTop: isMobile ? 40 : 0
								  }
						}
					>
						<WinFeature />
					</GridItem>
				</Grid>
			</Container>
		</div>
	);
};
