import { Typography } from 'components/ui/Typography';
import style from '../league.module.scss';

export const LeagueQuote = () => {
	return (
		<Typography component="div" className={style.lquote}>
			<Typography
				component="h2"
				variant="h2"
				variant2Xl="h3"
				variantXl="h4"
				weight="bold"
				textStyle="italic"
				className="m-0"
			>
				“ABSOLUTELY UNIQUE EXPERIENCE USING NFT-TOKENS”
			</Typography>
			<Typography
				component="h6"
				variant="h6"
				variantXl="p1"
				weight="bold"
				className="m-0 mt-32"
			>
				Jeremy McGregor
			</Typography>
			<Typography variant="p1" variantXl="p2" className="m-0 mt-8">
				Football Expert at Adidas Inc.
			</Typography>
		</Typography>
	);
};
