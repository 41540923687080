import { Typography } from 'components/ui/Typography';
import { Button } from 'components/ui/Button';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { InfoMembershipsProps } from './Memberships.model';

import style from './lifetime.module.scss';
import { ArrowRightIcon } from 'components/ui/Icon';
import { Link } from '../Link';

export const InfoMemberships = ({ buttonName }: InfoMembershipsProps) => {
	const { isTable } = useWindowDimensions();

	return (
		<Typography className={style.infomemberships} component="div">
			<Typography
				component="h6"
				variant={isTable ? 'p1' : 'h6'}
				className="m-0"
				weight="bold"
			>
				<Typography component="span" color="primary-mobile" weight="bold">
					FREE Lifetime Entry
				</Typography>{' '}
				into Weekly LEAGUE contesting to win hundreds of thousands in cash prizes
			</Typography>
			<Typography component="ul" className="mt-24">
				<Typography component="li">
					1 lifetime raffle entry into Sports Experience Package draws for a
					chance to win an all-expenses paid trip to watch your favorite pro
					team play in their home city
				</Typography>
				<Typography component="li">
					Prize Multiplier to earn up to 2X cash payouts on all Weekly LEAGUE
					contesting
				</Typography>
				<Typography component="li">
					Ability to sell LEAGUE Membership in future in the open marketplace
				</Typography>
			</Typography>
			<Button
				className={isTable ? 'mt-24' : 'mt-32'}
				fullWidth
				size="txl"
				variant="presale"
				disableShadow
				href="/sale/membership"
				endIcon={<ArrowRightIcon size={18} fillClass="neutral-black" />}
			>
				{buttonName ? buttonName : 'claim sale offer'}
			</Button>
			<Link href={'/league/connect'} underline="none">
				<Typography textAlign={'center'}>Already have a token?</Typography>
			</Link>
			{/*<Link*/}
			{/*	href="/league/login"*/}
			{/*	className="mt-26"*/}
			{/*	textVAlign="center"*/}
			{/*	textAlign="center"*/}
			{/*	underline="hover"*/}
			{/*	variant="p2"*/}
			{/*	weight="semi-bold"*/}
			{/*	color="primary-mobile"*/}
			{/*>*/}
			{/*	Use My NFT Token*/}
			{/*</Link>*/}
		</Typography>
	);
};
