import React from 'react';
import classNames from 'classnames';
import { Typography } from '../Typography';
import { LinkProps } from './Link.model';
import style from './link.module.scss';
import { Link as LinkTo } from 'react-router-dom';

export const Link = React.forwardRef<
	HTMLAnchorElement,
	React.PropsWithChildren<LinkProps>
>(({ children, underline = 'always', href, state, ...props }, ref) => {
	return (
		<LinkTo to={href ? href : ''} state={state}>
			<Typography
				{...props}
				className={classNames(
					props.className,
					style.link,
					style[`underline-${underline}`]
				)}
				component="span"
				ref={ref}
			>
				{children}
			</Typography>
		</LinkTo>
	);
});
