import React from 'react';
import styled from 'styled-components';

type TextAlign = 'left' | 'center' | 'right';

interface GridItemProps {
	area?: string;
	customProps?: object;
	alignSelf?: string;
	textAlign?: TextAlign;
	className?: string;
}

const StyledDiv = styled.div<Partial<GridItemProps>>`
	grid-area: ${(props) => props.area};
	align-self: ${(props) => props.alignSelf};
	text-align: ${(props) => props.textAlign};

	${({ customProps }) => ({ ...customProps })}
`;

export const GridItem = ({
	className,
	children,
	...props
}: React.PropsWithChildren<GridItemProps>) => {
	return (
		<StyledDiv className={className} {...props}>
			{children}
		</StyledDiv>
	);
};
