import React, { useState } from 'react';
import { useAppSelector } from 'hooks/redux';
import { Navigation, NavigationItem } from 'components/layout/Navigation';
import { Container } from 'components/layout/Grid';
import { Typography } from 'components/ui/Typography';
import { Logo } from 'components/ui/Logo';
import { Button } from 'components/ui/Button';
import style from './footer.module.scss';
import classNames from 'classnames';

interface NavLink {
	title: string;
	path: string;
}

const navLinks: NavLink[] = [
	{
		title: 'Contests',
		path: '/contest'
	},
	{
		title: 'The League',
		path: '/league'
	},
	{
		title: 'Terms Of Use',
		path: '/terms'
	},
	{
		title: 'Support',
		path: '/support'
	},
	{
		title: 'How To Play',
		path: '/how-to-play'
	}
];

export function Footer() {
	const [year] = useState<number>(new Date().getFullYear());
	const { profile } = useAppSelector((state) => state.user);

	return (
		<footer>
			<Container className={style.footer}>
				<Logo />
				<Navigation className={style.navigation}>
					{navLinks.map(({ title, path }) => (
						<NavigationItem key={path} to={path}>
							{title}
						</NavigationItem>
					))}
				</Navigation>

				{!profile && (
					<Button href="/sign-up" color="primary">
						Sign Up Today
					</Button>
				)}

				<Typography
					className={classNames(style.footer_rights, 'mt-36')}
					centered={true}
					textAlign="center"
					variant="body3"
					color="states-info"
				>
					All rights are reserved | Bounty Sports {year}
				</Typography>
			</Container>
		</footer>
	);
}
