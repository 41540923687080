import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoggedIn } from 'hooks/useLoggedIn';
import { Modal } from 'components/ui/Modal';
import { Typography } from 'components/ui/Typography';
import { Link } from 'components/ui/Link';
import { VerifyPhoneModal } from 'components/modals/VerifyOtp';
import { QuickRegisterForm, SignUpForm } from 'components/forms/RegisterForm';

export function SignUpModal() {
	const [data, setData] = useState<{ phone: string; referral: string }>({
		phone: '',
		referral: ''
	});
	const [showQuick, setShowQuick] = useState<boolean>(false);

	const navigate = useNavigate();
	const { redirectTo, state } = useLoggedIn();

	const toRegisterPage = () => {
		if (redirectTo?.includes('sale') || state?.from?.includes('london')) {
			setShowQuick(true);
		} else {
			navigate('/register', { state: { ...state, data, from: redirectTo } });
		}
	};

	const renderModal = () => {
		if (!data.phone || (showQuick && data.phone)) {
			return (
				<Modal
					className="p-40 width-popupDesktop"
					title="Sign Up & Get $5"
					titleClassName="ptb-24 m-0"
					isOpen
					forRoute
					onClose={() => navigate(-1)}
				>
					{showQuick ? (
						<QuickRegisterForm data={data} />
					) : (
						<SignUpForm onSuccess={setData} />
					)}

					<Typography variant="p2" textAlign="center">
						<Typography className="pr-8" variant="p2" component="span">
							I already have an account.{' '}
							<Link
								underline="always"
								color={
									state?.from?.includes('london') ? 'league' : 'desktop'
								}
								variant="p1"
								component="span"
								href="/login"
								className="m-0"
							>
								Login
							</Link>
						</Typography>
					</Typography>
				</Modal>
			);
		} else {
			return <VerifyPhoneModal phone={data.phone} onSuccess={toRegisterPage} />;
		}
	};

	return renderModal();
}
