import { useEffect, useState } from 'react';
import paymentService from 'services/payment.service';

interface MonerisInstance {
	setMode: (mode: string) => void;
	setCheckoutDiv: (id: string) => void;
	setCallback: (event: string, cb: Function) => void;
	startCheckout: (ticket: string) => void;
	closeCheckout: () => void;
}

interface HandlerMessage {
	handler: string;
	ticket: string;
	response_code: string;
}

export const useMoneris = (
	containerId: string,
	onApprove: () => any,
	onError: () => any
) => {
	const [moneris] = useState<MonerisInstance>(new (window as any).monerisCheckout());

	useEffect(() => {
		moneris.setMode(process.env.REACT_APP_MONERIS_MODE || 'qa');
		moneris.setCheckoutDiv(containerId);
	}, [moneris, containerId]);

	const createOrder = async (amount: number) => {
		const ticket = await paymentService.getCheckoutTicket(amount);

		moneris.setCallback('cancel_transaction', closeFrame);
		moneris.setCallback('error_event', closeFrame);
		moneris.setCallback('payment_complete', closeFrame);
		moneris.setCallback('payment_receipt', handleReceipt);

		moneris.startCheckout(ticket);
	};

	const closeFrame = () => {
		moneris.closeCheckout();
	};

	const handleReceipt = async (data: string) => {
		closeFrame();

		const message: HandlerMessage = JSON.parse(data);
		if (message.response_code !== '001') {
			onError();
			return;
		}

		try {
			await paymentService.confirmMonerisPayment(message.ticket);
			onApprove();
		} catch (e) {
			onError();
		}
	};

	return { createOrder, closeFrame };
};
