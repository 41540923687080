import React from 'react';
import { Icon } from './Icon';
import { ExternalIconProps } from './Icon.model';
import { ReactComponent } from 'assets/images/icons/star.svg';

interface StarIconProps extends ExternalIconProps {
	active: boolean;
}

const color = '#5EC5C8';

export function StarIcon(props: StarIconProps) {
	let fill = color,
		stroke = color;
	if (props.active) {
		stroke = 'none';
	} else {
		fill = 'none';
	}
	return <Icon stroke={stroke} color={fill} component={ReactComponent} />;
}
