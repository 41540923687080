import { Body } from 'components/layout/Body';
import { Main } from 'components/layout/Main';
import { Banner } from './partials/London150Banner';
import hbService, { LOCATION_TYPE } from '../../services/hubspot.service';
import { Button } from '../../components/ui/Button';
import React from 'react';
import { Logo } from 'components/ui/Logo';

import style from './partials/style.module.scss';
import { GoUp } from 'pages/Presale/GoUp';
import { Features } from 'pages/Presale/Features';
import { Outlet } from 'react-router-dom';
import { ArrowRightIcon } from '../../components/ui/Icon';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export const getJoinBtn = (isMobile: boolean, promo = 'LONDON150') => {
	return (
		<Button
			className={'mt-32'}
			fullWidth
			size="txl"
			variant="default"
			disableShadow
			href="/london150/membership"
			color="default"
			endIcon={<ArrowRightIcon size={18} fillClass="neutral-black" />}
			state={{
				promo: promo,
				from: '/london150',
				redirectTo: '/london150/membership',
				auth: 'signup'
			}}
		>
			JOIN NOW
		</Button>
	);
};

export const London150 = () => {
	const { isMobile } = useWindowDimensions();

	return (
		<Body className={style.london5}>
			<header className={style.header}>
				<Logo size="sm" />
			</header>
			<Main>
				<Banner />
				<Features button={getJoinBtn(isMobile)} />
				<GoUp />
				<Outlet />
			</Main>
		</Body>
	);
};
