import IconBg from 'assets/images/reviews/review1-icons-bg.png';
import BigIcon from 'assets/images/reviews/review1-big-icon.png';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { ParallaxModule, DependentImage, BaseImage } from 'components/ui/ParallaxModule';

export const IconsFeature = () => {
	const { isDesktopSmall } = useWindowDimensions();
	const dependentImage = (
		<DependentImage
			src={BigIcon}
			alt="Button example"
			width={isDesktopSmall ? '70%' : '80%'}
			centered="both"
			topOffset="40%"
		/>
	);
	const baseImage = (
		<BaseImage
			src={IconBg}
			height={isDesktopSmall ? '60vh' : '70vh'}
			alt="Icons"
			dependentImage={dependentImage}
		/>
	);
	return (
		<div
			style={{
				position: 'relative'
			}}
		>
			<ParallaxModule baseImage={baseImage} width="80%" rotate="10deg" />
		</div>
	);
};
