const SALE_EVENT = 'pe8648395_league_sale_button_click';

const CHECKOUT_EVENT = 'pe8648395_league_checkout_button_click';

const PURCHASE_EVENT = 'pe8648395_league_purchase';

export enum LOCATION_TYPE {
	'header' = 'Header',
	'banner' = 'Banner',
	'footer' = 'Footer',
	'content' = 'Content'
}

const hbRegisterClick = (location: LOCATION_TYPE = LOCATION_TYPE.header) => {
	const _hsq = (window._hsq = window._hsq || []);
	_hsq.push([
		'trackCustomBehavioralEvent',
		{
			name: SALE_EVENT,
			properties: {
				location: location,
				path: window.location.pathname
			}
		}
	]);
};

const hbCheckoutClick = (price: number) => {
	const _hsq = (window._hsq = window._hsq || []);
	_hsq.push([
		'trackCustomBehavioralEvent',
		{
			name: CHECKOUT_EVENT,
			properties: {
				price: price
			}
		}
	]);
};

const hbIdentify = (email: string) => {
	const _hsq = (window._hsq = window._hsq || []);
	_hsq.push([
		'identify',
		{
			email: email
		}
	]);
};

const hbLeaguePurchase = (success = false) => {
	const _hsq = (window._hsq = window._hsq || []);
	_hsq.push([
		'trackCustomBehavioralEvent',
		{
			name: PURCHASE_EVENT,
			properties: {
				status: success ? 'success' : 'fail'
			}
		}
	]);
};

const hbService = { hbRegisterClick, hbIdentify, hbCheckoutClick, hbLeaguePurchase };

export default hbService;
