import { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {UserProfile} from "../common/interfaces/user.interface";
import useWindowDimensions from "./useWindowDimensions";

export const useAcceptNewTerms = (profile?: UserProfile) => {
	const navigate = useNavigate();
	const {isMobile } = useWindowDimensions();
	useEffect(()=>{
		if(profile){
			if(profile.isTermsAccepted == 0 && !isMobile) navigate('/accept-new-terms') ;
		}
	},[profile]);
};
