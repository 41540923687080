import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import { Typography } from 'components/ui/Typography';

import style from './slide_captcha.module.scss';

interface SlideCaptchaProps {
	className?: string;
	onSuccess: () => any;
}

export const SlideCaptcha = (props: SlideCaptchaProps) => {
	const node = useRef<HTMLDivElement>(null);
	const trigger = useRef<HTMLDivElement>(null);
	const text = useRef<HTMLDivElement>(null);

	const [active, setActive] = useState(false);
	const [finished, setFinished] = useState(false);

	const onMouseDownHandler = (event: React.MouseEvent) => {
		setActive(true);
	};

	const onTouchStartHandler = (event: React.TouchEvent) => {
		setActive(true);
	};

	const onMouseMoveHandler = (event: MouseEvent) => {
		handleEvent(event);
	};

	const onTouchMoveHandler = (event: TouchEvent) => {
		handleEvent(event);
	};

	const onMouseUpHandler = (event: MouseEvent) => {
		event.preventDefault();
		handleEvent(event);
		setActive(false);
	};

	const onTouchEndHandler = (event: TouchEvent) => {
		/*event.preventDefault();
		handleEvent(event);
		setActive(false);*/
	};

	const handleEvent = (event: MouseEvent | TouchEvent) => {
		const left = node?.current ? node?.current.offsetLeft : 0;
		const x = event instanceof MouseEvent ? event.x : event.touches[0].clientX - left;
		const clientWidth = node?.current ? node?.current?.clientWidth : 0;
		const wrapperLimit = left + clientWidth;
		let width = 60;
		if (x >= wrapperLimit) {
			width = wrapperLimit;
		} else if (x >= width) {
			width = x;
		}
		const triggerNode = trigger?.current;
		if (triggerNode) {
			triggerNode.style.width = `${width}px`;
			const textNode = text?.current;
			if (textNode) {
				let bgPosition = 100 - (width / textNode.clientWidth) * 100;
				bgPosition = bgPosition < 0 ? 0 : bgPosition;
				bgPosition = bgPosition > 100 ? 100 : bgPosition;
				textNode.style.backgroundPosition = `${bgPosition}%`;
			}
		}
		if (width >= clientWidth) {
			setFinished(true);
			props.onSuccess();
		}
	};
	useEffect(() => {
		if (finished) return;
		if (active) {
			window.addEventListener('mouseup', onMouseUpHandler, false);
			window.addEventListener('mousemove', onMouseMoveHandler, false);

			window.addEventListener('touchend', onTouchEndHandler, false);
			window.addEventListener('touchmove', onTouchMoveHandler, false);

			return () => {
				window.removeEventListener('mouseup', onMouseUpHandler, false);
				window.removeEventListener('mousemove', onMouseMoveHandler, false);

				window.addEventListener('touchend', onTouchEndHandler, false);
				window.addEventListener('touchmove', onTouchMoveHandler, false);
			};
		}
	}, [active]);

	return (
		<div
			onMouseDown={onMouseDownHandler}
			onTouchStart={onTouchStartHandler}
			ref={node}
			className={classNames(props.className, style.captcha)}
		>
			<div className={style.trigger} ref={trigger}></div>
			<div className={style.label}>
				<Typography
					ref={text}
					className={classNames('ml-44', style.text)}
					color="desktop"
					textAlign="center"
					textVAlign="center"
					weight="semi-bold"
				>
					<span className="pr-12">Press and Swipe Right</span>
					<Typography component="span" variant="p1">
						→
					</Typography>
				</Typography>
			</div>
			<div className={classNames(style.finished, finished && style.active)}>
				<Typography
					variant="p3"
					component="span"
					color="primary"
					textAlign="center"
					textVAlign="center"
				>
					<span>Verified!</span>
				</Typography>
			</div>
		</div>
	);
};
