import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from 'components/ui/Modal';
import { Typography } from 'components/ui/Typography';

export const ForgotSuccessModal = NiceModal.create(() => {
	const modal = useModal();

	return (
		<Modal
			className="p-40 width-popupDesktop"
			isOpen={modal.visible}
			onClose={modal.hide}
		>
			<Typography variant="h5" weight="semi-bold" textAlign="center">
				We’ve sent a Reset Link
			</Typography>
			<Typography variant="p2" className="mt-16" textAlign="center">
				Check your SMS/inbox for a reset link
			</Typography>
			<Typography variant="p2" textAlign="center">
				You will get a letter within 5 minutes if you have an account registered
			</Typography>
		</Modal>
	);
});
