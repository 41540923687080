enum Storage {
	JWT_ACCESS = 'accessToken',
	JWT_REFRESH = 'refreshToken',
	USER_ID = 'userId',
	WALLET_ID = 'walletId'
}

class StorageService {
	getAccessToken(): string | null {
		return localStorage.getItem(Storage.JWT_ACCESS);
	}

	getRefreshToken(): string | null {
		return localStorage.getItem(Storage.JWT_REFRESH);
	}

	setAuthTokens(access: string, refresh: string) {
		localStorage.setItem(Storage.JWT_ACCESS, access);
		localStorage.setItem(Storage.JWT_REFRESH, refresh);
	}

	getUserId(): string | null {
		return localStorage.getItem(Storage.USER_ID);
	}

	saveUserId(userId: string): void {
		localStorage.setItem(Storage.USER_ID, userId);
	}

	setWallet(account?: string): void {
		if (account) localStorage.setItem(Storage.WALLET_ID, account);
		else localStorage.removeItem(Storage.WALLET_ID);
	}

	getWallet(): string | null {
		return localStorage.getItem(Storage.WALLET_ID);
	}

	resetAuthData(): void {
		localStorage.removeItem(Storage.JWT_ACCESS);
		localStorage.removeItem(Storage.JWT_REFRESH);
		localStorage.removeItem(Storage.USER_ID);
	}
}

export const storageService = new StorageService();
