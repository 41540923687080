import React, { useEffect, useState } from 'react';

interface ProgressProps {
	points?: number;
}
export const Progress = ({ points = 299 }: ProgressProps) => {
	const meridianPoints = 300;
	const fullPoints = 1000;
	const widthUpToMeridian = 170;
	const fullWidth = 493;

	let progressWidth: number;

	if (points <= meridianPoints) {
		progressWidth = (points / meridianPoints) * widthUpToMeridian;
	} else {
		progressWidth =
			((points - meridianPoints) / (fullPoints - meridianPoints)) *
				(fullWidth - widthUpToMeridian) +
			widthUpToMeridian;
	}

	const [progress, setProgress] = useState<number>(0);

	useEffect(() => {
		setProgress(progressWidth);
	}, []);

	return (
		<div>
			<svg
				width={fullWidth}
				height="40"
				viewBox={`0 0 ${fullWidth} 40`}
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1 20C1 9.50659 9.50659 1 20 1H168.672C178.202 1 187.038 5.98963 191.959 14.1515C192.438 14.9465 193.564 14.9759 194.096 14.2395C200.089 5.95726 209.731 1 219.949 1H473C483.493 1 492 9.50659 492 20C492 30.4934 483.493 39 473 39H20C9.50658 39 1 30.4934 1 20Z"
					fill="#16253A"
					stroke="white"
					strokeWidth="2"
				/>
				<rect
					id="progress"
					width={progress ? progress - 15 : 0}
					x="8"
					y="8"
					height="24"
					rx="12"
					fill="url(#paint0_linear_3187_68905)"
					style={{
						clipPath: 'url(#myClip)',
						transition: 'all 2s ease-in-out'
					}}
				/>
				<defs>
					<clipPath id="myClip">
						<path
							d="M 1 20 C 2 10 9 5 20 5 H 163 C 167 5 182 4 187 12 C 192 19 194 19 200 12 C 205 4 219 5 223 5 H 473 C 484 6 490 11 492 20 C 492 30.4934 483.493 39 473 39 H 20 C 9.5066 39 1 30.4934 1 20 Z"
							strokeWidth="2"
						/>
					</clipPath>
					<linearGradient
						id="paint0_linear_3187_68905"
						x1="85"
						y1="0"
						x2="85"
						y2="24"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#31CF8E" />
						<stop offset="1" stopColor="#0A7046" />
					</linearGradient>
				</defs>
			</svg>
		</div>
	);
};
