import React from 'react';

import classNames from 'classnames';
import style from './main.module.scss';

interface MainProps {
	className?: string;
}

export const Main = (props: React.PropsWithChildren<MainProps>) => {
	return (
		<main className={classNames(style.main, props.className)}>{props.children}</main>
	);
};
