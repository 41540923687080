import { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useAddWalletMutation } from 'services/nft.service';
import { storageService } from 'services/storage.service';
import { injected } from 'components/wallet/connectors';
import { useWallet } from 'hooks/useWallet';

interface MetamaskProviderProps {
	children: JSX.Element;
}

export default function WalletProvider({ children }: MetamaskProviderProps) {
	const { account, active: networkActive, error: networkError } = useWeb3React();
	const { connectMetamask, disconnectWallet } = useWallet();
	const [addWallet] = useAddWalletMutation();

	useEffect(() => {
		if (networkActive && account) {
			const prevWallet = storageService.getWallet();
			if (prevWallet !== account) {
				storageService.setWallet(account);
				addWallet(account);
			}
		}
	}, [networkActive, account]);

	useEffect(() => {
		injected.isAuthorized().then((isAuthorized) => {
			if (
				isAuthorized &&
				!networkActive &&
				!networkError &&
				storageService.getWallet()
			) {
				connectMetamask();
			} else if (!isAuthorized && !networkActive && storageService.getWallet()) {
				disconnectWallet();
			}
		});
	}, [networkActive, networkError]);

	return children;
}
