import React from 'react';
import { Modal } from 'components/ui/Modal';
import { SupportForm } from 'components/forms/SupportForm';

export function SupportModal() {
	return (
		<Modal
			title="Support Request"
			className="p-40 width-popupDesktop"
			titleClassName="ptb-24 m-0"
			isOpen
			forRoute
		>
			<SupportForm />
		</Modal>
	);
}
