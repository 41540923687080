import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginData, RegisterData } from 'common/interfaces/auth.interface';
import authService from 'services/auth.service';
import { useGetProfileQuery, userApi } from 'services/user.service';
import { storageService } from 'services/storage.service';
import { clearStore } from 'store/index';
import { useGTM } from '../../hooks/useGTM';
import { useLoggedIn } from '../../hooks/useLoggedIn';
import { useNavigate } from 'react-router-dom';

enum UserAction {
	LOGIN_USER = 'user/login',
	LOGOUT_USER = 'user/logout',
	REGISTER_USER = 'user/register',
	QUICK_REGISTER_USER = 'user/register/quick',
	USER_PROFILE_EDIT = 'user/profile/edit'
}

export const logoutUser = createAsyncThunk(
	UserAction.LOGOUT_USER,
	async (data, thunk) => {
		const isLoggedIn = storageService.getAccessToken();
		if (isLoggedIn) {
			storageService.resetAuthData();
			thunk.dispatch(clearStore());
			await authService.logoutUser();
		}
	}
);

export const loginUser = createAsyncThunk(
	UserAction.LOGIN_USER,
	async (data: LoginData, thunk) => {
		try {
			await authService.loginUser(data);
			thunk.dispatch(userApi.endpoints.getProfile.initiate());
		} catch (err: any) {
			return thunk.rejectWithValue(err.message as string);
		}
	}
);

export const registerUser = createAsyncThunk(
	UserAction.REGISTER_USER,
	async (data: RegisterData, thunk) => {
		try {
			await authService.registerUser({ ...data, app_type: 1 });
			thunk.dispatch(userApi.endpoints.getProfile.initiate());
			const { tiktokCompleteRegistration } = useGTM();

			tiktokCompleteRegistration(data.email, data.username);
		} catch (err: any) {
			return thunk.rejectWithValue(err.message as string);
		}
	}
);

export const quickRegisterUser = createAsyncThunk(
	UserAction.QUICK_REGISTER_USER,
	async (data: Partial<RegisterData>, thunk) => {
		try {
			await authService.registerUser({ ...data, app_type: 1 }, true);
			thunk.dispatch(userApi.endpoints.getProfile.initiate());

			const { tiktokCompleteRegistration } = useGTM();

			tiktokCompleteRegistration(data.email, data.username);
		} catch (err: any) {
			return thunk.rejectWithValue(err.message as string);
		}
	}
);
