import React from 'react';
import classNames from 'classnames';
import { Root as CheckboxRoot, Indicator } from '@radix-ui/react-checkbox';
import { CheckIcon } from 'components/ui/Icon';
import { CheckboxProps } from './Checkbox.model';
import { Button } from 'components/ui/Button';
import style from './checkbox.module.scss';

export const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(
	({ size, className, ...props }: CheckboxProps, ref) => {
		return (
			<CheckboxRoot
				{...props}
				className={classNames(style.container, size && style[size], className)}
				ref={ref}
			>
				<Indicator className={style.indicator}>
					<CheckIcon />
				</Indicator>
			</CheckboxRoot>
		);
	}
);

export const CheckboxButton = React.forwardRef<HTMLButtonElement, CheckboxProps>(
	({ size, checked = false, className, ...props }: CheckboxProps, ref) => {
		return (
			<CheckboxRoot
				{...props}
				className={classNames(
					style.button,
					size && style[size],
					checked && style.active
				)}
				ref={ref}
			>
				{props.children}
			</CheckboxRoot>
		);
	}
);
