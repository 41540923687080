import React from 'react';
import { Modal } from 'components/ui/Modal';
import { Typography } from 'components/ui/Typography';
import { Link } from 'components/ui/Link';
import {Button} from "../../ui/Button";
import { acceptNewTerms } from 'services/user.service';
import {useNavigate} from "react-router-dom";
import {TermsContent} from "../../../pages/Terms/TermsContent";

export function TermsModal() {
	const navigate = useNavigate();
	async function acceptTerms(){
		const data = await acceptNewTerms() ;
		if(data.status == 200) {
			window.location.href='/';
		}else{
			console.log("error to update the accept new terms ");
			window.location.href='/';
		}

	}

	return (
			<Modal
				className="p-40 width-registerDesktop"
				titleClassName={`ptb-24 m-0`}
				title=""
				isOpen
			>
				<Typography variant="p2">
					<TermsContent></TermsContent>
				</Typography>
				<Button onClick={()=>{ acceptTerms()}}>Accept</Button>
			</Modal>
	);
}
