import { useEffect } from 'react';
import { useGetMembershipQuery } from 'services/nft.service';
import {UserProfile} from "../common/interfaces/user.interface";
import {ExpireMembershipModal} from '../components/modals/RenewMembershipAlert'
import { useModal } from '@ebay/nice-modal-react';
import { useNavigate,useLocation } from 'react-router-dom';

const POSTPONE_DURATION = 1000 * 60 * 60 * 24 * 3;
const CLOSE_DURATION = 30 * 60 * 1000;

export const useMembershipExpiring = (profile?: UserProfile) => {
    const navigate = useNavigate();
	const location = useLocation();
	let toShow = true;
	const lastPostponed = localStorage.getItem('postponedPopup');
	if(lastPostponed){
		const lastPostponedTimestamp = parseInt(lastPostponed);
		if(lastPostponedTimestamp + POSTPONE_DURATION > Date.now()){
			toShow = false;
		}
		if(lastPostponedTimestamp + CLOSE_DURATION > Date.now()){
			toShow = false;
		}
	}

	const { isSuccess: isSuccessMembership, data: membership } = useGetMembershipQuery();

	useEffect(()=>{
		if(location.pathname == '/league-renewal/renew') return  ;
		if(profile && membership && toShow){
			if(membership.isExpiring && !membership.expired){
				navigate(
					'/league-renewal/renew',
					{
						state: {
							redirectTo: location.pathname
						}
					}
				);
			}

		}
	},[profile]);
};
