import { Container } from 'components/layout/Grid';
import { Typography } from 'components/ui/Typography';
import { DownArrowIcon } from 'components/ui/Icon';

import style from './style.module.scss';

export const GoUp = () => {
	const goToUp = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};
	return (
		<div className={style.goup}>
			<Container centered={true} align="center">
				<div className={style.goup_title} onClick={goToUp}>
					<Typography
						weight="semi-bold"
						variant="p1"
						component="span"
						color="primary"
					>
						Go Up
					</Typography>
					<DownArrowIcon size={17} color="#fff" />
				</div>
			</Container>
		</div>
	);
};
