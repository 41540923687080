import React from 'react';
import classNames from 'classnames';
import { Root as AvatarRoot, Image, Fallback } from '@radix-ui/react-avatar';
import { AvatarProps } from './Avatar.model';
import style from './avatar.module.scss';

export function Avatar({
	className,
	src,
	alt = 'Avatar',
	variant = 'default',
	border = false,
	size
}: AvatarProps) {
	return (
		<AvatarRoot
			className={classNames(
				style.avatar,
				style[variant],
				size && style[size],
				border && style[`border--${border}`],
				className
			)}
		>
			<Image className={style.image} src={src} alt={alt} />
			<Fallback className={style.fallback} delayMs={600}>
				{alt
					.split(' ')
					.map((chk) => chk.charAt(0).toLocaleUpperCase())
					.join('')}
			</Fallback>
		</AvatarRoot>
	);
}
