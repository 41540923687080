import React, { useEffect, useState } from 'react';
import {
	intervalToDuration,
	addDays,
	subSeconds,
	fromUnixTime,
	getUnixTime
} from 'date-fns';
import style from './styles.module.scss';
import { FlipCountdownProp } from './FlipTimer.model';
import classNames from 'classnames';
import { Typography } from '../Typography';

export const FlipTimer = (props: FlipCountdownProp) => {
	const clock = {
		day: {
			title: 'Days',
			current: useState(0)
		},
		hour: {
			title: 'Hours',
			current: useState(0)
		},
		minute: {
			title: 'Minutes',
			current: useState(0)
		},
		second: {
			title: 'Seconds',
			current: useState(0)
		}
	};

	const {
		countDays = 3,
		timestamp,
		hideDay = false,
		hideHour = false,
		hideMinute = false,
		hideSecond = false,
		className
	} = props;

	let interval: any = null;

	let setTimestamp = timestamp;

	if (!timestamp) {
		setTimestamp = getUnixTime(new Date());
	}

	const [startAtUnix] = useState(new Date());
	const endAtUnix = setTimestamp
		? fromUnixTime(setTimestamp)
		: addDays(new Date(), countDays);

	const [currentUnix, setCurrentUnix] = useState<Date>(endAtUnix);
	const [duration, setDuration] = useState<Duration>();

	const [completed, setCompleted] = useState<boolean>(
		intervalToDuration({
			start: startAtUnix,
			end: endAtUnix
		}) > 0
	);

	useEffect(() => {
		if (!completed) {
			interval = setInterval(() => {
				setCurrentUnix((current) => subSeconds(current, 1));
			}, 1000);
			processClock();
			return () => {
				if (interval) {
					clearInterval(interval);
				}
			};
		}
		if (currentUnix >= endAtUnix) {
			setCompleted(true);
		}
	}, []);

	useEffect(() => {
		setDuration(
			intervalToDuration({
				start: startAtUnix,
				end: currentUnix
			})
		);
		processClock();
	}, [currentUnix]);

	const processClock = () => {
		if (!duration) return;

		const { days, hours, minutes, seconds } = duration;

		if (!hideDay) {
			clock.day.current[1](Number(days));
		}
		if (!hideHour) {
			clock.hour.current[1](Number(hours));
		}
		if (!hideMinute) {
			clock.minute.current[1](Number(minutes));
		}
		if (!hideSecond) {
			clock.second.current[1](Number(seconds));
		}
	};

	return (
		<div className={classNames(style.flip_timer, className)}>
			{Object.keys(clock).map((key) => {
				switch (key) {
					case 'day':
						return (
							<div className={style.column}>
								<Piece key={'day_piece'} current={clock.day.current[0]} />
								<Typography
									variant="p1"
									color={'states-info'}
									textAlign={'center'}
								>
									Days
								</Typography>
							</div>
						);
					case 'hour':
						return (
							<div className={style.column}>
								<Piece
									key={'hour_piece'}
									current={clock.hour.current[0]}
								/>
								<Typography
									variant="p1"
									color={'states-info'}
									textAlign={'center'}
								>
									Hours
								</Typography>
							</div>
						);
					case 'minute':
						return (
							<div className={style.column}>
								<Piece
									key={'minute_piece'}
									current={clock.minute.current[0]}
								/>
								<Typography
									variant="p1"
									color={'states-info'}
									textAlign={'center'}
								>
									Minutes
								</Typography>
							</div>
						);
					case 'second':
						return (
							<div className={style.column}>
								<Piece
									key={'second_piece'}
									current={clock.second.current[0]}
								/>
								<Typography
									variant="p1"
									color={'states-info'}
									textAlign={'center'}
								>
									Seconds
								</Typography>
							</div>
						);
				}
				return <></>;
			})}
		</div>
	);
};

interface PieceProps extends React.PropsWithChildren {
	current: number;
}

const Piece = ({ current = 0 }: PieceProps) => {
	return (
		<div className={classNames(style.card)}>
			<div className={style.inner}>
				<div className={style.front}>{current}</div>
			</div>
		</div>
	);
};
