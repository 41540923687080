import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { customBaseQuery, selectDataFromResponse } from 'services/http.service';
import { UserNotification } from 'common/interfaces/notifications.interface';

const NOTIFICATIONS_ENDPOINT = '/notifications';

export const notificationsApi = createApi({
	reducerPath: 'notificationsApi',
	baseQuery: customBaseQuery,
	tagTypes: ['Notification'],
	endpoints: (build) => ({
		getNotifications: build.query<UserNotification[], void>({
			query: () => `${NOTIFICATIONS_ENDPOINT}/latest`,
			providesTags: ['Notification'],
			transformResponse: selectDataFromResponse
		}),
		readNotifications: build.mutation<void, string[]>({
			query: (items) => ({
				url: `${NOTIFICATIONS_ENDPOINT}/read`,
				method: 'PATCH',
				body: {
					items
				}
			}),
			invalidatesTags: ['Notification']
		})
	})
});

export const { useGetNotificationsQuery, useReadNotificationsMutation } =
	notificationsApi;
