import React from 'react';
import { TabsTrigger, TabsTriggerProps } from '@radix-ui/react-tabs';
import style from './tabs.module.scss';
import cn from 'classnames';

type Props = {
	children: React.ReactNode;
	className?: string;
} & TabsTriggerProps;

export function TabsItem({ children, className, ...props }: Props) {
	return (
		<TabsTrigger {...props} className={cn(style.item, className ? className : '')}>
			{children}
		</TabsTrigger>
	);
}
