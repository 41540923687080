import React, { useState, useEffect } from 'react';
import { RouteObject, useLocation, useRoutes } from 'react-router-dom';

import style from './transition.module.scss';

interface TransitionRouterType {
	routes: RouteObject[];
}

export const TransitionRouter = ({ routes }: TransitionRouterType) => {
	const location = useLocation();

	const [displayLocation, setDisplayLocation] = useState(location);
	const [transitionStage, setTransitionStage] = useState(style.fadeIn);

	useEffect(() => {
		if (location !== displayLocation) setTransitionStage(style.fadeOut);
	}, [location, displayLocation]);

	const router = useRoutes(routes, location);

	return (
		<div
			className={`${transitionStage} ${style.container}`}
			onAnimationEnd={() => {
				if (transitionStage === style.fadeOut) {
					setTransitionStage(style.fadeIn);
					setDisplayLocation(location);
				}
			}}
		>
			{router}
		</div>
	);
};
