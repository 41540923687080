import { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetProfileQuery } from 'services/user.service';

interface LocationState {
	from?: string;
}

export const useLoggedIn = (redirectPath: string = '/') => {
	const navigate = useNavigate();
	const location = useLocation();

	const state = typeof location.state === 'object' ? location.state : {};

	const path = useMemo(() => {
		if (state?.redirectTo) {
			return state.redirectTo;
		}
		return (state as LocationState)?.from || redirectPath;
	}, [redirectPath, state]);

	const { data: user } = useGetProfileQuery();

	useEffect(() => {
		if (user) {
			navigate(path, { replace: true, state: state });
		}
	}, [user]);

	return {
		redirectTo: path,
		state: state
	};
};
