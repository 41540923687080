import httpService, { ServerResponse } from './http.service';

const PROMOTION_ENDPOINT = '/promotion';

export const requestRoundUp = async (payload: {
	email: string;
	recaptcha?: string;
}): Promise<any> => {
	const { data } = await httpService.post<ServerResponse<number>>(
		`${PROMOTION_ENDPOINT}/roundup`,
		payload
	);
	if (data.status !== 200) {
		throw new Error(data.message);
	}
};
