import React, { useState } from 'react';
import classNames from 'classnames';
import { SortItem } from './SortItem';
import style from './sort.module.scss';

interface SortProps {
	className?: string;
	controls: Record<string, string>;
	onSort: (control: string) => void;
}

export function Sort({ controls, onSort, className = '' }: SortProps) {
	const [state, setState] = useState<{
		name: string;
		active: boolean;
	}>();

	const handleSort = (control: string) => {
		setState((state) => ({
			name: control,
			active: state?.name === control ? !state!.active : true
		}));
		onSort(control);
	};

	return (
		<div className={classNames(style.sort, className)}>
			{Object.entries(controls).map(([name, title]) => (
				<SortItem
					key={name}
					title={title}
					control={name}
					active={state?.name === name && state.active}
					onClick={handleSort}
				/>
			))}
		</div>
	);
}
