export type PasswordRule = 'longEnough' | 'hasNumber' | 'hasSymbol';
export type PasswordStrength = 'strong' | 'medium' | 'weak';

export interface PasswordChecklistProps {
	value: string;
	onChange?: (isValid: boolean) => any;
}

export const passwordRules: Record<PasswordRule, string> = {
	longEnough: 'At least 8 characters',
	hasNumber: 'Contains a number',
	hasSymbol: 'Contains a symbol'
};
