interface DelimiterProps {
	className?: string;
	backgroundColor?: string;
	height?: number;
}

export const Delimiter = ({ className, height = 1, backgroundColor }: DelimiterProps) => {
	return (
		<hr
			className={className}
			style={{
				width: '100%',
				display: 'block',
				height: `${height}px`,
				backgroundColor,
				border: 'none'
			}}
		/>
	);
};
