import React, { useEffect, useState } from "react";
import { Contest } from 'common/interfaces/contest.interface';
import { SportIcon } from 'components/ui/Icon/SportIcon';
import { ContestItem } from 'components/ui/Contest/ContestItem';
import { useGetMembershipQuery, useGetTokensQuery } from "services/nft.service";

import style from './contest.module.scss';

interface ContestListProps {
	items: Contest[];
}

export const ContestList = ({ items }: ContestListProps) => {
	const { isSuccess, data } = useGetTokensQuery();
	const { isSuccess: isSuccessMembership, data: membership } = useGetMembershipQuery();
	const [isMember, setIsMember] = useState(false);

	useEffect(() => {
		if(
			isSuccessMembership &&
			membership &&
			!membership.expired &&
			isSuccess &&
			data.length > 0
		){
			setIsMember(true);
		}
	},[isSuccessMembership, membership, isSuccess, data]);

	return (
		<div className={style.list}>
			{items.map((item) => {
				return (
					<ContestItem
						key={item._id}
						id={item._id}
						prize={item.prize}
						title={item.title}
						entrySlots={item.entrySlots}
						totalEntries={item.totalEntries}
						finishDate={item.finishAt * 1000}
						entryFee={item.entryFee || 0}
						enrolled={item.enrolled}
						isNFT={item.isNFT}
						isLive={item.isLive}
						isClosed={item.isClosed}
						isMember={isMember}
						isContestEnded={item.isContestEnded}
						type={item.type}
						icon={
							<SportIcon size={40} sport={item.sport ? item.sport : ''} />
						}
					/>
				);
			})}
		</div>
	);
};
