import React, { useCallback } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import {
	CreateOrderActions,
	CreateOrderData,
	OnApproveActions,
	OnApproveData
} from '@paypal/paypal-js';
import paymentService from 'services/payment.service';

interface PayPalProps {
	className?: string;
	amount: number;
	onApprove: () => any;
	onError: () => any;
}

export function PayPal({ amount, onApprove, onError, className = '' }: PayPalProps) {
	const createOrder = useCallback(
		(data: CreateOrderData, actions: CreateOrderActions) => {
			return actions.order.create({
				purchase_units: [
					{
						amount: {
							value: amount.toFixed(2)
						}
					}
				]
			});
		},
		[amount]
	);

	const handleApprove = async (data: OnApproveData, actions: OnApproveActions) => {
		const details = await actions.order?.capture();
		if (details) {
			await paymentService.confirmTransaction({
				transaction_id: details.id,
				payment_gateway: 'PayPal'
			});
			onApprove();
		}
	};

	return (
		<div className={className}>
			<PayPalScriptProvider
				options={{
					'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID as string,
					currency: 'USD'
				}}
			>
				<PayPalButtons
					forceReRender={[createOrder]}
					createOrder={createOrder}
					onApprove={handleApprove}
					onError={onError}
					style={{
						color: 'gold',
						layout: 'horizontal',
						label: 'pay',
						shape: 'rect',
						tagline: false,
						height: 48
					}}
				/>
			</PayPalScriptProvider>
		</div>
	);
}
