import type { IRPCMap } from '@walletconnect/types';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { ALL_SUPPORTED_CHAIN_IDS } from 'constants/chains';
import { INFURA_NETWORK_URLS } from 'constants/infura';

export const injected = new InjectedConnector({
	supportedChainIds: ALL_SUPPORTED_CHAIN_IDS
});

export const walletConnect = new WalletConnectConnector({
	rpc: INFURA_NETWORK_URLS as IRPCMap,
	qrcode: true
});

export function resetWalletConnector(connector: AbstractConnector) {
	if (connector && connector instanceof WalletConnectConnector) {
		connector.walletConnectProvider = undefined;
	}
}
