import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useModal } from '@ebay/nice-modal-react';
import { DownloadAppModal } from 'components/modals/DownloadApp';

export const useMobileDevice = () => {
	const navigate = useNavigate();
	const userAgent = navigator.userAgent || navigator.vendor;
	const isIOS = useMemo(() => /iPad|iPhone|iPod/.test(userAgent), [userAgent]);

	const allowedPaths = [
		'/download',
		'/restricted',
		'/sign-up',
		'/reset',
		'/round',
		'/register',
		'/sale',
		'/london5',
		'/london150',
		'/login'
	];

	const location = useLocation();
	const downloadApp = useModal(DownloadAppModal);

	useEffect(() => {
		checkDevice();
	}, [location.pathname]);

	const checkDevice = () => {
		const currentRoute = location.pathname.split('/')[1];
		const width = window.innerWidth;
		const isProd = process.env.NODE_ENV !== 'development';

		if (!isProd && width <= 639 && !allowedPaths.includes(`/${currentRoute}`)) {
			downloadApp.show();
		}

		if (isIOS && location.pathname.includes('/league')) {
			setTimeout(() => {
				navigate('/');
			}, 5000);
		}
	};

	return {
		isIOS
	};
};
