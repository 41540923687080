import React from 'react';
import {
	Root as DropdownMenuRoot,
	DropdownMenuProps
} from '@radix-ui/react-dropdown-menu';

type Props = {} & DropdownMenuProps;

export function Dropdown({ children, ...props }: Props) {
	return <DropdownMenuRoot {...props}>{children}</DropdownMenuRoot>;
}
