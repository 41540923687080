import { Typography } from 'components/ui/Typography';
import classNames from 'classnames';
import style from './form-tip.module.scss';

interface FormTipProps {
	text: string;
}

export const FormTip = (props: FormTipProps) => {
	return (
		<Typography
			className={classNames('ptb-12', 'plr-20', 'mt-8', style.tip)}
			color="states-info"
			variant="body1"
			textAlign="center"
		>
			{props.text}
		</Typography>
	);
};
