import { useWeb3React } from '@web3-react/core';
import {
	injected,
	resetWalletConnector,
	walletConnect
} from 'components/wallet/connectors';
import { storageService } from 'services/storage.service';

export const useWallet = () => {
	const { account, activate, deactivate } = useWeb3React();

	const disconnectWallet = () => {
		storageService.setWallet();
		deactivate();
	};

	const connectMetamask = async () => {
		try {
			await activate(injected);
		} catch (ex) {
			console.log(ex);
		}
	};

	const connectWalletConnect = async () => {
		try {
			resetWalletConnector(walletConnect);
			await activate(walletConnect);
		} catch (ex) {
			console.log(ex);
		}
	};

	return {
		account,
		disconnectWallet,
		connectMetamask,
		connectWalletConnect
	};
};
