import { UserProfile } from 'common/interfaces/user.interface';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/index';
import { loginUser, registerUser, quickRegisterUser, logoutUser } from './user.actions';
import { userApi } from 'services/user.service';

interface UserState {
	profile?: UserProfile;
	error?: string | null;
}

const initialState: UserState = {};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(loginUser.pending, (state) => {
			state.error = null;
		});
		builder.addCase(loginUser.rejected, (state, action) => {
			state.error = action.payload as string;
		});
		builder.addCase(registerUser.pending, (state) => {
			state.error = null;
		});
		builder.addCase(registerUser.rejected, (state, action) => {
			state.error = action.payload as string;
		});
		builder.addCase(quickRegisterUser.pending, (state) => {
			state.error = null;
		});
		builder.addCase(quickRegisterUser.rejected, (state, action) => {
			state.error = action.payload as string;
		});
		builder.addCase(logoutUser.fulfilled, (state) => {
			state.profile = undefined;
			state.error = undefined;
		});
		builder.addMatcher(
			userApi.endpoints.getProfile.matchFulfilled,
			(state, action) => {
				state.profile = action.payload;
			}
		);
	}
});

export const selectUserError = (state: RootState) => state.user.error;
export const selectUserData = (state: RootState) => state.user.profile;
