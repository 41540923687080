import React from 'react';
import { Modal } from 'components/ui/Modal';
import style from './expire.membership.module.scss';
import { useNavigate } from 'react-router-dom';
import { Typography } from "../../ui/Typography";
import { Button } from "../../ui/Button";

export const RenewMembershipModal = () =>  {
	const navigate = useNavigate();

	return (
		<Modal
			className="p-40 width-popupDesktop"
			titleClassName={`m-0 ${style.title}`}
			title="Renew Membership"
			isOpen
			forRoute
			onClose={() => navigate('/')}
			leagueRenew
		>
			<Typography variant={'p2'}>
				Your membership has expired. Please renew your membership to continue being the member of the league.
			</Typography>
			<Button variant={'outlined'} className={'primary'} href={'/league-renewal/renewleague'} fullWidth={true}>
				Renew Membership
			</Button>
		</Modal>
	);
};

