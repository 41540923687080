import React, {useEffect, useMemo} from 'react';
import diffInDays from 'date-fns/differenceInDays';
import classNames from 'classnames';
import { useGetStatsQuery } from 'services/nft.service';
import { DashboardChart } from './Chart';
import { Typography } from 'components/ui/Typography';
import style from '../league.module.scss';
import { ArrowRightIcon } from 'components/ui/Icon';
import {
	useGetProfileQuery,
	useGetProfileTermsQuery
} from "../../../services/user.service";
import {useAcceptNewTerms} from "../../../hooks/useAcceptNewTerms";

interface StatsItemProps {
	label: string;
	children?: React.ReactNode;
}

const StatsItem = ({ label, children }: StatsItemProps) => {
	return (
		<div className={style.stats_item}>
			<Typography variant="body1" color="states-info-info" className="m-0">
				{label}
			</Typography>
			<Typography variant="p1" weight="bold" className="m-0">
				{children}
			</Typography>
		</div>
	);
};

export function DashboardStats() {
	const { data } = useGetStatsQuery();
	let items = data?.items;

	const diffPercentage = useMemo(() => {
		if (!data?.items || data.items.length < 2) return 0;

		const [current, prev] = data.items;
		const diff = current.totalPrize - prev.totalPrize;

		return prev.totalPrize > 0 ? Math.floor((diff / prev.totalPrize) * 100) : 100;
	}, [data]);

	const totalStats = useMemo(() => {
		if (data) {
			const { items, ...stats } = data;
			return stats;
		}

		return {
			totalPoints: 0,
			totalPrize: 0,
			avgPlace: 0,
			moneyShare: 0
		};
	}, [data]);

	const lastWeekPrize = useMemo(() => {
		if (!data?.items?.length) return 0;

		// items = [...data.items].reverse();
		items = [...data.items];

		const item = items[0];
		const days = diffInDays(new Date(), new Date(item.date));

		return days <= 7 ? +item.totalPrize.toFixed(2) : 0;
	}, [data]);

	return (
		<div style={{ width: '100%', height: '100%' }} className={style.stats}>
			{data && <DashboardChart data={items} className="mr-24" />}

			<div className={style.stats_inner}>
				<div>
					<Typography
						variant="p3"
						color="states-info-info"
						className="m-0"
						weight="semi-bold"
					>
						7-days Winnings
					</Typography>
					<Typography variant="h5" className="m-0" weight="semi-bold">
						{lastWeekPrize}$
					</Typography>

					<div className="mt-8 flex items-center">
						<div
							className={classNames(
								style.difference,
								diffPercentage >= 0
									? style.difference_inc
									: style.difference_dec
							)}
						>
							<ArrowRightIcon size={11} className={style.difference_icon} />{' '}
							{diffPercentage}%
						</div>

						<Typography
							variant="body2"
							color="states-info-info"
							className="m-0 ml-8"
						>
							than previous
						</Typography>
					</div>
				</div>

				<div className={style.items}>
					<StatsItem label="Total Winnings">
						${totalStats.totalPrize.toFixed(2)}
					</StatsItem>
					<StatsItem label="% in the Money">
						{totalStats.moneyShare.toFixed(2)}%
					</StatsItem>
					<StatsItem label="Total Points Earned">
						{totalStats.totalPoints.toFixed(2)}
					</StatsItem>
					<StatsItem label="Average Place">
						{totalStats.avgPlace.toFixed(2)}
					</StatsItem>
				</div>
			</div>
		</div>
	);
}
