import React from 'react';
import RcPagination, { PaginationProps } from 'rc-pagination';
// import 'rc-pagination/assets/index.css';
import style from './pagination.module.scss';

export function Pagination(props: PaginationProps) {
	return (
		<RcPagination
			{...props}
			hideOnSinglePage={true}
			showPrevNextJumpers={false}
			showQuickJumper={false}
			className={style.pagination}
		/>
	);
}
