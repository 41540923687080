import { createApi } from '@reduxjs/toolkit/dist/query/react';
import httpService, { customBaseQuery, ServerResponse } from 'services/http.service';
import { Contest } from 'common/interfaces/contest.interface';
import { storageService } from './storage.service';

import { nftApi } from './nft.service';
import { userApi } from './user.service';

const CONTEST_ENDPOINT = '/contests';

const selectDataFromResponse = (response: ServerResponse<Contest[]>) => response.data;

interface UserContestSelectionParams {
	user_id: string;
	contest_id: string;
}

export const contestApi = createApi({
	reducerPath: 'contestApi',
	baseQuery: customBaseQuery,
	tagTypes: ['Contest', 'ContestList'],
	endpoints: (build) => ({
		getAvailableContests: build.query<Contest[], number | void>({
			query: (limit = 0) => ({
				url: `${CONTEST_ENDPOINT}/list/available`,
				params: { limit, liveList: false, userId: storageService.getUserId(),isWeb:true }
			}),
			transformResponse: selectDataFromResponse,
			providesTags: ['ContestList']
		}),
		getLiveContests: build.query<Contest[], number | void>({
			query: (limit = 0) => ({
				url: `${CONTEST_ENDPOINT}/list/available`,
				params: { limit, liveList: true, userId: storageService.getUserId(),isWeb:true }
			}),
			transformResponse: selectDataFromResponse,
			providesTags: ['ContestList']
		}),
		getSingleContest: build.query<Contest, any>({
			query: (contest_id) => ({
				url: `${CONTEST_ENDPOINT}/single/full/${contest_id}`,
				method: 'GET'
			}),
			providesTags: ['Contest'],
			transformResponse: (response: ServerResponse<Contest>) => response.data
		}),
		getUserContestPicks: build.query<any, any>({
			query: (params: UserContestSelectionParams) => ({
				url: `${CONTEST_ENDPOINT}/getusercontestpicks`,
				method: 'GET',
				params: params
			}),
			providesTags: [],
			transformResponse: (response: ServerResponse<Contest>) => response.data
		}),
		enrollContest: build.mutation<any, any>({
			query: (selection) => ({
				url: `${CONTEST_ENDPOINT}/join/${selection.contest_id}`,
				method: 'POST',
				body: {
					selections: selection.selections,
					pgaPlayers: selection.pgaPlayers,
					nftToken: selection.tokenId,
					picSixSelections: selection.picSixSelections
				}
			}),
			transformResponse: (response: ServerResponse<any>): string =>
				response.message,
			async onCacheEntryAdded({}, { dispatch }) {
				setTimeout(() => {
					dispatch(nftApi.util.invalidateTags(['Token', 'Wallet']));
					dispatch(contestApi.util.invalidateTags(['Contest', 'ContestList']));
					dispatch(contestApi.util.invalidateTags(['Contest', 'ContestList']));
					dispatch(userApi.util.invalidateTags(['Profile']));
				}, 5000);
			}
		}),
		updateUserContest: build.mutation<any, any>({
			query: (selection) => ({
				url: `${CONTEST_ENDPOINT}/join/${selection.contest_id}`,
				method: 'PATCH',
				body: {
					selections: selection.selections,
					pgaPlayers: selection.pgaPlayers,
					nftToken: selection.tokenId,
					picSixSelections: selection.picSixSelections
				}
			}),
			transformResponse: (response: ServerResponse<any>): string =>
				response.message,
			async onCacheEntryAdded({}, { dispatch }) {
				setTimeout(() => {
					dispatch(nftApi.util.invalidateTags(['Token', 'Wallet']));
					dispatch(contestApi.util.invalidateTags(['Contest', 'ContestList']));
				}, 5000);
			}
		}),
		getCompletedContests: build.query<Contest[], number | void>({
			query: (page = 1) => `${CONTEST_ENDPOINT}/list/completed?page=${page}`,
			transformResponse: selectDataFromResponse,
			providesTags: ['ContestList']
		})
	})
});

export const selectionMade = async (contestId: string): Promise<any> => {
	httpService.post(
		`${CONTEST_ENDPOINT}/selection_made`,
		{
			contest_id: contestId,
			user_id: storageService.getUserId()
		},
		{ headers: { Authorization: String(storageService.getAccessToken()) } }
	);
};

export const getContestHistory = async (page: Number): Promise<any> => {
	const { data } = await httpService.get(
		`${CONTEST_ENDPOINT}/list/completed?page=${page}&is_app=0`,
		{ headers: { Authorization: String(storageService.getAccessToken()) } }
	);
	return data;
};

export const getMarketplaceHistory = async (): Promise<any> => {
	const { data } = await httpService.get(
		`${CONTEST_ENDPOINT}/list/marketplacehistory`,
		{ headers: { Authorization: String(storageService.getAccessToken()) } }
	);
	return data;
};

export const {
	useGetAvailableContestsQuery,
	useGetLiveContestsQuery,
	useGetCompletedContestsQuery,
	useEnrollContestMutation,
	useUpdateUserContestMutation,
	useGetSingleContestQuery,
	useGetUserContestPicksQuery
} = contestApi;
