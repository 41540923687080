import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Typography } from 'components/ui/Typography';
import { Button } from 'components/ui/Button';
import { WalletIcon } from 'components/ui/Icon';
import { WalletsModal } from 'components/modals/Wallets';

import style from './tokens.module.scss';

export function TokensWallet() {
	const walletsModal = useModal(WalletsModal);

	return (
		<div className={style.navigation}>
			<div>
				<Typography>Have NFT Token?</Typography>
				<Button variant="outlined" href="/league/connect">
					Connect NFT Wallet
				</Button>
			</div>

			<Button
				variant="text"
				color="primary"
				startIcon={<WalletIcon />}
				onClick={() => walletsModal.show()}
			>
				View Wallets
			</Button>
		</div>
	);
}
