import React, { useState } from 'react';
import { EyeIcon } from 'components/ui/Icon';
import { InputProps } from './Input.model';
import { Input } from './Input';

interface InputPasswordProps extends InputProps {
	visibilityToggle?: boolean;
}

export const InputPassword = React.forwardRef<
	HTMLInputElement,
	React.PropsWithChildren<InputPasswordProps>
>(({ visibilityToggle = true, ...props }, ref) => {
	const [visible, setVisible] = useState<boolean>(false);

	const onVisibleChange = () => {
		if (props.disabled) return;
		setVisible(!visible);
	};
	const icon = visibilityToggle && (
		<EyeIcon color="white" active={visible} onClick={onVisibleChange} />
	);

	return (
		<Input {...props} type={visible ? 'text' : 'password'} endIcon={icon} ref={ref} />
	);
});
