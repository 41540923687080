import { Body } from 'components/layout/Body';
import { Main } from 'components/layout/Main';
import { Content } from 'components/modals/DownloadApp/Content';
import { useEffect } from 'react';
import { useMobileDevice } from 'hooks/useMobileDevice';

const downloadAppRedirect = process.env.REACT_APP_APP_STORE_URL;

export const DownloadApp = () => {
	const { isIOS } = useMobileDevice();

	useEffect(() => {
		if (isIOS) {
			window.location.href = String(downloadAppRedirect);
		}
	}, []);
	return (
		<Body>
			<Main>
				<Content />
			</Main>
		</Body>
	);
};
