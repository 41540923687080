import React from 'react';
import classNames from 'classnames';
import { InputProps } from './Input.model';
import style from './input.module.scss';

export const Input = React.forwardRef<
	HTMLInputElement,
	React.PropsWithChildren<InputProps>
>(
	(
		{
			className,
			color,
			fieldBgColor = 'neutral_01',
			label,
			helperText,
			type = 'text',
			placeholder = 'Placeholder',
			error,
			disabled,
			startIcon,
			endIcon,
			size,
			fullWidth,
			rows,
			highlighted = false,
			...props
		},
		ref
	) => {
		const labelElement = label ? <label className="pt-4">{label}</label> : null;
		const helperElement = label ? (
			<label className={classNames(style.helperText, 'pt-4')}>{helperText}</label>
		) : null;

		const startIconElement = startIcon ? (
			<span className={classNames(style.icon, style.iconStart)}>{startIcon}</span>
		) : (
			''
		);
		const endIconElement = endIcon ? (
			<span className={classNames(style.icon, style.end)}>{endIcon}</span>
		) : (
			''
		);

		const inputClassName = classNames(
			fieldBgColor && style[`background--${fieldBgColor}`]
		);

		const inputElement = rows ? (
			<textarea
				{...props}
				ref={ref as any}
				className={inputClassName}
				rows={rows}
				placeholder={placeholder}
				disabled={disabled}
			/>
		) : (
			<input
				{...props}
				ref={ref}
				className={classNames(inputClassName, highlighted && style.highlighted)}
				type={type}
				placeholder={placeholder}
				disabled={disabled}
			/>
		);

		return (
			<div
				className={classNames(
					style.wrapper,
					error && style.invalid,
					disabled && style.disabled,
					fullWidth && style.expanded,
					size && style[size],
					color && style[color],
					className
				)}
			>
				<div className={style.field}>
					{startIconElement}
					{endIconElement}
					{inputElement}
					{error && helperElement ? helperElement : labelElement}
				</div>
			</div>
		);
	}
);
