import { Avatar } from 'components/ui/Avatar';
import { Typography } from 'components/ui/Typography';
import style from './profile-dropdown.module.scss';
import classNames from 'classnames';

interface NameProps {
	avatar: string;
	name: string;
	className?: string;
}

export const Name = (props: NameProps) => {
	return (
		<div className={classNames(style.name, props.className)}>
			<Avatar className="mr-16" src={props.avatar} alt={props.name} />
			<Typography variant="p1" component="span" weight="semi-bold">
				{props.name}
			</Typography>
		</div>
	);
};
