import React from 'react';
import { DropdownMenuItem, DropdownMenuItemProps } from '@radix-ui/react-dropdown-menu';
import style from './dropdown.module.scss';

type Props = {} & DropdownMenuItemProps;

export const DropdownItem = ({ children, ...props }: Props) => {
	return (
		<DropdownMenuItem
			{...props}
			className={props.className ? props.className : style.item}
		>
			{children}
		</DropdownMenuItem>
	);
};
