import React from 'react';
import { Typography } from 'components/ui/Typography';
import { formatSum } from 'utils/format-sum';

import style from './purchase.module.scss';

interface PurchaseInfoItemProps {
	total?: boolean;
	children: string;
	value: number;
}

const PurchaseInfoItem = ({ children, value, total = false }: PurchaseInfoItemProps) => {
	return (
		<Typography variant="p2">
			{children}: <Typography component="span">{formatSum(value)}</Typography>
		</Typography>
	);
};

interface PurchaseInfoProps {
	balance: number;
	futureBalance: number;
	product: {
		price: number;
		name: string;
	};
}

export function PurchaseInfo({ balance, futureBalance, product }: PurchaseInfoProps) {
	return (
		<div className={style.info}>
			<PurchaseInfoItem value={balance}>Current Balance</PurchaseInfoItem>
			<PurchaseInfoItem
				value={product.price}
			>{`${product.name} Cost`}</PurchaseInfoItem>
			<PurchaseInfoItem
				value={
					balance - product.price > 0 ? 0 : Math.abs(balance - product.price)
				}
			>
				Total Outstanding
			</PurchaseInfoItem>
		</div>
	);
}
