import React from 'react';
import classNames from 'classnames';
import style from 'components/layout/Grid/grid.module.scss';

interface ContainerProps {
	className?: string;
	centered?: boolean;
	align?: string;
	style?: React.CSSProperties;
}

export const Container = (props: React.PropsWithChildren<ContainerProps>) => {
	return (
		<div
			style={{ ...props.style }}
			className={classNames(
				style.container,
				props.className,
				props.centered || props.centered === undefined ? style.centered : false,
				props.align ? style[`align--${props.align}`] : false
			)}
		>
			{props.children}
		</div>
	);
};
