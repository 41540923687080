import React from 'react';
import { Body } from './Body';
import { Header } from './Header';
import { Main } from './Main';
import { Outlet } from 'react-router-dom';
import { Theme } from 'components/layout/Body/Body';

interface BaseLayoutProps extends React.PropsWithChildren {
	mainClassName?: string;
	bodyClassName?: string;
	theme?: Theme;
}

export const BaseLayout = ({
	children,
	mainClassName = '',
	bodyClassName = '',
	theme
}: BaseLayoutProps) => {
	return (
		<>
			<Body theme={theme} className={bodyClassName}>
				<Header />

				<Main className={mainClassName}>{children}</Main>
			</Body>

			<Outlet />
		</>
	);
};
