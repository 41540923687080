import React from 'react';
import { Modal } from 'components/ui/Modal';
import { Typography } from 'components/ui/Typography';
import { ForgotPasswordForm } from 'components/forms/PasswordForm';

export function ForgotPassword() {
	return (
		<Modal
			title="Reset password"
			className="p-40 width-popupDesktop"
			titleClassName="ptb-24 m-0"
			isOpen
			forRoute
		>
			<Typography variant="p2">
				We will send a link to your email to reset a password
			</Typography>

			<ForgotPasswordForm />
		</Modal>
	);
}
