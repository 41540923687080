import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/index';
import { logoutUser } from 'store/user/user.actions';

interface NFTState {
	activeToken?: number;
}

const initialState: NFTState = {};

export const nftSlice = createSlice({
	name: 'nft',
	initialState,
	reducers: {
		setActiveToken(state, action: PayloadAction<number>) {
			state.activeToken = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(logoutUser.fulfilled, (state) => {
			state.activeToken = undefined;
		});
	}
});

export const { setActiveToken } = nftSlice.actions;
export const selectNFTActiveToken = (state: RootState) => state.nft.activeToken;
