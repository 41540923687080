import React, { useState, useMemo, useEffect } from 'react';
import formatDate from 'date-fns/format';
import Calendar from 'react-calendar';
import OutsideClickHandler from 'react-outside-click-handler';
import { Input } from 'components/ui/Input';
import { CalendarIcon } from 'components/ui/Icon';
import { DatePickerProps } from './DatePicker.model';
import 'react-calendar/dist/Calendar.css';

export const DatePicker = React.forwardRef<HTMLInputElement, DatePickerProps>(
	({ onChange, defaultValue, ...props }, ref) => {
		const [visible, setVisible] = useState<boolean>(false);
		const [date, setDate] = useState<Date>();
		const formattedDate = useMemo(
			() => (date ? formatDate(date, 'dd/MM/yyyy') : ''),
			[date]
		);

		useEffect(() => {
			if (defaultValue) setDate(new Date(defaultValue as string));
		}, [defaultValue]);

		const handleChange = (value: Date) => {
			setDate(value);
			if (value) setVisible(false);
			if (onChange) onChange(value);
		};

		return (
			<div>
				<Input
					{...props}
					value={formattedDate}
					placeholder={'Pick up the date'}
					onFocus={() => setVisible(true)}
					endIcon={<CalendarIcon stroke="#fff" color="transparent" />}
					readOnly
				/>
				{visible && (
					<OutsideClickHandler onOutsideClick={() => setVisible(false)}>
						<Calendar locale="en" value={date} onChange={handleChange} />
					</OutsideClickHandler>
				)}
			</div>
		);
	}
);
