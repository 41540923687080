import IPhone from 'assets/images/reviews/feature3-phone.png';
import Popup from 'assets/images/reviews/feature3-popup.png';

import { ParallaxModule, DependentImage, BaseImage } from 'components/ui/ParallaxModule';

export const WinFeature = () => {
	const dependentImage = (
		<DependentImage
			src={Popup}
			alt="Winning Card"
			width="80%"
			// centered="horizontal"
			topOffset="30%"
		/>
	);
	const baseImage = (
		<BaseImage src={IPhone} alt="IPhone image" dependentImage={dependentImage} />
	);
	return (
		<div
			style={{
				position: 'relative'
			}}
		>
			<ParallaxModule baseImage={baseImage} width="70%" />
		</div>
	);
};
