import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import style from './parallax.module.scss';

type JustifyContent = 'center' | 'flex-end' | 'flex-start';

interface ImageProps {
	src: string;
	alt: string;
	borderRadius?: string;
}

interface BaseImageProps extends ImageProps {
	height?: string;
	dependentImage: JSX.Element;
	justifyContent?: JustifyContent;
}

interface DependentImageProps extends ImageProps {
	width: string;
	centered?: string; // vertical, horizontal, both
	topOffset?: string;
	leftPosition?: string;
	height?: string;
	index?: number;
	rotateAnimation?: boolean;
}

const getElementOffset = (el: HTMLElement | null | undefined) => {
	let top = 0;
	let left = 0;
	let element = el;

	// Loop through the DOM tree
	// and add it's parent's offset to get page offset
	do {
		top += element?.offsetTop || 0;
		left += element?.offsetLeft || 0;
		element = element?.offsetParent as HTMLElement;
	} while (element);

	return {
		top,
		left
	};
};

const StyledBase = styled.div<Partial<BaseImageProps>>`
	border-radius: ${(props) => props.borderRadius || 0};
	width: 100%;
	height: ${(props) => props.height || 'auto'};
	justify-content: ${(props) => props.justifyContent || 'center'};
`;
export const BaseImage = ({ src, alt, dependentImage, ...props }: BaseImageProps) => {
	return (
		<StyledBase className={style.base} {...props}>
			<img src={src} alt={alt} />
			{dependentImage}
		</StyledBase>
	);
};

const StyledDependent = styled.div<Partial<DependentImageProps>>`
	width: ${(props) => props.width || '100%'};
	height: ${(props) => props.height || 'auto'};
	top: ${(props) => props.topOffset || '0px'};
	left: ${(props) => (props.leftPosition ? props.leftPosition + ' !important ' : '0')};
	z-index: ${(props) => props.index || '0'};
`;
export const DependentImage = ({
	rotateAnimation,
	centered,
	src,
	alt,
	...props
}: DependentImageProps) => {
	const ref = useRef<HTMLImageElement>(null);
	let transform = {
		transform: `translateY(${0}px)`
	};
	let handleProcess = false;

	useEffect(() => {
		const element = ref?.current;
		const offsetY = getElementOffset(element).top;
		const innerHeight = window.innerHeight;

		const handleScroll = () => {
			if (handleProcess) return;
			handleProcess = true;
			setTimeout(() => {
				handleProcess = false;
			}, 0);
			const windowBottom = window.scrollY + innerHeight;
			const difference = windowBottom - offsetY;
			if (difference > 0 && element) {
				element.style.transform = `translateY(${-100 + difference / 30}px)`;
			}
			if (difference > 0 && element && rotateAnimation) {
				element.style.transform = `translateY(${
					-100 + difference / 30
				}px) rotate(${difference / 20}deg) `;
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<StyledDependent
			className={classNames(
				style.dependent,
				centered ? style[`centered--${centered}`] : false
			)}
			{...props}
		>
			<img ref={ref} src={src} alt={alt} style={transform} />
		</StyledDependent>
	);
};

interface ParallaxProps {
	baseImage: JSX.Element;
	width?: string;
	height?: string;
	rotate?: string;
}

const StyledHolder = styled.div<Partial<ParallaxProps>>`
	width: ${(props) => props.width || '100%'};
	height: ${(props) => props.height || 'auto'};
	transform: ${(props) => (props.rotate ? `rotate(${props.rotate})` : 'none')};
`;
export const ParallaxModule = ({ baseImage, ...props }: ParallaxProps) => {
	return (
		<StyledHolder className={style.parallax} {...props}>
			{baseImage}
		</StyledHolder>
	);
};
