import IPhone from 'assets/images/reviews/lasVegas-phone.png';
import Card from 'assets/images/reviews/lasvegas-card.png';
import CardTwo from 'assets/images/reviews/lasvegas-progress.svg';

import { ParallaxModule, DependentImage, BaseImage } from 'components/ui/ParallaxModule';
import useWindowDimensions from 'hooks/useWindowDimensions';

export const WinningPhoneFeature = () => {
	const { isLaptop, isTable } = useWindowDimensions();
	const dependentImage = (
		<>
			<DependentImage
				src={Card}
				alt="Winning Card"
				width={isLaptop ? '90%' : '75%'}
				centered="horizontal"
				topOffset="15%"
			/>
			<DependentImage
				src={CardTwo}
				alt="Winning Card"
				width={isLaptop ? '85%' : '75%'}
				centered="horizontal"
				topOffset="55%"
			/>
		</>
	);
	const baseImage = (
		<BaseImage
			height={isTable ? 'initial' : ''}
			src={!isTable ? IPhone : Card}
			alt="IPhone image"
			justifyContent={isTable ? 'center' : 'flex-start'}
			dependentImage={!isTable ? dependentImage : <></>}
		/>
	);
	return (
		<div
			style={{
				position: 'relative'
			}}
		>
			<ParallaxModule baseImage={baseImage} />
		</div>
	);
};
