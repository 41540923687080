import React from 'react';
import classNames from 'classnames';

import style from './review.module.scss';
import { Rating } from 'components/ui/Rating';
import { Typography } from 'components/ui/Typography';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

interface ReviewProps {
	stars: number;
	quote?: string;
	author?: string;
	className?: string;
	avatar?: JSX.Element;
}

export const Review = (props: React.PropsWithChildren<ReviewProps>) => {
	const { isMobile } = useWindowDimensions();

	return (
		<div
			className={classNames(props?.className, style.review)}
			style={{ textAlign: isMobile ? 'center' : 'initial' }}
		>
			{props?.avatar}
			<Rating stars={props.stars} />
			<Typography variant="p1" textStyle="italic" weight="regular">
				{props.quote}
			</Typography>
			<Typography variant="p3"> {props.author}</Typography>
		</div>
	);
};
