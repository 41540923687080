import React from 'react';
import classNames from 'classnames';
import style from './navigation.module.scss';

type Props = {
	children: JSX.Element[];
	className?: string;
};

export function Navigation({ className, children }: Props) {
	return <nav className={classNames(style.navigation, className)}>{children}</nav>;
}
