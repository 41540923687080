import React from 'react';
import classNames from 'classnames';
import { Link as NavLink } from 'react-router-dom';

import { ButtonProps } from './Button.model';
import style from './button.module.scss';

export const Button = React.forwardRef<any, React.PropsWithChildren<ButtonProps>>(
	(
		{
			children,
			className,
			variant = 'default',
			disableShadow,
			disabled = false,
			startIcon,
			endIcon,
			size,
			color = 'default',
			href,
			fullWidth,
			state,
			...props
		},
		ref
	) => {
		const fullClassName = classNames(
			style.button,
			style[variant],
			!disableShadow && style.shadow,
			disabled && style.disabled,
			size && style[size],
			style[color],
			fullWidth && style.fullWidth,
			className
		);

		return !href ? (
			<button {...props} className={fullClassName} disabled={disabled} ref={ref}>
				{startIcon}
				{children}
				{endIcon}
			</button>
		) : (
			<NavLink
				state={state}
				{...props}
				className={fullClassName}
				to={href}
				ref={ref}
			>
				{startIcon}
				{children}
				{endIcon}
			</NavLink>
		);
	}
);
