import IPhone from 'assets/images/reviews/review2-iphone.png';
import Contest from 'assets/images/reviews/review2-contest.svg';
import ContestOpacity from 'assets/images/reviews/review2-contest-opacity.svg';

import { ParallaxModule, DependentImage, BaseImage } from 'components/ui/ParallaxModule';
import useWindowDimensions from 'hooks/useWindowDimensions';

export const IPhoneFeature = () => {
	const { isLaptop, isTable } = useWindowDimensions();
	const dependentImage = (
		<>
			<DependentImage
				src={Contest}
				alt="Contest example"
				width={isLaptop ? '100%' : '80%'}
				index={1}
				centered="horizontal"
				topOffset={isLaptop ? '60%' : '50%'}
				leftPosition={isLaptop ? '5%' : '25%'}
			/>
			<DependentImage
				src={ContestOpacity}
				alt="ContestTwo example"
				width={isLaptop ? '100%' : '80%'}
				index={1}
				centered="horizontal"
				topOffset={isLaptop && !isTable ? '30%' : isTable ? '25%' : '15%'}
				leftPosition={isLaptop ? '25%' : '45%'}
			/>
		</>
	);
	const baseImage = (
		<BaseImage
			height={isTable ? 'initial' : ''}
			src={IPhone}
			alt="IPhone image"
			justifyContent="flex-end"
			dependentImage={dependentImage}
		/>
	);
	return (
		<div
			style={{
				position: 'relative'
			}}
		>
			<ParallaxModule height="inherit" baseImage={baseImage} width="100%" />
		</div>
	);
};
