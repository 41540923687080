import { useState, useRef, useEffect } from 'react';

const DEFAULT_DELAY = 1000;

function useInterval(callback: Function, delay: number | null) {
	const callbackRef = useRef<Function>();

	// update callback function with current render callback that has access to latest props and state
	useEffect(() => {
		callbackRef.current = callback;
	});

	useEffect(() => {
		if (!delay) {
			return () => {};
		}

		const interval = setInterval(() => {
			callbackRef.current && callbackRef.current();
		}, delay);
		return () => clearInterval(interval);
	}, [delay]);
}

export const useCountdown = (
	timeToCount: number,
	step: number,
	delay = DEFAULT_DELAY
) => {
	const [timeLeft, setTimeLeft] = useState<number>(0);
	const [isRunning, setIsRunning] = useState<boolean>(false);

	const startTimer = () => {
		setTimeLeft(timeToCount);
		setIsRunning(true);
	};

	const stopTimer = () => {
		setIsRunning(false);
		setTimeLeft(0);
	};

	useInterval(
		() => {
			if (timeLeft <= 0) stopTimer();
			else setTimeLeft((timeLeft) => timeLeft - step);
		},
		isRunning ? delay : null
	);

	return { counter: timeLeft, startTimer, stopTimer };
};
