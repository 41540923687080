import Boosts from 'assets/images/reviews/feature4-boosts.png';
import Wheel from 'assets/images/reviews/feature4-wheel.png';

import { ParallaxModule, DependentImage, BaseImage } from 'components/ui/ParallaxModule';
import useWindowDimensions from 'hooks/useWindowDimensions';

export const BoostsFeature = () => {
	const { isLaptop, isTable } = useWindowDimensions();
	const dependentImage = (
		<DependentImage
			src={Wheel}
			alt="Wheel example"
			width={isLaptop ? '90%' : '70%'}
			centered="horisontal"
			topOffset="40%"
			leftPosition={isTable ? '40%' : '25%'}
			index={-1}
			rotateAnimation={true}
		/>
	);
	const baseImage = (
		<BaseImage
			height={isTable ? 'initial' : ''}
			src={Boosts}
			alt="IPhone image"
			justifyContent="flex-start"
			dependentImage={dependentImage}
		/>
	);
	return (
		<div
			style={{
				position: 'relative'
			}}
		>
			<ParallaxModule baseImage={baseImage} />
		</div>
	);
};
