import React from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { ReactComponent as DefaultLogo } from 'assets/images/logo.svg';
import { ReactComponent as WhiteLogo } from 'assets/images/logo-white.svg';
import { ReactComponent as VerticalLogo } from 'assets/images/logo-vertical.svg';
import { LogoProps } from './Logo.model';
import style from './logo.module.scss';

export const Logo = ({
	className,
	size,
	variant = 'default',
	color = 'default'
}: LogoProps) => {
	const Image =
		variant === 'vertical'
			? VerticalLogo
			: color === 'default'
			? DefaultLogo
			: WhiteLogo;

	return (
		<div
			className={classNames(
				style.logo,
				style[variant],
				size && style[size],
				className
			)}
		>
			<Link to="/">
				<Image />
			</Link>
		</div>
	);
};
