import React, { useMemo, useCallback } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useGetProfileQuery } from 'services/user.service';
import { Modal } from 'components/ui/Modal';
import { VerifyOtpForm, VerifyOtpFormProps } from 'components/forms/VerifyOtpForm';
import { Typography } from 'components/ui/Typography';
import { Link } from 'components/ui/Link';

interface VerifyIdentityModalProps {
	type: VerifyOtpFormProps['type'];
	description: string;
	onSuccess: (otp?: string) => any;
}

export const VerifyIdentityModal = NiceModal.create(
	({ type, description, onSuccess }: VerifyIdentityModalProps) => {
		const bankVerify = 'bankverify';
		const modal = useModal();
		const { data: user } = useGetProfileQuery();

		const otpRecipient = useMemo(
			() => (type === 'phone' ? user?.mobileNo : user?.email),
			[type, user]
		);
		const handleSuccess = useCallback(
			(otp: string) => {
				onSuccess(otp);
				modal.remove();
			},
			[onSuccess, modal]
		);

		return (
			<Modal
				className="p-40 width-popupDesktop"
				title="Verify your Identity"
				isOpen={modal.visible}
				onClose={modal.remove}
			>
				<Typography variant="p3">{description}</Typography>
				<Typography variant="p3" weight="bold">
					{otpRecipient}
				</Typography>

				<VerifyOtpForm
					type={type}
					to={otpRecipient as string}
					onSuccess={handleSuccess}
					bankVerify={bankVerify}
				/>

				<Typography variant="p2" textAlign="center">
					<Typography className="pr-8" variant="p2" component="span">
						Don’t have an access to the {type}?
					</Typography>
					<Link underline="always" href="/support" color="desktop">
						<Typography color="desktop" variant="p2" component="span">
							Contact Support
						</Typography>
					</Link>
				</Typography>
			</Modal>
		);
	}
);
