import React from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Dialog from '@radix-ui/react-dialog';
import { Typography } from '../Typography';
import { ModalProps } from './Modal.model';
import { CloseIcon } from 'components/ui/Icon';

import style from './modal.module.scss';

export function Modal({
	isOpen,
	title,
	titleVariant,
	className,
	children,
	forRoute = false,
	leagueRenew =false,
	...props
}: ModalProps) {
	const navigate = useNavigate();
	const location = useLocation();

	const onClose = () => {
		if (props.onClose) props.onClose();
		if(leagueRenew){
			navigate('/');
			return
		}
		if (forRoute) {
			if (location?.state?.redirectTo) {
				navigate(location.state.redirectTo);
				return;
			}
			const routes = location.pathname.split('/');
			navigate(`/${routes[routes.length - 2]}`);
		}
	};

	return (
		<Dialog.Root open={isOpen} onOpenChange={(open) => !open && onClose()}>
			<Dialog.Portal>
				<Dialog.Overlay className={style.overlay} />
				<Dialog.Content className={classNames(style.container, className)}>
					<Dialog.Close asChild>
						<button className={style.close}>
							<CloseIcon />
						</button>
					</Dialog.Close>

					{title && (
						<Dialog.Title asChild>
							<Typography
								variant={titleVariant ? titleVariant : 'h3modal'}
								weight="semi-bold"
								className={
									props.titleClassName ? props.titleClassName : ''
								}
							>
								{title}
							</Typography>
						</Dialog.Title>
					)}

					<div className={style.content}>{children}</div>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
}
