import React from 'react';
import classNames from 'classnames';
import { SuccessIcon, InfoIcon, WarnIcon, ErrorIcon } from 'components/ui/Icon';
import { Typography } from 'components/ui/Typography';
import { FormAlertProps } from './FormAlert.model';
import style from './form-alert.module.scss';

export function FormAlert({ type, children }: FormAlertProps) {
	const getIcon = () => {
		switch (type) {
			case 'success':
				return <SuccessIcon />;
			case 'info':
				return <InfoIcon />;
			case 'warn':
				return <WarnIcon />;
			case 'error':
				return <ErrorIcon />;
		}
	};

	return (
		<div className={classNames(style.container, style[type])}>
			{getIcon()}
			<Typography variant="body1">{children}</Typography>
		</div>
	);
}
