import React, { useCallback } from 'react';
import { Tooltip } from 'components/ui/Tooltip';
import { ClipboardProps } from './Clipboard.model';

export function Clipboard({ children, value, onCopy, hideContent }: ClipboardProps) {
	const handleCopy = useCallback(() => {
		navigator.clipboard.writeText(value || '');
		if (onCopy) onCopy();
	}, [value, onCopy]);

	return (
		<Tooltip content={hideContent ?? 'Copied!'} trigger="click">
			{React.cloneElement(children, { onClick: handleCopy })}
		</Tooltip>
	);
}
