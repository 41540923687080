import React, { useState } from 'react';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useModal } from '@ebay/nice-modal-react';
import { FormAlert } from 'components/ui/FormAlert';
import { InputPassword } from 'components/ui/Input';
import { Button } from 'components/ui/Button';
import { PasswordChecklist } from 'components/ui/PasswordChecklist';
import { ResetSuccessModal } from 'components/modals/Password';
import authService from 'services/auth.service';

const initialValues = {
	password: '',
	passwordConfirmation: ''
};
const validationSchema = Yup.object({
	password: Yup.string()
		.required()
		.min(8)
		.test('passwordRequirements', 'Information', (value) =>
			[/\d/, /^.*[!@#$%^&*()_+\-=\]{};':"\\|,.<>?].*$/].every((pattern) =>
				pattern.test(value || '')
			)
		),
	passwordConfirmation: Yup.string().oneOf(
		[Yup.ref('password'), null],
		'Passwords must match'
	)
}).required();

export function ResetForm() {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, isSubmitting }
	} = useForm<typeof initialValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: initialValues,
		mode: 'onTouched'
	});

	const [error, setError] = useState<string>();
	const navigate = useNavigate();
	const { token } = useParams<{ token: string }>();
	const watchPassword = watch('password');

	const modal = useModal(ResetSuccessModal);

	const onSubmit = async (values: typeof initialValues) => {
		try {
			await authService.resetPassword({ token, password: values.password });

			navigate('/');
			modal.show();
		} catch (e: any) {
			setError(e.message);
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<InputPassword
					fullWidth={true}
					{...register('password')}
					label="Password"
					error={!!errors.password}
					className="mb-12"
				/>
				<PasswordChecklist value={watchPassword} />
				<InputPassword
					fullWidth={true}
					{...register('passwordConfirmation')}
					label="Repeat password"
					error={!!errors.passwordConfirmation}
					helperText={errors.passwordConfirmation?.message}
					className="mt-16"
				/>

				<Button
					className="mt-32"
					fullWidth={true}
					type="submit"
					color="primary"
					disabled={isSubmitting}
				>
					Update password
				</Button>
			</form>

			{error && <FormAlert type="error">{error}</FormAlert>}
		</>
	);
}
