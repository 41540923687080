import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { ShareModal } from 'components/modals/Share';
import { useNavigate } from 'react-router-dom';

import { Typography } from 'components/ui/Typography';
import { Button } from 'components/ui/Button';
import { Progress } from './Progress';
import Image from 'assets/images/las_vegas.png';

import style from './dashboard.module.scss';
import classNames from 'classnames';

export const Draw = () => {
	const navigate = useNavigate();
	const shareModal = useModal(ShareModal);

	return (
		<div className={classNames(style.draw, 'p-24 ml-40')}>
			<div className={style.image}>
				<img src={Image} alt="Trip to Las Vegas" />
			</div>
			<div className={style.progress}>
				<div className={style.title}>
					<Typography variant="p3" className="m-0" color="states-info">
						Next Sports Experience Package Draw
					</Typography>
					<Typography className={classNames('mt-12 mb-24 m-0')} component="h6">
						<Typography variant="h6" weight="bold" component="span">
							Las Vegas Raiders
						</Typography>{' '}
						<Typography
							variant="h6"
							weight="bold"
							component="span"
							color="league"
						>
							Trip For 2!
						</Typography>
					</Typography>
				</div>
				<Progress points={950} />
			</div>
			<div className={classNames(style.summary, 'mt-14')}>
				<Typography className={classNames(style.item, 'm-0')} variant="body1">
					My Raffle Entries: 3
				</Typography>
				<Typography className={classNames(style.item, 'm-0')} variant="body1">
					Chance to Win: 0.3%
				</Typography>
			</div>
			<div className={classNames(style.buttons, 'mt-24')}>
				<Button
					onClick={() => navigate('/league/contests')}
					size="lg"
					fullWidth={true}
				>
					Get more entries
				</Button>
				<Button
					onClick={() => shareModal.show()}
					size="lg"
					variant="outlined"
					fullWidth={true}
				>
					invite friends
				</Button>
			</div>
		</div>
	);
};
