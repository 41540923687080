import React from 'react';
import classNames from 'classnames';
import { variantsMapping, TypographyProps } from './Typography.model';
import style from './typography.module.scss';
import useWindowDimensions from 'hooks/useWindowDimensions';

export const Typography = React.forwardRef<any, React.PropsWithChildren<TypographyProps>>(
	(
		{
			cursorPointer,
			component,
			variant,
			variantXl,
			variant2Xl,
			variantMd,
			color = 'primary',
			weight = 'regular',
			className,
			centered,
			textTransform,
			textStyle,
			textAlign,
			textVAlign,
			children,
			...props
		},
		ref
	) => {
		const { isLaptop, isDesktopSmall, isTable } = useWindowDimensions();
		const Component: any = component || (variant ? variantsMapping[variant] : 'p');

		return (
			<Component
				ref={ref}
				className={classNames(
					style[
						`typography--variant-${
							isDesktopSmall && !isLaptop && variant2Xl
								? variant2Xl
								: isLaptop && !isTable && variantXl
								? variantXl
								: isTable && variantMd
								? variantMd
								: variant
						}`
					],
					style[`typography--color-${color}`],
					style[`typography--weight-${weight}`],
					centered ? 'typography--centered' : false,
					textTransform ? style[`typography--text-${textTransform}`] : false,
					textStyle ? style[`typography--text-${textStyle}`] : false,
					textAlign ? style[`typography--align-${textAlign}`] : false,
					textVAlign ? style[`typography--valign-${textVAlign}`] : false,
					cursorPointer ? style[`typography--pointer`] : false,
					className
				)}
				{...props}
			>
				{children}
			</Component>
		);
	}
);
