import { Typography } from 'components/ui/Typography';
import { useGetMembershipQuery } from "../../../services/nft.service";
import { Button } from "../../../components/ui/Button";
import style from './dashboard.module.scss';
import classNames from "classnames";
import { useLocation } from "react-router-dom";

interface MembershipReminderProps {
	inEnrollPopup?: boolean;
	newTab?: boolean;
}

export const MembershipReminder = ({inEnrollPopup = false, newTab = false}: MembershipReminderProps) => {
	const location = useLocation();
	const { isSuccess: isSuccessMembership, data: membership } = useGetMembershipQuery();

	const hoursTillExapiration = Math.ceil( (membership?.expiredAt - new Date().getTime()) / (1000 * 60 * 60));

	if (!isSuccessMembership) return <></>;
	if(isSuccessMembership && !membership?.isExpiring) return <></>;
	if(isSuccessMembership && membership.isExpiring){

		let remainingTime;
		if(membership.weeksRemaining>0){
			remainingTime = `${membership.weeksRemaining} ${ membership.weeksRemaining === 1 ? 'week' : 'weeks'}`;
		}else{
			const daysRemaining = Math.ceil( (membership.expiredAt - new Date().getTime()) / (1000 * 60 * 60 * 24));
			remainingTime = `${daysRemaining} ${ daysRemaining === 1 ? 'day' : 'days'}`;
		}

		if(hoursTillExapiration < 72){
			return (
				<div className={classNames(style.notification, style.notification_isExpiring, inEnrollPopup && style.notification_enrollment)}>
					<Typography variant={'p1'} color={!inEnrollPopup ? 'states-info' : 'primary'} className={style.notification_text}>
						<b>FINAL NOTICE:</b>
						<br/>
						Membership Expiring: If you don't renew, you will lose all privileges relating to your League membership. Renewal prices are subject to change after expiration.
					</Typography>
					<Button href={'/league-renewal/renew'} target={newTab ? '_blank' : ''} state={{
						redirectTo: location.pathname
					}} variant={'info'} size={'lg'} >
						<Typography variant={'body1'} className={style.notification_button_text}>
							Renew For Year Two
						</Typography>
					</Button>
				</div>
			);
		}

		return (
			<div className={classNames(style.notification, style.notification_isExpiring, inEnrollPopup && style.notification_enrollment)}>
				<Typography variant={'p1'}  color={!inEnrollPopup ? 'states-info' : 'primary'} className={style.notification_text}>
					Your League membership is about the expire! You only have {remainingTime} left. Want to renew? It's cheapest to renew before it expires.
				</Typography>
				<Button href={'/league-renewal/renew'} target={newTab ? '_blank' : ''} variant={'info'} size={'lg'} >
					<Typography variant={'body1'} className={style.notification_button_text}>
						Renew For Year Two
					</Typography>
				</Button>
			</div>
		);
	}
	return <></>;
}
