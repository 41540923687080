import React, { useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { useMoneris } from 'hooks/useMoneris';
import { Button } from 'components/ui/Button';
import { Typography } from 'components/ui/Typography';
import { Tabs, TabsContent, TabsItem, TabsList } from 'components/ui/Tabs';
import { SelectAmount } from './SelectAmount';
import { PayPal } from './PayPal';
import hbService from 'services/hubspot.service';

import style from './payment.module.scss';
import useWindowDimensions from 'hooks/useWindowDimensions';

enum PayGateway {
	CREDIT_CARD = 'Credit Card',
	PAYPAL = 'PayPal'
}

const depositItems: number[] = [20, 50, 100, 250];
const initialValues = {
	sum: depositItems[0]
};
const validationSchema = Yup.object({
	sum: Yup.number().required().min(0).max(10000000)
});

interface PaymentFormProps {
	price?: number;
	onSuccess: (amount: number) => any;
	onError: () => any;
}

export function PaymentForm({ price, onSuccess, onError }: PaymentFormProps) {
	const [currentTab, setCurrentTab] = useState<string>(PayGateway.CREDIT_CARD);
	const { isMobile } = useWindowDimensions();

	const methods = useForm<typeof initialValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: initialValues,
		mode: 'onTouched'
	});
	const {
		watch,
		handleSubmit,
		setValue,
		formState: { isSubmitting }
	} = methods;
	const sumValue = watch('sum');

	useEffect(() => {
		if (price) setValue('sum', price);
	}, [price]);

	hbService.hbCheckoutClick(sumValue);

	const handleSuccess = useCallback(() => {
		onSuccess(+sumValue);
	}, [sumValue]);
	const { createOrder } = useMoneris('checkout', handleSuccess, onError);

	const onSubmit = async (values: typeof initialValues) => {
		await createOrder(values.sum);
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={style.payment}>
					{!price && <SelectAmount items={depositItems} />}

					<Typography variant="p1">Pay with Credit Card:</Typography>
					<Tabs
						defaultValue={PayGateway.CREDIT_CARD}
						variant="outlined"
						onValueChange={(value) => {
							setCurrentTab(value);
						}}
					>
					<TabsList>
						<TabsItem value={PayGateway.CREDIT_CARD}>
							<Typography
								variant="p2"
								color={
									PayGateway.CREDIT_CARD === currentTab
										? 'primary'
										: 'states-info'
								}
							>
								Credit Card
							</Typography>
						</TabsItem>
					<TabsItem
						value={PayGateway.PAYPAL}
						color={
							PayGateway.PAYPAL === currentTab
								? 'primary'
								: 'states-info'
						}
						disabled={false}
					>
						<Typography variant="p2">PayPal</Typography>
					</TabsItem>
					</TabsList>

					<TabsContent value={PayGateway.CREDIT_CARD}>
					<div id="checkout" />
					<Button
						className={classNames('mt-32')}
						type="submit"
						color={isMobile ? 'default' : 'primary'}
						disabled={isSubmitting}
						fullWidth
					>
						To Checkout
					</Button>
					</TabsContent>
					<TabsContent value={PayGateway.PAYPAL}>
						<PayPal
							amount={+sumValue}
							onApprove={handleSuccess}
							onError={onError}
							className="mt-32"
						/>
					</TabsContent>
					</Tabs>
				</div>
			</form>
		</FormProvider>
	);
}
