import React from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import formatDate from 'date-fns/format';
import { ContestsStatsItem } from 'common/interfaces/nft.interface';
import style from '../league.module.scss';
import cn from 'classnames';

interface DashboardChartProps {
	data?: ContestsStatsItem[];
	className?: string;
}

export function DashboardChart({ data, className }: DashboardChartProps) {
	return (
		<div className={cn(style.chart, className && className)}>
			<ResponsiveContainer width="100%" height="100%">
				<AreaChart
					width={500}
					height={400}
					data={data}
					margin={{
						top: 50,
						right: 10,
						left: 0,
						bottom: 0
					}}
				>
					<text x={45} y={25} fill="#7084A2" textAnchor="middle">
						<tspan fontSize="13" fontWeight="bold">
							Weekly Points
						</tspan>
					</text>
					<defs>
						<linearGradient id="areaColor" x1="0" y1="0" x2="0" y2="1">
							<stop offset="5%" stopColor="#31CF8E" stopOpacity={0.8} />
							<stop offset="95%" stopColor="#31CF8E" stopOpacity={0} />
						</linearGradient>
					</defs>

					<XAxis
						axisLine={{ stroke: '#fff' }}
						tickLine={{ stroke: '#fff' }}
						stroke="#7084A2"
						dataKey="date"
						tickFormatter={(value, index) => `W${data!.length + index}`}
					/>

					<YAxis
						axisLine={{ stroke: '#fff' }}
						tickLine={{ stroke: '#fff' }}
						stroke="#7084A2"
						width={30}
					/>
					<Tooltip
						cursor={false}
						labelFormatter={(label) =>
							formatDate(new Date(label), 'dd MMM yyyy')
						}
						formatter={(value: any) => [`${value.toFixed(2)} Points`]}
					/>
					<Area
						type="linear"
						dataKey="totalPoints"
						stroke="#31CF8E"
						fill="url(#areaColor)"
						strokeWidth={2}
						dot={{ r: 8, strokeWidth: 2, fill: '#000', fillOpacity: 1 }}
						activeDot={{ strokeWidth: 0, stroke: '#000', fill: '#DAE7FF' }}
					/>
				</AreaChart>
			</ResponsiveContainer>
		</div>
	);
}
