import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import formatDate from 'date-fns/format';
import { getDateString } from 'utils/date';
import { getFlooredFixed } from 'utils/to-fixed';
import { useCountdown } from 'hooks/useCountdown';
import { ArrowRightIcon, CalendarIcon } from 'components/ui/Icon';
import { Typography } from 'components/ui/Typography';
import { Progress } from 'components/ui/Progress';
import { Button } from 'components/ui/Button';
import { SellIcon } from 'components/ui/Icon';

import style from './contest.module.scss';

interface ContestItemProps {
	id: string;
	title: string;
	prize: number;
	totalEntries: number;
	entrySlots: number;
	finishDate: number;
	entryFee: number;
	enrolled?: boolean;
	isNFT?: boolean;
	isMember?: boolean;
	isLive?: boolean;
	isSlide?: boolean;
	isClosed?: boolean;
	isContestEnded?: boolean;
	icon?: React.ReactNode;
	type?: string ;
}

export const ContestItem = ({
	id,
	title,
	prize,
	totalEntries,
	entrySlots,
	entryFee,
	finishDate: date,
	enrolled = false,
	isNFT = false,
	isMember = false,
	isLive = false,
	isSlide = false,
	isClosed = false,
	isContestEnded,
	icon,
	type
}: ContestItemProps) => {
	const [totalEnrolled, setTotalEnrolled] = useState(totalEntries);
	const isFull = totalEntries >= entrySlots ? true : false;
	const isFree = type === 'free' ? true : false ;
	const { counter: timeLeft, startTimer } = useCountdown(
		date - new Date().getTime(),
		1000
	);
	const formattedDate = useMemo(
		() => formatDate(date, "dd MMM,yyyy hh:mm aaaaa'm'"),
		[date]
	);

	useEffect(() => {
		startTimer();
		if (isNFT) {
			setTotalEnrolled(entrySlots);
		}
	}, []);

	return (
		<div
			className={classNames(
				style.row,
				enrolled && style.enrolled,
				isNFT && style.nft,
				isFree && style.free
			)}
		>
			<div className={classNames(style.body, isSlide && style.contest_slide)}>
				<div className={classNames(style.icon, style.column)}>{icon}</div>
				<div className={classNames(style.title, style.column)}>
					<Typography
						variant="p2"
						variant2Xl="p3"
						variantXl="p3"
						className="m-0"
						textVAlign="center"
						weight="semi-bold"
					>
						{title}
					</Typography>
				</div>
				<div className={classNames(style.column, style.prize, 'plr-16')}>
					<Typography
						variant="p2"
						variant2Xl="p3"
						variantXl="p3"
						color="desktop"
						weight="semi-bold"
						textVAlign="center"
						className="m-0"
					>
						${getFlooredFixed(prize)}
					</Typography>
				</div>
				<div className={classNames(style.column, style.progress, 'plr-8')}>
					{/*<div className={style.progress}>*/}
					<div className={style.progressHeading}>
						<Typography
							variant="body2"
							color="neutral_07"
							className="m-0 mb-4"
						>
							{!isNFT && `${totalEnrolled}/${entrySlots} Entries`}
							{isNFT && `Unlimited Entries`}
						</Typography>
					</div>
					<div className={style.progressBody}>
						<Progress
							value={totalEnrolled}
							max={entrySlots}
							color="neutral_02"
						/>
					</div>
					{/*</div>*/}
				</div>
				<div className={classNames(style.date, style.column)}>
					<Typography color="neutral_07" variant="body1" textVAlign="center">
						<CalendarIcon size={20} stroke="#fff" color="none" />
						<span className="pl-8">{formattedDate}</span>
					</Typography>
				</div>
				<div className={classNames(style.timeout, style.column)}>
					<div className={classNames(style['timeout-item'], 'plr-15', 'ptb-6')}>
						<Typography
							color="neutral_black"
							className="p-0 m-0"
							weight="semi-bold"
							variant="p3"
						>
							{timeLeft > 0
								? getDateString(timeLeft)
								: isContestEnded
								? 'Ended'
								: 'Live'}
						</Typography>
					</div>
				</div>
				<div className={classNames(style.button, style.column)}>
					<Button
						fullWidth={true}
						href={`/contest/${id}`}
						variant={enrolled || !isLive ? 'outlined' : 'default'}
						color={isNFT ? 'default' : 'primary'}
						disabled={isNFT && !isMember && !isClosed}
						style={{ fontSize: '16px', padding: '7px 20px' }}
						endIcon={
							<ArrowRightIcon
								size={18}
								className={classNames('ml-16')}
								fillClass={
									enrolled || !isLive ? 'default' : 'default-text'
								}
							/>
						}
					>
						{enrolled && !isClosed && !isFull
							? 'Edit picks'
							: enrolled && !isClosed && isFull
							? 'Edit picks'
							: enrolled && isClosed && isFull
							? 'View Contest'
							: enrolled && isClosed && !isFull
							? 'View Contest'
							: !enrolled && !isClosed && isFull
							? 'FUll'
							: !enrolled && !isClosed && !isFull
							? isNFT
								? 'Enroll'
								: `$${entryFee} Entry`
							: ''}
					</Button>
					{process.env.REACT_APP_SHOW_BETSWAP != 'true' && (
						<Button
							href={`/contest`}
							className="ml-8"
							variant="neutral"
							size="sm"
							style={{
								width: '50px',
								height: '100%',
								padding: 0,
								flexShrink: 0
							}}
						>
							<SellIcon size={20} stroke="#fff" color="#fff" />
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};
