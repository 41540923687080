import React from 'react';
import classNames from 'classnames';
import { DownArrowIcon } from 'components/ui/Icon';
import style from './sort.module.scss';

interface SortItemProps {
	title: string;
	control: string;
	active: boolean;
	onClick: (control: string) => void;
}

export const SortItem = ({ title, control, active, onClick }: SortItemProps) => {
	return (
		<div
			className={classNames(style.item, active && style.active, style[control])}
			onClick={() => onClick(control)}
		>
			{title}
			<DownArrowIcon size={15} />
		</div>
	);
};
