import React from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useModal } from '@ebay/nice-modal-react';
import { Input } from 'components/ui/Input';
import { Button } from 'components/ui/Button';
import { ForgotSuccessModal } from 'components/modals/Password';
import authService from 'services/auth.service';

const initialValues = {
	email: ''
};
const validationSchema = Yup.object({
	email: Yup.string().email().required()
});

export function ForgotForm() {
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting }
	} = useForm<typeof initialValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: initialValues,
		mode: 'onTouched'
	});

	const navigate = useNavigate();
	const modal = useModal(ForgotSuccessModal);

	const onSubmit = async (values: typeof initialValues) => {
		await authService.forgotPassword(values.email);

		navigate('/');
		modal.show();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Input
				{...register('email')}
				label="Email"
				error={!!errors.email}
				helperText={errors.email?.message}
				placeholder=""
				className="mb-24"
				fullWidth={true}
			/>

			<Button
				className="mb-32"
				fullWidth={true}
				type="submit"
				color="primary"
				disabled={isSubmitting}
			>
				Reset my password
			</Button>
		</form>
	);
}
