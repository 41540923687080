import React from 'react';
import { TabsContent as Content, TabsContentProps } from '@radix-ui/react-tabs';
import style from './tabs.module.scss';

type Props = {} & TabsContentProps;

export function TabsContent({ children, ...props }: Props) {
	return (
		<Content {...props} className={style.content}>
			{children}
		</Content>
	);
}
