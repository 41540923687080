import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { SortConfig } from 'hooks/useSortable';
import { Contest } from 'common/interfaces/contest.interface';
import { RootState } from 'store/index';
import { paymentApi } from 'services/payment.service';

export type ContestSortConfig = SortConfig<keyof Contest>;

interface Price {
	membership: {
		basic_price?: number;
		initial_price?: number;
		current_price?: number;
	};
}

const initialState: Price = {
	membership: {
		basic_price: 0,
		initial_price: 0,
		current_price: 0
	}
};

export const paymentSlice = createSlice({
	name: 'payment',
	initialState,
	reducers: {
		setFilters: (state, action: PayloadAction<Partial<Price['membership']>>) => {
			state.membership = { ...state, ...action.payload };
		}
	}
});
