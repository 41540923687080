import style from './style.module.scss';
import { Typography } from 'components/ui/Typography';

import imageUrl from 'assets/images/luke-rockhold-banner.png';

import classNames from 'classnames';
export const LukeBanner = () => {
	return (
		<div className={style.luke_banner}>
			<div className={style.luke_banner_image}>
				<img src={imageUrl} alt="luke rockhold" />
			</div>
			<div className={style.content}>
				<Typography>OFFICIAL PARTNER OF</Typography>
				<Typography className={classNames('m-0')} variant="h6" weight="bold">
					Luke Rockhold
				</Typography>
			</div>
		</div>
	);
};
