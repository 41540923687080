import React from 'react';
import classNames from 'classnames';
import style from './input.module.scss';

interface InputGroupProps {
	className?: string;
	children: React.ReactNode;
}

export function InputGroup({ className, children }: InputGroupProps) {
	return <div className={classNames(style.inputGroup, className)}>{children}</div>;
}
