import React from 'react';
import { Icon } from './Icon';
import { ExternalIconProps } from './Icon.model';
import { ReactComponent as SvgIcon } from 'assets/images/icons/eye.svg';
import { ReactComponent as SvgIconOff } from 'assets/images/icons/eye-off.svg';

export interface EyeIconProps extends ExternalIconProps {
	active?: boolean;
}

export function EyeIcon({ active, ...props }: EyeIconProps) {
	return <Icon {...props} component={active ? SvgIconOff : SvgIcon} />;
}
