import Table from 'assets/images/reviews/feature3-table.svg';

import { ParallaxModule, BaseImage } from 'components/ui/ParallaxModule';
import useWindowDimensions from 'hooks/useWindowDimensions';
export const TableFeature = () => {
	const { isTable } = useWindowDimensions();
	const baseImage = (
		<BaseImage
			height={isTable ? 'initial' : ''}
			src={Table}
			alt="IPhone image"
			justifyContent="flex-end"
			dependentImage={<></>}
		/>
	);
	return (
		<div
			style={{
				position: 'relative'
			}}
		>
			<ParallaxModule baseImage={baseImage} />
		</div>
	);
};
