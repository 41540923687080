import React, { useMemo, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useAppSelector } from 'hooks/redux';
import { selectUserData } from 'store/user/user.slice';
import { Modal } from 'components/ui/Modal';
import { Typography } from 'components/ui/Typography';
import { Clipboard } from 'components/ui/Clipboard';
import { CopyIcon } from 'components/ui/Icon';
import { Input } from 'components/ui/Input';
import { CheckIcon } from 'components/ui/Icon';

import inviteImage from 'assets/images/invite.png';

type copiedTextProps = 'link' | 'code';

export const ShareModal = NiceModal.create(() => {
	const [copiedText, setCopiedText] = useState<string>();
	const modal = useModal();
	const user = useAppSelector(selectUserData);

	const invitationLink = useMemo(
		() => `${window.location.origin}/sign-up/${user?.userCode}`,
		[window.location.origin, user]
	);
	const stateSuccessCopyText = (text: copiedTextProps) => {
		switch (text) {
			case 'link':
				setCopiedText('Link');
				break;
			case 'code':
				setCopiedText('Code');
				break;
		}
	};

	return (
		<Modal
			className="p-40 width-popupDesktop"
			isOpen={modal.visible}
			onClose={modal.hide}
		>
			<div className="align-center mb-16">
				<img src={inviteImage} alt="Invite friends" />
			</div>

			<Typography
				variant="h5"
				weight="semi-bold"
				className="mb-32"
				textAlign="center"
			>
				Invite your friends to Bounty Sports
			</Typography>
			<Typography variant="p2" textAlign="center">
				You and your friends will each receive $5 when they sign-up and play!
			</Typography>

			<div className="align-center column">
				<Clipboard
					hideContent={true}
					onCopy={() => stateSuccessCopyText('code')}
					value={user?.userCode}
				>
					<Typography variant="p2" textVAlign="center" textAlign="center">
						Copy Code:
						<Typography
							variant="p2"
							component="span"
							color="desktop"
							textVAlign="center"
							className="ml-12 flex items-center"
						>
							{user?.userCode}{' '}
							<CopyIcon className="ml-10 pointer" color="transparent" />
						</Typography>
					</Typography>
				</Clipboard>

				<Typography variant="p2" textAlign="center">
					or
				</Typography>

				<Clipboard
					hideContent={true}
					onCopy={() => stateSuccessCopyText('link')}
					value={invitationLink}
				>
					<Input
						label={'Invitation link'.toLocaleUpperCase()}
						defaultValue={invitationLink}
						endIcon={<CopyIcon color="transparent" className="pointer" />}
						fullWidth
						readOnly
						className="align-left"
					/>
				</Clipboard>
				{copiedText && (
					<Typography
						className="m-0 mt-32 flex items-center justify-center"
						color="states-success"
					>
						{copiedText} Has Been Copied{' '}
						<CheckIcon size={16} className="ml-9" color="transparent" />
					</Typography>
				)}
			</div>

			{/*<Delimiter backgroundColor="#7084A1" />*/}

			{/*<div>*/}
			{/*	<Typography variant="p2">Share via:</Typography>*/}
			{/*</div>*/}
		</Modal>
	);
});
