import { useWallet } from 'hooks/useWallet';
import { Typography } from 'components/ui/Typography';
import { Link } from 'components/ui/Link';
import { MetamaskIcon, WalletConnectIcon } from 'components/ui/Icon';
import { WalletButton } from './WalletButton';
import style from '../league.module.scss';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

export const LeagueWallet = () => {
	const { account, connectMetamask, connectWalletConnect } = useWallet();
	const navigate = useNavigate();
	const [triggered, setTriggered] = useState<boolean>(false);

	useEffect(() => {
		if (triggered && account) {
			navigate('/league');
		}
	}, [triggered, account]);
	return (
		<Typography component="div" className={style.lwallet}>
			<Typography
				className="m-0"
				component="h2"
				variant="h1"
				variantXl="h3"
				variantMd="h4"
				textStyle="italic"
				textTransform="uppercase"
			>
				connect
			</Typography>
			<Typography
				className="m-0 mtb-8 md:mtb-0"
				component="h2"
				variant="hero"
				variantXl="h1"
				variantMd="h2"
				weight="bold"
				textStyle="italic"
				color="primary-mobile"
				textTransform="uppercase"
			>
				your
			</Typography>
			<Typography
				className="m-0"
				component="h2"
				variant="hero"
				variantXl="h1"
				variantMd="h2"
				weight="bold"
				textStyle="italic"
				color="primary-mobile"
				textTransform="uppercase"
			>
				wallet
			</Typography>
			<Typography className="m-0 mt-16 xl:mt-12 md:mt-8" component="p" variant="p1">
				Connect your wallet to sign in or create an account
			</Typography>
			<Typography component="div" className={style.btn_inner}>
				<WalletButton
					title="Metamask"
					description="Connect to your Metamask wallet"
					icon={<MetamaskIcon />}
					onClick={async () => {
						await connectMetamask();
						setTriggered(true);
					}}
				/>
				<WalletButton
					title="WalletConnect"
					description="Scan with your mobile device to connect"
					icon={<WalletConnectIcon />}
					onClick={connectWalletConnect}
				/>
			</Typography>
			<Typography variant="p2" className="mt-40 xl:mt-36 md:mt-28">
				<Typography className="pr-8" component="span">
					New to NFTs?
				</Typography>
				<Link href="/how-to-play" underline="always" color="primary-mobile">
					Learn about wallets
				</Link>
			</Typography>
		</Typography>
	);
};
