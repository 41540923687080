import React from 'react';
import classNames from 'classnames';
import { TabsList as List, TabsListProps } from '@radix-ui/react-tabs';
import style from './tabs.module.scss';

type Props = {
	children: React.ReactNode[];
} & TabsListProps;

export function TabsList({ children, ...props }: Props) {
	return (
		<List {...props} className={classNames(style.list, props.className)}>
			{children}
		</List>
	);
}
