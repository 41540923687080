import React from 'react';
import { MainLeaderboard } from './MainLeaderboard';
import { FullLeaderboard } from './FullLeaderboard';
import { LeagueLayout } from 'pages/League/Layout/Layout';
import style from './leaderboard.module.scss';

export default function LeagueLeaderboard() {
	return (
		<LeagueLayout mainClassName={style.leaderboard}>
			<MainLeaderboard />
			<FullLeaderboard />
		</LeagueLayout>
	);
}
