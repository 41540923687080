import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { InputProps } from './Input.model';
import { Input } from './Input';
import style from './input.module.scss';

interface InputAreaProps extends InputProps {
	rows: number;
	maxLength: number;
}

export const InputTextArea = React.forwardRef<
	HTMLInputElement,
	React.PropsWithChildren<InputAreaProps>
>(({ label, className, maxLength, onChange, ...props }, ref) => {
	const [count, setCount] = useState<number>(0);
	const handleChange = useCallback(
		(e: React.FormEvent<HTMLTextAreaElement>) => {
			setCount(e.currentTarget.value.length);
			if (onChange) onChange(e);
		},
		[onChange]
	);

	return (
		<Input
			{...props}
			ref={ref}
			className={classNames(className, style.textarea)}
			label={
				<>
					{label}
					<span>
						{count}/{maxLength}
					</span>
				</>
			}
			maxLength={maxLength}
			onChange={handleChange}
		/>
	);
});
