import React, { useState } from 'react';
import { LeaderboardUser } from 'common/interfaces/nft.interface';
import { useGetLeaderboardUsersQuery } from 'services/nft.service';
import { storageService } from 'services/storage.service';
import { useSortable } from 'hooks/useSortable';
import { Avatar } from 'components/ui/Avatar';
import { Typography } from 'components/ui/Typography';
import { Sort } from 'components/ui/Sort';
import { Pagination } from 'components/ui/Pagination';
import { PeriodSelect, periods } from './PeriodSelect';
import cn from 'classnames';

import style from './leaderboard.module.scss';

const sortControls: Partial<Record<keyof LeaderboardUser, string>> = {
	leagueRank: 'Rank',
	name: 'Player',
	totalPrize: 'Total Winnings',
	totalPoints: 'Total Points Earned',
	avgPlace: 'Average Place',
	moneyShare: '% in the Money'
};

const LeaderboardItem = ({
	name,
	avatar,
	totalPrize,
	totalPoints,
	leagueRank,
	avgPlace,
	moneyShare,
	borderBottom,
	topUser
}: LeaderboardUser) => {
	return (
		<div
			className={cn(style.rank, {
				[style.rank_borderb]: borderBottom,
				[style.rank_top]: topUser
			})}
		>
			<Typography
				className={style.rank_number}
				weight="bold"
				component="span"
				variant="p2"
			>
				{leagueRank}
			</Typography>

			<div className={style.rank_player}>
				<Avatar alt={name} src={avatar} />
				<Typography weight="bold" component="span" variant="p2">
					{name}
				</Typography>
			</div>

			<Typography weight="semi-bold" component="span" className="pl-20">
				${totalPrize.toFixed(2)}
			</Typography>
			<Typography weight="semi-bold" component="span" className="pl-20">
				{totalPoints.toFixed(2)}
			</Typography>

			<Typography weight="semi-bold" component="span" className="pl-20">
				{avgPlace.toFixed(2)}
			</Typography>
			<Typography weight="semi-bold" component="span" className="pl-20">
				{Math.floor(moneyShare)}%
			</Typography>
		</div>
	);
};

export function FullLeaderboard() {
	const itemsPerPage = 4;

	const [currentPage, setCurrentPage] = useState(1);
	const [period, setPeriod] = useState(periods[0]);

	const { data } = useGetLeaderboardUsersQuery({
		page: currentPage,
		limit: itemsPerPage,
		period: period.value
	});
	const { items: sortedItems, requestSort } = useSortable<LeaderboardUser>(
		data?.items || []
	);

	const { data: user } = useGetLeaderboardUsersQuery({
		userId: storageService.getUserId() as string,
		page: 1
	});
	console.log('Leaderboard: ', data);

	return (
		<div className="mt-80 pb-44">
			<Typography
				component="h4"
				variant="h4"
				weight="bold"
				color="neutral_07"
				className="m-0"
			>
				Full Leaderboard
			</Typography>
			<PeriodSelect value={period} onSelect={setPeriod} />

			<Sort
				className={cn('mt-32 mb-12', style.lsort)}
				controls={sortControls}
				onSort={requestSort}
			/>

			{user?.items.length ? (
				<LeaderboardItem topUser={true} {...user.items[0]} />
			) : null}
			<div className={style.ranks}>
				{sortedItems.map((item) => (
					<LeaderboardItem borderBottom={true} key={item.name} {...item} />
				))}
			</div>
			<div className="mt-32">
				<Pagination
					pageSize={itemsPerPage}
					current={currentPage}
					total={data?.totalCount || 0}
					onChange={setCurrentPage}
				/>
			</div>
		</div>
	);
}
