import { Typography } from '../../ui/Typography';
import style from './download.app.module.scss';
import { Link } from '../../ui/Link';
import DownloadAppStore from '../../../assets/images/download-app-store.png';
import DownloadApk from '../../../assets/images/androidapk.png';
import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { useNavigate } from 'react-router-dom';

interface ContentProps {
	modal?: any;
}

export const Content = ({ modal }: ContentProps) => {
	const navigate = useNavigate();

	function handelClick() {
		if (modal) {
			modal.hide();
		}
		navigate('/download');
	}

	return (
		<>
			<Typography className={'m-0'} textAlign="center" variant="p1">
				Download The Bounty Sports App By Clicking the Buttons Below
			</Typography>
			<div className={style.download_app}>
				<div className={style.appstore}>
					<Link href="https://apps.apple.com/app/id1557241541">
						<img
							style={{
								maxWidth: '200px'
							}}
							src={DownloadAppStore}
							alt="get the app"
							className="mt-12"
						/>
					</Link>
				</div>
				<div className={style.playstore}>
					<div onClick={handelClick} className={'mt-0'}>
						<img
							style={{
								maxWidth: '200px'
							}}
							src={DownloadApk}
							alt=""
							className="mt-0"
						/>
					</div>
				</div>
			</div>
		</>
	);
};
