import React, { useState } from 'react';

import { Tabs, TabsContent, TabsItem, TabsList } from 'components/ui/Tabs';
import EnrolledContests from 'pages/Contests/Enrolled';
import CompletedContests from 'pages/Contests/Completed';
import AvailableContests from './Available';

import { LeagueLayout } from 'pages/League/Layout/Layout';

enum ContestGroup {
	AVAILABLE = 'available',
	ENROLLED = 'enrolled',
	COMPLETED = 'completed'
}

const contestsGroups = {
	[ContestGroup.AVAILABLE]: 'Available Contests',
	[ContestGroup.ENROLLED]: 'Enrolled Contests',
	[ContestGroup.COMPLETED]: 'Completed Contests'
};

export default function LeagueContests() {
	const [group, setGroup] = useState<string>(ContestGroup.AVAILABLE);

	return (
		<LeagueLayout>
			<Tabs value={group} onValueChange={setGroup} variant="minimal">
				<TabsList className="mb-40">
					{Object.entries(contestsGroups).map(([name, title]) => (
						<TabsItem key={name} value={name}>
							{title}
						</TabsItem>
					))}
				</TabsList>

				<TabsContent value={ContestGroup.AVAILABLE}>
					<AvailableContests />
				</TabsContent>
				<TabsContent value={ContestGroup.ENROLLED}>
					<EnrolledContests
						isNft
						toAvailable={() => setGroup(ContestGroup.AVAILABLE)}
					/>
				</TabsContent>
				<TabsContent value={ContestGroup.COMPLETED}>
					<CompletedContests isNft />
				</TabsContent>
			</Tabs>
		</LeagueLayout>
	);
}
