import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { Contest } from 'common/interfaces/contest.interface';
import { Container } from 'components/layout/Grid/Container';
import { Typography } from 'components/ui/Typography';
import { ContestItem } from 'components/ui/Contest';
import { ArrowRightIcon, SportIcon } from 'components/ui/Icon';
import style from './style.module.scss';

type WaySwiperSlide = 'right';

interface ContestsSliderProps {
	items: Contest[];
}

export const ContestsSlider = ({ items }: ContestsSliderProps) => {
	const swiperRef = useRef as any;
	const handleSwipeSLide = (way?: WaySwiperSlide) => {
		switch (way) {
			case 'right':
				swiperRef.current.swiper.slideNext();
				break;
			default:
				swiperRef.current.swiper.slidePrev();
				break;
		}
	};
	return (
		<div className={style.contestSlider}>
			<Typography variant="h4" weight="bold" className="m-0 mb-32">
				<Container>
					<Typography component="span" color="desktop" weight="bold">
						Featured
					</Typography>{' '}
					Contests
				</Container>
			</Typography>

			<Swiper
				// @ts-ignore
				ref={swiperRef}
				modules={[Navigation]}
				grabCursor={true}
				spaceBetween={0}
				loopedSlides={4}
				loop={items.length > 1}
				centeredSlides
				slideToClickedSlide
				breakpoints={{
					0: {
						slidesPerView: 1.2
					},
					1280: {
						slidesPerView: 1.3
					},
					1520: {
						slidesPerView: 1.2
					}
				}}
			>
				{items.map((item) => (
					<SwiperSlide key={item._id}>
						{({ isActive }) => (
							<div className={isActive ? style.activeSlide : style.slide}>
								<ContestItem
									id={item._id}
									prize={item.prize}
									title={item.title}
									entrySlots={item.entrySlots}
									totalEntries={item.totalEntries}
									finishDate={item.finishAt * 1000}
									entryFee={item.entryFee}
									icon={
										<SportIcon
											color="#7084a2"
											size={40}
											sport={item.sport ? item.sport : ''}
										/>
									}
									isSlide
									enrolled={item.enrolled}
									isNFT={item.isNFT}
								/>
							</div>
						)}
					</SwiperSlide>
				))}
				<div className={style.prevArrowSlide} onClick={() => handleSwipeSLide()}>
					<ArrowRightIcon size={22} fillClass="default" />
				</div>
				<div
					className={style.nextArrowSlide}
					onClick={() => handleSwipeSLide('right')}
				>
					<ArrowRightIcon size={22} fillClass="default" />
				</div>
			</Swiper>
		</div>
	);
};
