import React from 'react';
import { Navigation, NavigationItem } from 'components/layout/Navigation';
import style from './header.module.scss';

export function Navbar() {
	return (
		<Navigation className={style.navigation}>
			<NavigationItem to="/contest">Contests</NavigationItem>
			<NavigationItem className={style.league} to="/league">
				The League
			</NavigationItem>

			{/*<NavigationItem to="/rewards">My Rewards</NavigationItem>*/}
		</Navigation>
	);
}
