import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { TooltipContent } from './TooltipContent';

interface TooltipProps {
	content: React.ReactNode;
	children?: React.ReactNode;
	side?: TooltipPrimitive.TooltipContentProps['side'];
	trigger?: 'click' | 'hover';
	className?: string;
	disableContent?: boolean;
}

export function Tooltip({
	className = '',
	disableContent,
	children,
	content,
	side = 'top',
	trigger = 'hover'
}: TooltipProps) {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const handleClick = (status: boolean) => {
		if (trigger === 'click') {
			setIsOpen(status);
		}
	};

	return (
		<TooltipPrimitive.Provider delayDuration={250}>
			<OutsideClickHandler
				display="inline-block"
				onOutsideClick={() => handleClick(false)}
			>
				<TooltipPrimitive.Root
					open={isOpen}
					onOpenChange={trigger === 'hover' ? setIsOpen : undefined}
				>
					<TooltipPrimitive.Trigger
						className={className}
						asChild
						onClick={() => handleClick(true)}
					>
						{children}
					</TooltipPrimitive.Trigger>

					{!disableContent && (
						<TooltipContent side={side}>{content}</TooltipContent>
					)}
				</TooltipPrimitive.Root>
			</OutsideClickHandler>
		</TooltipPrimitive.Provider>
	);
}
