import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {
	RegisterData,
	PreRegisterData,
	ResetPassword,
	LoginData,
	JwtPayload,
	JwtTokensPair
} from 'common/interfaces/auth.interface';
import { getBrowserFingerprint } from 'utils/fingerprint';
import httpService, { API_URL, ServerResponse } from 'services/http.service';
import { storageService } from './storage.service';

const AUTH_ENDPOINT = '/auth';

interface BasicResponse<T = void> extends ServerResponse<T> {
	success: boolean;
}

const prepareSession = (tokens: JwtTokensPair) => {
	const { accessToken, refreshToken } = tokens;
	storageService.setAuthTokens(accessToken, refreshToken);

	const tokenData = jwtDecode<JwtPayload>(accessToken);
	storageService.saveUserId(tokenData.id);
};

const sendOTP = async (type: string, to: string,bankVerify?:string): Promise<void> => {
	await httpService.post<BasicResponse>(`${AUTH_ENDPOINT}/otp`, {
		type,
		to,
		bankVerify
	});
};
const verifyOTP = async (otp: string): Promise<void> => {
	await httpService.post<BasicResponse>(`${AUTH_ENDPOINT}/otp/verify`, {
		otp
	});
};
const forgotPassword = async (email: string): Promise<void> => {
	await httpService.post<BasicResponse>(`${AUTH_ENDPOINT}/forgotPassword`, {
		email
	});
};
const resetPassword = async (payload: ResetPassword): Promise<void> => {
	await httpService.post<BasicResponse>(`${AUTH_ENDPOINT}/resetPassword`, payload);
};

const loginUser = async (payload: LoginData): Promise<void> => {
	const fingerprint = await getBrowserFingerprint();
	const { data } = await httpService.post<BasicResponse<JwtTokensPair>>(
		`${AUTH_ENDPOINT}/login`,
		{ ...payload, fingerprint }
	);
	prepareSession(data.data);
};

const logoutUser = async () => {
	await httpService.post<BasicResponse>(`${AUTH_ENDPOINT}/logout`, {
		refreshToken: storageService.getRefreshToken()
	});
};

const registerUser = async (
	payload: RegisterData | Partial<RegisterData>,
	quick = false
): Promise<void> => {
	const fingerprint = await getBrowserFingerprint();
	const { data } = await httpService.post<BasicResponse<JwtTokensPair>>(
		`${AUTH_ENDPOINT}/register${quick ? '/quick' : ''}`,
		{ ...payload, fingerprint }
	);

	prepareSession(data.data);
};

const checkPhoneAndPromo = async (payload: PreRegisterData): Promise<void> => {
	await httpService.post(`${AUTH_ENDPOINT}/preRegister`, payload);
};

const refreshAccess = async (): Promise<JwtTokensPair | null> => {
	try {
		const fingerprint = await getBrowserFingerprint();
		const { data } = await axios.post<BasicResponse<JwtTokensPair>>(
			`${API_URL}${AUTH_ENDPOINT}/refresh`,
			{
				refreshToken: storageService.getRefreshToken(),
				fingerprint
			}
		);
		prepareSession(data.data);
		return data.data;
	} catch (e) {}

	return null;
};

const authService = {
	refreshAccess,
	loginUser,
	logoutUser,
	sendOTP,
	verifyOTP,
	forgotPassword,
	resetPassword,
	registerUser,
	checkPhoneAndPromo
};

export default authService;
