import React from 'react';
import { Modal } from 'components/ui/Modal';
import { ResetPasswordForm } from 'components/forms/PasswordForm';

export function ResetPassword() {
	return (
		<Modal
			titleVariant="h5"
			title="Create new password"
			className="p-40 width-popupDesktop"
			titleClassName="pb-24 m-0"
			isOpen
			forRoute
		>
			<ResetPasswordForm />
		</Modal>
	);
}
