import React, { useMemo } from 'react';
import { Root as ProgressRoot, Indicator } from '@radix-ui/react-progress';
import { ProgressProps } from './Progress.model';
import classNames from 'classnames';
import style from './progress.module.scss';

export function Progress({ value, max, color, isDanger, height, className }: ProgressProps) {
	const percentage = useMemo(() => (value / max) * 100, [value, max]);

	return (
		<ProgressRoot
			style={{ height }}
			className={classNames(
				style.progress,
				color ? style[`progress--color-${color}`] : false,
				className
			)}
			value={value}
			max={max}
		>
			<Indicator
				className={classNames( style.indicator, isDanger && style['indicator--color-danger'] )}
				style={{ transform: `translateX(-${100 - percentage}%)` }}
			/>
		</ProgressRoot>
	);
}
