import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useGetProfileQuery } from 'services/user.service';
import { Modal } from 'components/ui/Modal';
import { AlertModal } from 'components/modals/Alert';
import { PaymentForm } from 'components/forms/PaymentForm';

import hbService from 'services/hubspot.service';

export const DepositModal = NiceModal.create(() => {
	const modal = useModal();
	const alertModal = useModal(AlertModal);
	const { refetch } = useGetProfileQuery();

	const handleSuccess = (amount: number) => {
		refetch();

		hbService.hbLeaguePurchase(true);

		modal.remove();
		alertModal.show({
			success: true,
			heading: 'Success',
			content: `${amount}$ was added to your balance`
		});
	};

	const handleError = () => {
		modal.remove();
		hbService.hbLeaguePurchase(false);
		alertModal.show({
			success: false,
			heading: 'Failed Transition',
			content: 'Please, try using different payment method\n or contact our support'
		});
	};

	return (
		<Modal
			titleVariant="h5"
			title="Deposit"
			className="p-40 width-popupDesktop"
			titleClassName="ptb-24 m-0"
			isOpen={modal.visible}
			onClose={modal.remove}
		>
			<PaymentForm onSuccess={handleSuccess} onError={handleError} />
		</Modal>
	);
});
