import React from 'react';
import { NavLink } from 'react-router-dom';
import style from './navigation.module.scss';
import classNames from 'classnames';
export type Props = {
	className?: string;
	href?: string;
	to?: string;
};

export function NavigationItem({
	className,
	children,
	href,
	to
}: React.PropsWithChildren<Props>) {
	// console.log(style);
	return (
		<div className={classNames(style['navigation-item'], className)}>
			{to ? (
				<NavLink to={to}>{children}</NavLink>
			) : (
				<a href={href || '#'}>{children}</a>
			)}
		</div>
	);
}
