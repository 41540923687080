import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useModal } from '@ebay/nice-modal-react';
import { useGetProfileQuery } from 'services/user.service';
import { useGetMembershipPriceQuery } from 'services/payment.service';
import paymentService from 'services/payment.service';
import { AlertModal } from 'components/modals/Alert';
import { PurchaseModal } from '../Purchase';
import { useMobileDevice } from 'hooks/useMobileDevice';

const successRedirect = '/league';
const errorRedirect = '/sale';
const downloadAppRedirect: string = process.env.REACT_APP_APP_STORE_URL || errorRedirect;

export const MembershipModal = () => {
	const location = useLocation();

	const promo = '';//location?.state?.promo ? location.state.promo : '';

	const { data, isSuccess } = useGetMembershipPriceQuery(promo);

	const [price, setPrice] = useState(0);

	useEffect(() => {
		if (isSuccess) {
			setPrice(data.current_price);
		}
	}, [data, isSuccess]);

	const [isAvailable, setIsAvailable] = useState<boolean>();
	const alertModal = useModal(AlertModal);
	const navigate = useNavigate();

	const { refetch } = useGetProfileQuery();
	const { isIOS } = useMobileDevice();

	useEffect(() => {
		paymentService.isAvailableMembership().then(setIsAvailable);
	}, []);

	useEffect(() => {
		if (isAvailable === false) {
			alertModal.show({
				success: false,
				heading: 'Something went wrong...',
				content:
					'There is no available memberships or you have already purchased one.'
			});

			if (!isIOS) {
				navigate(errorRedirect);
			} else {
				navigate('/');
			}
		}
	}, [isAvailable]);

	const handleSuccess = async () => {
		try {
			const waitPopup: any = alertModal.show({
				success: true,
				heading: 'We are processing your request.',
				content: '',
				button: false
			});

			await paymentService.purchaseMembership(promo);
			refetch();

			if (!isIOS) {
				navigate(successRedirect);
			}

			await alertModal.show({
				success: true,
				heading: 'Membership Purchased!',
				content: 'You LEAGUE Membership has been Activated automatically',
				onActionClose: () => {
					if (isIOS) {
						window.location.href = downloadAppRedirect;
					} else {
						navigate('/');
					}
				}
			});
		} catch (e: any) {
			navigate(errorRedirect);

			await alertModal.show({
				success: false,
				heading: 'Purchase error',
				content: e.message
			});
		}
	};
	const handleError = async () => {
		navigate(errorRedirect);
		alertModal.show({
			success: false,
			heading: 'Failed Transition',
			content: 'Please, try using different payment method\n or contact our support'
		});
	};

	return isAvailable ? (
		<PurchaseModal
			name="Membership"
			price={price}
			onConfirm={handleSuccess}
			onError={handleError}
		/>
	) : null;
};
