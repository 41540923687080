import React from 'react';
import { RouteObject } from 'react-router-dom';
import { LogInModal } from 'components/modals/LogIn';
import { SignUpModal } from 'components/modals/SignUp';
import { DownloadApp } from 'pages/DownloadApp/index';
import { ForgotPasswordModal, ResetPasswordModal } from 'components/modals/Password';
import { SupportModal } from 'components/modals/Support';
import { MembershipModal } from 'components/modals/Membership';
import { RenewModal } from 'components/modals/Membership';
import { PrivateRoute } from 'components/PrivateRoute';
import { LeagueRoute } from 'components/LeagueRoute';
import { RenewMembershipModal } from 'components/modals/RenewMembershipAlert/RenewMembershipModal';
import ComponentsGrid from 'pages/ComponentsGrid';
import Presale from 'pages/Presale';
import InDevelopmentPage from 'pages/InDevelopment';
import Privacy from 'pages/Privacy';
// import MonthlyBoost from 'pages/MonthlyBoost';
import RestrictedPage from 'pages/Restricted';
import BetSwap from 'pages/BetSwap';

import LeagueDashboard from 'pages/League/Dashboard';
// import LeagueJoin from 'pages/League/LeagueConnect';
import LeaguePage from 'pages/League/Dashboard';
import LeagueConnect from 'pages/League/LeagueConnect';
import LeagueContests from 'pages/League/Contests';
import LeagueTokens from 'pages/League/Tokens';
import Download from 'pages/Download';
import { RoundUp } from 'pages/Promotion/RoundUp';
import { Terms } from 'pages/Terms';

import LeagueLeaderboard from 'pages/League/Leaderboard';
import LeagueBoost from 'pages/League/Boost';
import LeagueJoin from './pages/League/LeagueJoin';
import { AcceptTermsModal } from './components/modals/AcceptTerms';

import { London150, London5 } from './pages/Promos';
import { ExpireMembershipModal } from "./components/modals/RenewMembershipAlert";
import BetMGM from 'pages/BetMGM';

const Profile = React.lazy(() => import('./pages/Profile'));
const Register = React.lazy(() => import('./pages/Register'));
const Contests = React.lazy(() => import('./pages/Contests'));
const Contest = React.lazy(() => import('./pages/Contest'));
const RivalScreen = React.lazy(() => import('./pages/Contest/RivalScreen'));
const HowToPlay = React.lazy(() => import('./pages/HowToPlay'));

const withFallback = (Component: React.ComponentType, auth = false) => (
	<React.Suspense fallback={<>...</>}>
		{auth ? (
			<PrivateRoute>
				<Component />
			</PrivateRoute>
		) : (
			<Component />
		)}
	</React.Suspense>
);

const leagueFallback = (
	Component: React.ComponentType,
	isConnected = true,
	redirect = '/league/login'
) => (
	<React.Suspense fallback={<>...</>}>
		<PrivateRoute>
			<LeagueRoute
				component={Component}
				redirectPath={redirect}
				isConnected={isConnected}
			/>
		</PrivateRoute>
	</React.Suspense>
);

const signLogInChild = [
	{
		path: 'login',
		element: <LogInModal />
	},
	{
		path: 'sign-up',
		element: <SignUpModal />
	},
	{
		path: 'sign-up/:code',
		element: <SignUpModal />
	}
];

export const routes: RouteObject[] = [
	{
		path: '/',
		element: <ComponentsGrid />,
		children: [
			{
				path: 'login',
				element: <LogInModal />
			},
			{
				path: 'sign-up',
				element: <SignUpModal />
			},
			{
				path: 'sign-up/:code',
				element: <SignUpModal />
			},
			{
				path: 'forgot',
				element: <ForgotPasswordModal />
			},
			{
				path: 'reset/:token',
				element: <ResetPasswordModal />
			},
			{
				path: 'support',
				element: <SupportModal />
			},
			{
				path: 'accept-new-terms',
				element: <AcceptTermsModal />
			}
		]
	},
	{
		path: '/sale',
		element: <Presale />,
		children: [
			{
				path: 'membership',
				element: withFallback(MembershipModal, true)
			}
		]
	},
	{
		path: '/register',
		element: withFallback(Register)
	},
	{
		path: '/profile/:tab',
		element: withFallback(Profile, true)
	},
	{
		path: '/contest',
		element: withFallback(Contests, true)
	},
	{
		path: '/contest/:id',
		children: [
			{
				element: withFallback(Contest, true),
				index: true
			},
			{
				element: withFallback(RivalScreen, true),
				path: 'selection/:user_id'
			}
		]
	},
	{
		path: '/how-to-play',
		element: withFallback(HowToPlay)
	},
	{
		path: '/betswap',
		element:
			process.env.REACT_APP_SHOW_BETSWAP == 'true' ? withFallback(BetSwap) : <></>
	},
	{
		path: 'league-renewal',
		element: <Presale />,
		children: [
			{
				path: 'renew', // is expiring
				element: <ExpireMembershipModal />,
			},
			{
				path: 'expired', // is expired
				element: <RenewMembershipModal />,
			},
			{
				path: 'renewleague', // is expired
				element: <RenewModal />,
			},
		]
	},
	{
		path: '/league',
		children: [
			{
				element: leagueFallback(LeaguePage),
				index: true
			},
			{
				path: 'login',
				element: <Presale />,
				children: [
					{
						path: 'membership',
						element: withFallback(MembershipModal, true)
					}
				]
			},
			{
				path: 'connect',
				element: <LeagueConnect />
			},
			{
				path: 'dashboard',
				element: leagueFallback(LeagueDashboard)
			},
			{
				path: 'contests',
				element: leagueFallback(LeagueContests)
			},
			{
				path: 'tokens',
				element: leagueFallback(LeagueTokens)
			},
			{
				path: 'leaderboard',
				element: leagueFallback(LeagueLeaderboard)
			}
		]
	},
	{
		path: '/rewards',
		element: <InDevelopmentPage />
	},
	{
		path: '/restricted',
		element: <RestrictedPage />
	},
	{
		path: '/mgm',
		element: <BetMGM />
	},
	{
		path: '/download',
		element: <Download />
	},
	{
		path: '/download-app',
		element: <DownloadApp />
	},
	{
		path: '/round',
		element: <RoundUp />
	},
	{
		path: '/terms',
		element: <Terms />
	},
	{
		path: '/privacy',
		element: <Privacy />
	},
	//promo pages
	{
		path: '/london150',
		children: [
			{
				element: <London150 />,
				index: true
			},
			{
				path: 'membership',
				element: withFallback(MembershipModal, true)
			},
			...signLogInChild
		]
	},
	{
		path: '/london5/',
		children: [
			{
				element: <London5 />,
				index: true
			},
			...signLogInChild
		]
	}
];
