import { batch } from 'react-redux';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { userApi } from 'services/user.service';
import { contestApi } from 'services/contest.service';
import { paymentApi } from 'services/payment.service';
import { wheelApi } from 'services/boost.service';
import { nftApi } from 'services/nft.service';
import { notificationsApi } from 'services/notifications.service';
import { userSlice } from './user/user.slice';
import { nftSlice } from './nft/nft.slice';
import { contestSlice } from './contest/contest.slice';
import { paymentSlice } from './payment/payment.slice';

export const store = configureStore({
	reducer: {
		user: userSlice.reducer,
		contest: contestSlice.reducer,
		payment: paymentSlice.reducer,
		nft: nftSlice.reducer,
		[userApi.reducerPath]: userApi.reducer,
		[contestApi.reducerPath]: contestApi.reducer,
		[paymentApi.reducerPath]: paymentApi.reducer,
		[nftApi.reducerPath]: nftApi.reducer,
		[wheelApi.reducerPath]: wheelApi.reducer,
		[notificationsApi.reducerPath]: notificationsApi.reducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.concat(userApi.middleware)
			.concat(contestApi.middleware)
			.concat(nftApi.middleware)
			.concat(paymentApi.middleware)
			.concat(notificationsApi.middleware),
	devTools: process.env.NODE_ENV === 'development'
});

export const clearStore = () => {
	return (dispatch: (arg0: { payload: undefined; type: string }) => void) => {
		batch(() => {
			dispatch(userApi.util.resetApiState());
			dispatch(contestApi.util.resetApiState());
			dispatch(nftApi.util.resetApiState());
			dispatch(notificationsApi.util.resetApiState());
		});
	};
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
