/**
 * List of all the networks supported by the Uniswap Interface
 */
export enum SupportedChainId {
	MAINNET = 1,
	ROPSTEN = 3,
	RINKEBY = 4,
	GOERLI = 5,
	KOVAN = 42

	// ARBITRUM_ONE = 42161,
	// ARBITRUM_RINKEBY = 421611,

	// OPTIMISM = 10,
	// OPTIMISTIC_KOVAN = 69,

	// POLYGON = 137,
	// POLYGON_MUMBAI = 80001,
}

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(
	SupportedChainId
).filter((id) => typeof id === 'number') as SupportedChainId[];
