import React, { useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormAlert } from 'components/ui/FormAlert';
import { InputPassword } from 'components/ui/Input';
import { Button } from 'components/ui/Button';
import { PasswordChecklist } from 'components/ui/PasswordChecklist';
import userService from 'services/user.service';

const initialValues = {
	currentPassword: '',
	password: '',
	passwordConfirmation: ''
};
const validationSchema = Yup.object({
	currentPassword: Yup.string().required('Required field!'),
	password: Yup.string()
		.required()
		.min(8)
		.test('passwordRequirements', 'Information', (value) =>
			[/\d/, /^.*[!@#$%^&*()_+\-=\]{};':"\\|,.<>?].*$/].every((pattern) =>
				pattern.test(value || '')
			)
		),
	passwordConfirmation: Yup.string().oneOf(
		[Yup.ref('password'), null],
		'Passwords must match'
	)
}).required();

type Props = {
	onSuccess: () => void;
};

export function ChangeForm({ onSuccess }: Props) {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, isSubmitting }
	} = useForm<typeof initialValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: initialValues,
		mode: 'onTouched'
	});

	const [error, setError] = useState<string>();
	const watchPassword = watch('password');

	const onSubmit = async (values: typeof initialValues) => {
		try {
			await userService.changePassword({
				oldpassword: values.currentPassword,
				password: values.password
			});
			onSuccess();
		} catch (e: any) {
			setError(e.message);
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<InputPassword
					{...register('currentPassword')}
					label="Enter current password"
					error={!!errors.currentPassword}
					helperText={errors.currentPassword?.message}
					fullWidth={true}
					className="mb-20"
				/>
				<InputPassword
					{...register('password')}
					label="Password"
					error={!!errors.password}
					fullWidth={true}
					className="mb-20"
				/>
				<PasswordChecklist value={watchPassword} />
				<InputPassword
					{...register('passwordConfirmation')}
					label="Repeat new password"
					error={!!errors.passwordConfirmation}
					helperText={errors.passwordConfirmation?.message}
					fullWidth={true}
					className="mb-20"
				/>

				<Button
					fullWidth={true}
					type="submit"
					color="primary"
					disabled={isSubmitting}
				>
					Save
				</Button>
			</form>

			{error && <FormAlert type="error">{error}</FormAlert>}
		</>
	);
}
