import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux';
import { logoutUser } from 'store/user/user.actions';

export const useLogout = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	return () => {
		dispatch(logoutUser());
		navigate('/');
	};
};
