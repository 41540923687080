import { BaseLayout } from 'components/layout/BaseLayout';
import { Container } from 'components/layout/Grid';
import { Footer } from 'components/layout/Footer';
import { Typography } from 'components/ui/Typography';
import { Link } from 'components/ui/Link';
import { Delimiter } from 'components/ui/Delimiter';

const Privacy = () => {
	const defaultTitle = 'h4';
	const defaultSubTitle = 'h6';
	const defaultP = 'p2';

	const delimiter = <Delimiter backgroundColor={'#fff'} />;
	return (
		<BaseLayout>
			<Container className="ptb-80" centered={true}>
				<Typography variant={defaultTitle} textAlign="center">
					PRIVACY POLICY – Bounty Sports Inc
				</Typography>
				<Typography variant={defaultP}>
					<Typography variant={defaultP} component="span" weight="bold">
						THE TEN PRIVACY PRINCIPLES OF BOUNTY SPORTS INC.
					</Typography>{' '}
					(Canadian Residents)
				</Typography>
				<Typography variant={defaultP}>
					Bounty Sports Inc. and its subsidiaries and affiliated entities, if
					any, and their respective representatives (collectively{' '}
					<Typography variant={defaultP} component="span" weight="bold">
						"Bounty")
					</Typography>{' '}
					have always been and will continue to be committed to maintaining the
					accuracy, confidentiality, and security of your personal information.
					As part of this commitment, we have established our Ten Privacy
					Principles to govern our activities as they relate to the use of
					customer personal information. We invite you to review our principles,
					which have been built upon the values set by Canada's federal Personal
					Information Protection and Electronic Documents Act (
					<Typography variant={defaultP} component="span" weight="bold">
						"PIPEDA"
					</Typography>
					).We may amend this privacy policy from time to time to reflect
					changes in the regulatory environment and/or industry practices and
					standards and will post any such revised policy on our website at{' '}
					<Link href={'/privacy'}>www.bountysports.com/privacy</Link> (
					<Typography variant={defaultP} component="span" weight="bold">
						“Site”
					</Typography>
					). This privacy policy was last updated on October 20, 2020.
				</Typography>
				{delimiter}
				<Typography variant={defaultP}>Principle One - Accountability</Typography>
				<Typography variant={defaultP}>
					Principle Two - Identifying Purposes
				</Typography>
				<Typography variant={defaultP}>Principle Three - Consent</Typography>
				<Typography variant={defaultP}>
					Principle Four - Limiting Collection
				</Typography>
				<Typography variant={defaultP}>
					Principle Five - Limiting Use, Disclosure and Retention
				</Typography>
				<Typography variant={defaultP}>Principle Six - Accuracy</Typography>
				<Typography variant={defaultP}>
					Principle Seven - Safeguarding Customer Information
				</Typography>
				<Typography variant={defaultP}>Principle Eight - Openness</Typography>
				<Typography variant={defaultP}>
					Principle Nine - Customer Access
				</Typography>
				<Typography variant={defaultP}>
					Principle Ten - Handling Customer Complaints and Suggestions
				</Typography>
				{delimiter}
				<Typography variant={defaultSubTitle}>
					Principle One — Accountability
				</Typography>
				<Typography variant={defaultP}>
					Bounty is responsible for personal information under its control. In
					fulfilling this mandate, we have designated an individual or
					individuals who is/are accountable for compliance with our Ten Privacy
					Principles.
				</Typography>

				<Typography variant={defaultSubTitle}>
					Principle One — Accountability
				</Typography>
				<Typography variant={defaultP}>
					Bounty is responsible for personal information under its control. In
					fulfilling this mandate, we have designated an individual or
					individuals who is/are accountable for compliance with our Ten Privacy
					Principles.
				</Typography>

				<Typography variant={defaultSubTitle}>
					Principle Two — Identifying Purpose
				</Typography>
				<Typography variant={defaultP}>
					The purposes for which personal information is collected, used and
					disclosed are to be identified before or at the time we collect
					personal information. Bounty complies with this principle by, among
					other steps, making available this privacy policy to all affected
					individuals.
				</Typography>

				<Typography variant={defaultSubTitle}>
					Principle Three — Consent
				</Typography>
				<Typography variant={defaultP}>
					The knowledge and consent of the customer are required for the
					collection, use or disclosure of customer personal information, except
					where inappropriate or permitted by law. Depending on the sensitivity
					of the information, this consent can be implied or expressed; however,
					wherever commercially feasible Bounty shall attempt to obtain express
					consent. Consent to our use of personal information can be withdrawn
					at any time by following the directions at the end of this policy.
				</Typography>

				<Typography variant={defaultSubTitle}>
					Principle Four — Limited Collection
				</Typography>
				<Typography variant={defaultP}>
					Customer personal information collected must be limited to those
					details reasonably necessary for the purposes identified and must be
					collected by fair and lawful means. Accordingly, Bounty neither
					collects information which pertains to customer health, race or ethnic
					origin.
				</Typography>

				<Typography variant={defaultSubTitle}>
					Principle Five — Limiting Use, Disclosure and Retention
				</Typography>
				<Typography variant={defaultP}>
					Customer personal information may only be used or disclosed for the
					purpose for which it was collected unless the customer has otherwise
					consented, or when it is required or permitted by law. As customer
					personal information is only to be retained for the period of time
					required to fulfill the purpose for which it was collected Bounty has
					established and implemented guidelines and procedures for retaining
					and destroying such information.
				</Typography>

				<Typography variant={defaultSubTitle}>
					Principle Six — Accuracy
				</Typography>
				<Typography variant={defaultP}>
					Customer personal information, as available to us, shall be maintained
					in as accurate, complete and up-to-date form as is necessary to
					fulfill the purpose for which it is to be used. If any of your
					information changes please inform us by contacting us as described at
					the end of this privacy policy so that we can make any necessary
					changes.
				</Typography>

				<Typography variant={defaultSubTitle}>
					Principle Seven — Safeguarding Customer Information
				</Typography>
				<Typography variant={defaultP}>
					Customer personal information must be protected by security safeguards
					that are appropriate to the sensitivity level of the information. All
					Bounty computer systems are password protected for this same reason.
				</Typography>

				<Typography variant={defaultSubTitle}>
					Principle Eight — Openness
				</Typography>
				<Typography variant={defaultP}>
					We are required to make specific easily understandable information
					available to customers concerning the policies and practices that
					apply to the management of their personal information. Making
					available to customers this privacy policy, via our Site, is a key
					method of making such information available.
				</Typography>

				<Typography variant={defaultSubTitle}>
					Principle Nine — Customer Access
				</Typography>
				<Typography variant={defaultP}>
					Upon request, a customer shall be informed of the existence, use and
					disclosure of their personal information, and shall be given access to
					it. Customers may verify the accuracy and completeness of their
					personal information, and may request that it be amended, if
					appropriate. Contact us as described at the end of this privacy
					policy. Summary information is available on request and we will
					respond to the request within 30 days of receipt. More detailed
					requests which require archive or other retrieval costs may be subject
					to our normal professional and disbursement fees and may take longer
					to respond to.
				</Typography>

				<Typography variant={defaultSubTitle}>
					Principle Ten — Handling Customer Complaints and Suggestions
				</Typography>
				<Typography variant={defaultP}>
					Customers may direct any questions or enquiries with respect to the
					privacy principles outlined above or about our practices by contacting
					the designated person(s) accountable for privacy.
				</Typography>
				{delimiter}
				<Typography variant={defaultSubTitle}>
					Why is personal information needed?
				</Typography>

				<Typography variant={defaultP}>
					We collect, use and disclose customer personal information in order
					to:
					<ul>
						<li>confirm customer identity;</li>
						<li>
							assess customer suitability for our products and services;
						</li>
						<li>
							provide the products and services that have been requested or
							to offer additional products and/or services we believe the
							customer may be interested in;
						</li>
						<li>communicate with customers;</li>
						<li>process account payments and/or transactions;</li>
						<li>
							meet regulatory requirements, including compliance with
							privacy laws; and
						</li>
						<li>respond to any event(s) of default.</li>
					</ul>
				</Typography>

				<Typography variant={defaultSubTitle}>What is collected?</Typography>
				<Typography variant={defaultP}>
					"Personal information" is any information that identifies a customer,
					or by which a customer identity could be deduced. Please note that
					"personal information" does not include either aggregate information
					that does not allow an individual to be identified, information about
					a visit to our Site(s) which is not linked to the customer, or
					information about a customer's computer operating system and web
					browser software (this technical information is verified to ensure
					that our Site(s) are optimized to serve our customers). We use
					anonymous/non-personal information to improve our products and
					services to our customers.
				</Typography>
				<Typography variant={defaultP}>
					We only collect non-prohibited personal information which is related
					to our business such as customer name, address, date of birth, email
					address, physical and/or billing address, phone number, password,
					username, credit card details, banking details.
				</Typography>

				<Typography variant={defaultSubTitle}>
					How is personal information collected?
				</Typography>
				<Typography variant={defaultP}>
					Wherever possible we collect personal information in an active fashion
					directly from the customer. Please note that we may elect to record a
					conversation with a customer for quality control and accuracy
					purposes.
				</Typography>

				<Typography variant={defaultSubTitle}>
					Will information be disclosed to outside parties?
				</Typography>
				<Typography variant={defaultP}>
					Bounty does not disclose your personal information to any third party
					to enable them to market their products and services. We are obliged
					to keep customer personal information confidential except under the
					following circumstances:
					<ul>
						<li>
							when expressly or impliedly authorized by the customer, for
							example disclosures to agencies and other organizations in
							order to verify that the personal information provided by a
							customer is accurate – please note that in certain limited
							circumstances when the services we are providing to you
							requires us give your information to third parties your
							consent will be implied, unless you tell us otherwise.
						</li>
						<li>
							when we are required or authorized by law to do so, for
							example if a court issues a subpoena;
						</li>
						<li>
							if we engage a third party to provide products and/or services
							to us (like computer back-up services or archival file
							storage) and such third party is contractually bound by our
							privacy policy and all applicable privacy laws, please note
							that such service provider(s) may be located outside of
							Canada;
						</li>
						<li>
							personal information located outside of Canada may result in
							the information being subject to foreign access requests;
						</li>
						<li>
							when we engage in business or a transaction with a third party
							including, without limitation, a financing, partnership, joint
							venture or the sale of part or all of our assets, business or
							undertaking, we may share and, if required to complete the
							business or transaction, transfer and convey such information
							to the third party;
						</li>
						<li>
							when we share such information with our subsidiaries or
							affiliated companies;
						</li>
						<li>where it is necessary to establish or collect fees; or</li>
						<li>if the information is already publicly known.</li>
					</ul>
				</Typography>

				<Typography variant={defaultSubTitle}>
					How is personal information safeguarded?
				</Typography>
				<Typography variant={defaultP}>
					We implement commercially reasonable industry standard policies,
					procedures, technologies and security standards to ensure that
					customer personal information is protected against unauthorized
					access, and inappropriate disclosure, alteration or misuse. All safety
					and security measures which are implemented are designed to be
					appropriate to the sensitivity level of the stored customer personal
					information. Among the steps taken to protect your information are:
					<ul>
						<li>premises security;</li>
						<li>
							restricted file access to personal information to only those
							with a need to know;
						</li>
						<li>
							deploying technological safeguards like security software and
							firewalls to prevent hacking or unauthorized computer access;
						</li>
						<li>internal password and security policies;</li>
						<li>
							secure disposal of personal information no longer needed; and
						</li>
						<li>screening and training of personnel.</li>
					</ul>
					Bounty cannot, however, guarantee that loss, misuse or unauthorized
					use will never occur (for example, someone could conceivably overcome
					our security measures). If you receive any electronic communication
					which purports to be from Bounty that you have any questions or
					concerns about, please contact us. Spam, improper use, and pirating of
					domain names and email addresses is a growing problem, so we
					appreciate hearing about incidents in order that we may investigate
					them and provide you the best customer service.
				</Typography>

				<Typography variant={defaultSubTitle}>
					Can access to personal information be denied?
				</Typography>
				<Typography variant={defaultP}>
					Rights to access personal information are not absolute. We may deny
					access to a customer when:
					<ul>
						<li>denial of access is required or authorized by law;</li>
						<li>
							information relates to existing or anticipated legal
							proceedings against the customer;
						</li>
						<li>
							the information was generated as a result of a formal dispute
							resolution process including a court case; or
						</li>
						<li>
							when granting the customer access would have an unreasonable
							impact on other people's privacy, security or proprietary
							information.
						</li>
					</ul>
					If we deny a request for access to, or refuse a request to correct
					information, we shall do so in writing and explain why.
				</Typography>

				<Typography variant={defaultSubTitle}>Communicating with us</Typography>
				<Typography variant={defaultP}>
					Any channel of communication, such as e-mail, is not 100% secure, and
					you should be aware of this when contacting us to send personal or
					confidential information. With respect to Canada's Anti-Spam
					Legislation (commonly referred to as CASL), customers hereby expressly
					consent to receiving, during and after our business relationship,
					electronic messages from Bounty, including via emails and through
					social media, providing information to you including newsletters,
					updates, alerts, other publications, news and communications, other
					information of interest to you and/or information on our services. You
					can withdraw this consent or modify your preferences as to the types
					of electronic messages which you wish to receive from us, at any time,
					simply by notifying us or by using the unsubscribe mechanism on any of
					our electronic messages.
				</Typography>

				<Typography variant={defaultSubTitle}>
					How to file a complaint?
				</Typography>
				<Typography variant={defaultP}>
					Bounty has a privacy officer who may be contacted to answer any
					comments or questions about this privacy policy. Please forward your
					written communication to:
					<br />
					Telephone: 226-919-4371
					<br />
					E-mail: jess@bountysports.com
					<br />
					Address: Bounty Sports Inc PO Box 28034 RPO Oakridge London, ON N6H
					5E1
					<br />
					Attention: Privacy Officer
					<br />
					If you are not satisfied with our response, the Office of the Privacy
					Commissioner of Canada which oversees PIPEDA can be reached at:
					<br />
					Place de Ville
					<br />
					112 Kent Street, 3rd Floor
					<br />
					Ottawa Ontario, K1A 1H3
					<br />
					1-800-282-1376
					<br />
				</Typography>
				<Typography variant={defaultP} weight={'bold'}>
					www.privcom.gc.ca
				</Typography>
				{delimiter}
				<Typography variant={defaultSubTitle}>
					<Typography variant={defaultSubTitle} component="span" weight="bold">
						CALIFORNIA CONSUMER PRIVACY ACT
					</Typography>{' '}
					(California Residents)
				</Typography>
				<Typography variant={defaultSubTitle}>Your Rights and Choices</Typography>
				<Typography variant={defaultP}>
					The California Consumer Privacy Act (
					<Typography variant={defaultP} component="span" weight="bold">
						“CCPA”
					</Typography>
					) provides users (California residents) with specific rights regarding
					their personal information. This section describes your (meaning, in
					this CCPA section, California residents) CCPA rights and explains how
					to exercise those rights.
				</Typography>
				<Typography variant={defaultSubTitle}>
					Access to Specific information and Data Portability Rights
				</Typography>
				<Typography variant={defaultP}>
					You have the right to request that Bounty disclose certain information
					to you about our collection and use of your personal information over
					the past 12 months. Once we receive and confirm your verifiable
					consumer request (see{' '}
					<Typography variant={defaultP} component="span" weight="bold">
						Exercising Access, Data Portability, and Deletion Rights
					</Typography>
					), we will disclose to you:
					<ul>
						<li>
							The categories of personal information we collected about you.
						</li>
						<li>
							The categories of sources for the personal information we
							collected about you.
						</li>
						<li>
							Our business or commercial purpose for collecting or selling
							that personal information.
						</li>
						<li>
							The categories of third parties with whom we share that
							personal information.
						</li>
						<li>
							The specific pieces of personal information we collected about
							you.
						</li>
						<li>
							<Typography
								className={'mt-16'}
								variant={defaultP}
								component="span"
							>
								If we sold or disclosed your personal information for a
								business purpose, two separate lists disclosing:
							</Typography>
							<ul>
								<li>
									sales, identifying the personal information categories
									that each category of recipient purchased; and
								</li>
								<li>
									disclosures for a business purpose, identifying the
									personal information categories that each category of
									recipient obtained.
								</li>
							</ul>
						</li>
					</ul>
				</Typography>
				<Typography variant={defaultSubTitle}>Non-Discrimination</Typography>
				<Typography variant={defaultP}>
					We will not discriminate against you for exercising any of your CCPA
					rights. Unless permitted by the CCPA, we will not:
					<ul>
						<li>Deny you goods or services.</li>
						<li>
							Charge you different prices or rates for goods or services,
							including through granting discounts or other benefits, or
							imposing penalties.
						</li>
						<li>
							Provide you a different level or quality of goods or services.
						</li>
						<li>
							Suggest that you may receive a different price or rate for
							goods or services or a different level or quality of goods or
							services.
						</li>
					</ul>
					However, we may offer you certain financial incentives permitted by
					the CCPA that can result in different prices, rates, or quality
					levels. Any CCPA-permitted financial incentive we offer will
					reasonably relate to your personal information's value and contain
					written terms that describe the program's material aspects.
					Participation in a financial incentive program requires your prior
					opt-in consent, which you may revoke at any time.
				</Typography>

				<Typography variant={defaultSubTitle}>
					Exercising Access, Data Portability and Deletion Rights
				</Typography>
				<Typography variant={defaultP}>
					To exercise the access, data portability, and deletion rights
					described above, please submit a verifiable consumer request to us by
					either:
					<ul>
						<li>Calling us at 226-919-4371</li>
						<li>Emailing us at jess@bountysports.com</li>
					</ul>
					Only you, or a person registered with the California Secretary of
					State that you authorize to act on your behalf, may make a verifiable
					consumer request related to your personal information. You may also
					make a verifiable consumer request on behalf of your minor child.
					<br />
					You may only make a verifiable consumer request for access or data
					portability twice within a 12-month period. The verifiable consumer
					request must:
					<ul>
						<li>
							Provide sufficient information that allows us to reasonably
							verify you are the person about whom we collected personal
							information or an authorized representative.
						</li>
						<li>
							Describe your request with sufficient detail that allows us to
							properly understand, evaluate, and respond to it.
						</li>
					</ul>
					We cannot respond to your request or provide you with personal
					information if we cannot verify your identity or authority to make the
					request and confirm the personal information relates to you.
					<br />
					Making a verifiable consumer request does not require you to create an
					account with us; however, we do consider requests made through your
					password-protected account sufficiently verified when the request
					relates to personal information associated with that specific account.
					<br />
					We will only use personal information provided in a verifiable
					consumer request to verify the requester’s identity or authority to
					make the request.
				</Typography>

				<Typography variant={defaultSubTitle}>
					Do Not Sell My Personal Information
				</Typography>
				<Typography variant={defaultP}>
					Bounty will not sell your personal information. California law
					requires that we maintain a separate webpage that allows you to opt
					out of the sale of your personal information, which can be accessed by
					clicking the link below:
					<br />
					https://bountysports.hubspotpagebuilder.com/bounty-sports-unsubscribe-page
					<br />
					In particular, Bounty has collected the following categories of
					Personal Information from its consumers within the last twelve (12)
					months:
					<table
						style={{
							textAlign: 'left'
						}}
						className={'mtb-32'}
					>
						<thead>
							<th>Category</th>
							<th>Examples</th>
						</thead>
						<tbody>
							<tr>
								<td>Identifiers.</td>
								<td>
									A real name, alias, postal address. email address,
									unique personal identifier, online identifier,
									Internet Protocol address, email address, account
									name, Social Security number, driver's license number,
									passport number, current or past job history, or other
									similar identifiers.
								</td>
							</tr>
							<tr>
								<td>Commercial information.</td>
								<td>
									Records of personal property, products or services
									purchased, obtained, or considered, or other
									purchasing or consuming histories or tendencies.
								</td>
							</tr>
							<tr>
								<td>Usage.</td>
								<td>
									Information on the user's interaction with Bounty.
								</td>
							</tr>
							<tr>
								<td>Geolocation data.</td>
								<td>Physical location, generally.</td>
							</tr>
							<tr>
								<td>Financial Data</td>
								<td>
									User’s credit card information and banking details
									which may include the bank name, account number,
									transit number, institution number, ABA/routing
									number, account type and the account holder’s name.
								</td>
							</tr>
						</tbody>
					</table>
				</Typography>

				{delimiter}

				<Typography variant={defaultSubTitle}>
					We also collect personal information from other sources
				</Typography>
				<Typography variant={defaultP}>
					<ul>
						<li>
							Information received as part of routine card verification
							(fraud) checks.
						</li>
						<li>
							Social network information. When you use a social network
							login to access our services, you will share certain personal
							information from your social media account with us, for
							example, your name, email address, photo, list of social media
							contacts, and any other information that may be or you make
							accessible to us when you connect your social media account to
							your services account. The specific information transferred
							depends on your security settings and the privacy policy of
							your social media network.
						</li>
						<li>
							Joint marketing partners, when they share information with us.
						</li>
						<li>Publicly available data bases.</li>
					</ul>
				</Typography>

				{delimiter}

				<Typography variant={defaultSubTitle}>
					Use of Personal Information
				</Typography>
				<Typography variant={defaultP}>
					We may use or disclose the personal information we collect for one or
					more of the following business purposes:
					<ul>
						<li>
							To fulfill or meet the reason you provided the information.
							For example, if you share your name and contact information to
							request a price quote or ask a question about our products or
							services, we will use that personal information to respond to
							your inquiry. If you provide your personal information to
							purchase a product or service, we will use that information to
							process your payment and facilitate delivery. We may also save
							your information to facilitate new product orders or process
							returns.
						</li>
						<li>
							To provide, support, personalize, and develop our Sites,
							products, and services.
						</li>
						<li>
							To create, maintain, customize, and secure your account with
							us.
						</li>
						<li>
							To process your requests, purchases, transactions, and
							payments and prevent transactional fraud.
						</li>
						<li>
							To provide you with support and to respond to your inquiries,
							including to investigate and address your concerns and monitor
							and improve our responses.
						</li>
					</ul>
				</Typography>

				{delimiter}

				<Typography variant={defaultSubTitle}>
					Sales of Personal Information
				</Typography>
				<Typography variant={defaultP}>
					In the preceding twelve (12) months, Bounty has sold the following
					categories of personal information:
					<table
						style={{
							textAlign: 'left'
						}}
						className={'mtb-32'}
					>
						<tbody>
							<tr>
								<td>Category A: Identifiers.</td>
								<td>None</td>
							</tr>
							<tr>
								<td>
									Category B: California Customer Records personal
									information categories.
								</td>
								<td>None</td>
							</tr>
							<tr>
								<td>
									Category C: Protected classification characteristics
									under California or U.S. federal law.
								</td>
								<td>None</td>
							</tr>
							<tr>
								<td>Category D: Commercial information.</td>
								<td>None</td>
							</tr>
						</tbody>
					</table>
				</Typography>

				{delimiter}

				<Typography variant={defaultSubTitle}>Service Providers</Typography>
				<Typography variant={defaultP}>
					We engage certain trusted third parties to perform functions and
					provide services to us, including hosting and maintenance, error
					monitoring, debugging, performance monitoring, billing, customer
					relationship, database storage and management, and direct marketing
					campaigns. We may share your personal information with these third
					parties, but only to the extent necessary to perform these functions
					and provide such services. We also require these third parties to
					maintain the privacy and security of the personal information they
					process on our behalf.
				</Typography>

				{delimiter}

				<Typography variant={defaultSubTitle}>
					<Typography variant={defaultSubTitle} component="span" weight="bold">
						EU GENERAL DATA PROTECTION REGULATION
					</Typography>{' '}
					(EU Residents)
				</Typography>

				<Typography variant={defaultP}>
					This privacy policy will explain how Bounty Sports Inc. (
					<Typography variant={defaultP} component="span" weight="bold">
						“Bounty”
					</Typography>
					) uses the personal data we collect from you (meaning, in this GDPR
					section, EU residents) when you use our Site and complies with the EU
					General Data Protection Regulation.
				</Typography>

				<Typography variant={defaultSubTitle}>Topics:</Typography>
				<Typography variant={defaultP}>
					<ul>
						<li>What data do we collect?</li>
						<li>How do we collect your data?</li>
						<li>How will we use your data?</li>
						<li>How do we store your data?</li>
						<li>Marketing</li>
						<li>What are your data protection rights?</li>
						<li>What are cookies?</li>
						<li>How do we use cookies?</li>
						<li>What types of cookies do we use?</li>
						<li>How to manage your cookies</li>
						<li>Privacy policies of other websites</li>
						<li>Changes to our privacy policy</li>
						<li>How to contact us</li>
						<li>How to contact the appropriate authorities</li>
					</ul>
				</Typography>

				{delimiter}

				<Typography variant={defaultSubTitle}>
					What data do we collect?
				</Typography>
				<Typography variant={defaultP}>
					Bounty collects the following data:
					<ul>
						<li>
							Personal identification information (Name, email address,
							phone number, etc.)
						</li>
						<li>
							Personal financial information (Bank account, transit,
							institution, name, etc.)
						</li>
						<li>
							Non-personally identifiable information (Anonymized browsing
							history, demographic information, etc.)
						</li>
					</ul>
				</Typography>

				{delimiter}

				<Typography variant={defaultSubTitle}>
					How do we collect your data?
				</Typography>
				<Typography variant={defaultP}>
					You directly provide Bounty with most of the data we collect. We
					collect data and process data when you:
					<ul className={'mtb-20'}>
						<li>Register online or for any of our products or services.</li>
						<li>
							Perform financial transactions such as a deposit or withdrawal
						</li>
						<li>
							Voluntarily complete a customer survey or provide feedback on
							any of our message boards, email, text/sms, chat or phone
							services
						</li>
						<li>Use or view our Site via your browser's cookies.</li>
					</ul>
					Bounty may also receive your data indirectly from the following
					sources:
					<ul className={'mtb-20'}>
						<li>
							Third parties and partners who have collected non-PII data
							used for data augmentation
						</li>
					</ul>
				</Typography>

				{delimiter}

				<Typography variant={defaultSubTitle}>
					How will we use your data?
				</Typography>
				<Typography variant={defaultP}>
					Bounty collects your data so that we can:
					<ul className={'mtb-20'}>
						<li>Process your registration and manage your account.</li>
						<li>
							Perform financial transactions such as a deposit or
							withdrawal.
						</li>
						<li>
							Communicate with you on information regarding your account,
							activities by Bounty sports, or with special offers on other
							products and services we think you might like, via email,
							chat, text/sms or phone.
						</li>
					</ul>
					If you agree, Bounty will share your data with our partner companies
					so that they may offer you their products and services.
					<ul className={'mtb-20'}>
						<li>None</li>
					</ul>
				</Typography>

				{delimiter}

				<Typography variant={defaultSubTitle}>
					How do we store your data?
				</Typography>
				<Typography variant={defaultP}>
					Bounty securely stores your data on Amazon Webservices and Hubspot
					Webservices. Data is collected via SSL and stored in accordance with
					local laws, in an encrypted format where possible.
					<br />
					Unless required by applicable law for data retention, Bounty will keep
					the personally identifiable data collected from you for a maximum
					period of 24 months after you last transact with Bounty in any way
					including, but not limited to, logging onto our Site, apps or other
					services, depositing or withdrawing funds from our services, or
					engaging with any of our communications. Non-personally identifiable
					information relating to your activity with us may be kept for
					analytics purposes.
					<br />
					Once this time period has expired, we will delete your data by purging
					the data from all systems managed by Bounty.
				</Typography>

				{delimiter}

				<Typography variant={defaultSubTitle}>Marketing</Typography>
				<Typography variant={defaultP}>
					Bounty would like to send you information about products and services
					of ours that we think you might like.
					<br />
					If you have agreed to receive marketing, you may always opt out at a
					later date.
					<br />
					You have the right at any time to stop Bounty from contacting you for
					marketing purposes.
					<br />
					If you no longer wish to be contacted for marketing purposes, please
					click here.
					<br />
					https://bountysports.hubspotpagebuilder.com/bounty-sports-unsubscribe-page
				</Typography>

				{delimiter}

				<Typography variant={defaultSubTitle}>
					What are your data protection rights?
				</Typography>
				<Typography variant={defaultP}>
					Bounty would like to make sure you are fully aware of all of your data
					protection rights. Every user is entitled to the following:
					<br />
					<Typography variant={defaultP} component={'span'} weight={'bold'}>
						The right to access
					</Typography>{' '}
					- You have the right to request Bounty for copies of your personal
					data. We may charge you a small fee for this service.
					<br />
					<Typography variant={defaultP} component={'span'} weight={'bold'}>
						The right to rectification
					</Typography>{' '}
					- You have the right to request that Bounty correct any information
					you believe is inaccurate. You also have the right to request Bounty
					to complete information you believe is incomplete.
					<br />
					<Typography variant={defaultP} component={'span'} weight={'bold'}>
						The right to erasure
					</Typography>{' '}
					- You have the right to request that Bounty erase your personal data,
					under certain conditions.
					<br />
					<Typography variant={defaultP} component={'span'} weight={'bold'}>
						The right to restrict processing
					</Typography>{' '}
					- You have the right to request that Bounty restrict the processing of
					your personal data, under certain conditions.
					<br />
					<Typography variant={defaultP} component={'span'} weight={'bold'}>
						The right to object to processing
					</Typography>{' '}
					- You have the right to object to Bounty's processing of your personal
					data, under certain conditions.
					<br />
					<Typography variant={defaultP} component={'span'} weight={'bold'}>
						The right to data portability
					</Typography>{' '}
					- You have the right to request that Bounty transfer the data that we
					have collected to another organization, or directly to you, under
					certain conditions.
					<br />
					<br />
					<br />
					If you make a request, we have one month to respond to you. If you
					would like to exercise any of these rights, please contact us at our
					email:{' '}
					<Typography variant={defaultP} component={'span'} weight={'bold'}>
						jess@bountysports.com
					</Typography>
					<br />
					Call us at:
					<Typography variant={defaultP} component={'span'} weight={'bold'}>
						226-919-4371
					</Typography>
					<br />
					Or write to us:
					<Typography variant={defaultP} component={'span'} weight={'bold'}>
						Bounty Sports Inc
						<br />
						PO Box 28034 RPO Oakridge London, ON N6H 5E1
					</Typography>
					<br />
				</Typography>

				{delimiter}

				<Typography variant={defaultSubTitle}>What are cookies?</Typography>
				<Typography variant={defaultP}>
					Cookies are text files placed on your computer to collect standard
					Internet log information and visitor behavior information. When you
					visit our Site, we may collect information from you automatically
					through cookies or similar technology.
				</Typography>

				<Typography variant={defaultSubTitle}>How do we use cookies?</Typography>
				<Typography variant={defaultP}>
					Bounty uses cookies in a range of ways to improve your experience on
					our Site, including:
					<ul>
						<li>Keeping you signed in</li>
						<li>Understanding how you use our Site</li>
						<li>Personalising communications and experiences</li>
					</ul>
				</Typography>

				<Typography variant={defaultSubTitle}>
					What types of cookies do we use?
				</Typography>
				<Typography variant={defaultP}>
					There are a number of different types of cookies, however, our Site
					uses:
					<ul>
						<li>
							Functionality - Bounty uses these cookies so that we recognize
							you on our Site and remember your previously selected
							preferences. These could include what language you prefer and
							location you are in. A mix of first-party and third-party
							cookies are used.
						</li>
						<li>
							Advertising - Bounty uses these cookies to collect information
							about your visit to our Site, the content you viewed, the
							links you followed and information about your browser, device,
							and your IP address. Bounty sometimes shares some limited
							aspects of this data with third parties for advertising
							purposes.
						</li>
						<li>
							Experience – Bounty uses these cookies to understand how
							you’re using our site and services, and to distribute
							communications to you accordingly in relation to Bounty’s
							services]
						</li>
					</ul>
				</Typography>

				<Typography variant={defaultSubTitle}>How to manage cookies</Typography>
				<Typography variant={defaultP}>
					You can set your browser not to accept cookies. However, some of our
					Site features may not function as a result.
				</Typography>

				{delimiter}

				<Typography variant={defaultSubTitle}>
					Privacy policies of other websites
				</Typography>
				<Typography variant={defaultP}>
					The Bounty Site contains links to other websites. Our privacy policy
					applies only to our Site, so if you click on a link to another
					website, you should read their privacy policy.
				</Typography>

				{delimiter}

				<Typography variant={defaultSubTitle}>
					Changes to our privacy policy
				</Typography>
				<Typography variant={defaultP}>
					Bounty keeps its privacy policy under regular review and places any
					updates on this web page. This privacy policy was last updated on
					November 25th, 2020.
				</Typography>

				{delimiter}

				<Typography variant={defaultSubTitle}>How to contact us</Typography>
				<Typography variant={defaultP}>
					If you have any questions about Bounty's privacy policy, the data we
					hold on you, or you would like to exercise one of your data protection
					rights, please do not hesitate to contact us.
					<br />
					Email us at:{' '}
					<Typography variant={defaultP} component={'span'} weight={'bold'}>
						jess@bountysports.com
					</Typography>
					<br />
					Call us:{' '}
					<Typography variant={defaultP} component={'span'} weight={'bold'}>
						226-919-4371
					</Typography>
					<br />
					Or write to us at:{' '}
					<Typography variant={defaultP} component={'span'} weight={'bold'}>
						Bounty Sports Inc PO Box 28034 RPO Oakridge London, ON N6H 5E1
					</Typography>
				</Typography>

				{delimiter}

				<Typography variant={defaultSubTitle}>
					How to contact the appropriate authority
				</Typography>
				<Typography variant={defaultP}>
					Should you wish to report a complaint or if you feel that Bounty has
					not addressed your concern in a satisfactory manner, you may contact
					the Information Commissioner's Office.
					<br />
					Email:{' '}
					<Typography variant={defaultP} component={'span'} weight={'bold'}>
						jess@bountysports.com
					</Typography>
					<br />
					Address:{' '}
					<Typography variant={defaultP} component={'span'} weight={'bold'}>
						Bounty Sports Inc PO Box 28034 RPO Oakridge London, ON N6H 5E1
					</Typography>
				</Typography>
			</Container>
			<Footer />
		</BaseLayout>
	);
};

export default Privacy;
