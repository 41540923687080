import React from 'react';
import { Icon } from './Icon';
import { ExternalIconProps } from './Icon.model';
import { ReactComponent } from 'assets/images/icons/share.svg';
import classNames from 'classnames';
import style from './icons.module.scss';

export function ShareIcon({ className, ...props }: ExternalIconProps) {
	return (
		<Icon
			{...props}
			className={classNames(className, style['share-icon'])}
			component={ReactComponent}
		/>
	);
}
