import React from 'react';
import { DashboardStats } from './Stats';
import { LeagueLayout } from 'pages/League/Layout';
import { Draw } from './Draw';
import { DashboardContest } from './Contests';
import { DashboardTokens } from './Tokens';
import { DashboardTimer } from './Timer';
import { MembershipInfo } from './MembershipInfo';
import { MembershipReminder } from './MembershipReminder';

import style from './dashboard.module.scss';
import classNames from 'classnames';

export default function LeagueDashboard() {
	return (
		<LeagueLayout>
			<MembershipReminder />
			<div className={classNames(style.dashboard, 'ptb-20')}>
				<DashboardStats />
				<Draw />
				<DashboardContest />
				<DashboardTokens />
				<MembershipInfo />
				{/*<DashboardTimer />*/}
			</div>
		</LeagueLayout>
	);
}
