import React from 'react';
import { Modal } from 'components/ui/Modal';
import { Typography } from 'components/ui/Typography';
import { useModal } from '@ebay/nice-modal-react';
import {TermsModal} from "../Terms";
import { Link } from 'components/ui/Link';
import style from './accept.terms.module.scss';
import {Button} from "../../ui/Button";


export function AcceptTermsModal() {
	const termsModal = useModal(TermsModal);
	function viewTermsOfService(){
		termsModal.show();
	}

	return (
		<Modal
			className="p-40 width-popupDesktop"
			titleClassName={`m-0 ${style.title}`}
			title=""
			isOpen
			forRoute={false}
		>
		<Typography variant="p2" textAlign="center">
			<Typography className="pr-8" variant="p2" component="span">
				We have updated our terms and conditions. Please accept these updated terms and conditions to continue using Bounty Sports.
			</Typography>
		</Typography>
		<Typography textAlign="center">
			<Button onClick={()=>{viewTermsOfService()}}>View and Accept Terms</Button>
		</Typography>

		</Modal>
	);
}
