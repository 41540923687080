import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCountdown } from 'hooks/useCountdown';
import { zeroPad } from 'utils/date';
import { FormAlert } from 'components/ui/FormAlert';
import { InputOtp } from 'components/ui/Input';
import { Button } from 'components/ui/Button';
import { Link } from 'components/ui/Link';
import { Typography } from 'components/ui/Typography';
import authService from 'services/auth.service';
import { useGTM } from 'hooks/useGTM';

const initialValues = {
	otp: ''
};
const validationSchema = Yup.object({
	otp: Yup.string().required().min(6).max(6)
});

export interface VerifyOtpFormProps {
	type: 'email' | 'phone';
	to: string;
	onSuccess: (otp: string) => any;
	bankVerify?: string
}

export function VerifyOtpForm({ type, to, onSuccess,bankVerify }: VerifyOtpFormProps) {
	const {
		watch,
		handleSubmit,
		setValue,
		formState: { errors, isSubmitting, isValid }
	} = useForm<typeof initialValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: initialValues
	});

	const { tiktokSubscribe } = useGTM();

	const optValue = watch('otp');

	const [error, setError] = useState<string>();
	const { counter, startTimer } = useCountdown(60, 1);

	useEffect(() => {
		sendCode();
	}, []);

	const sendCode = async () => {
		if (!to || !type) return;

		await authService.sendOTP(type, to, bankVerify);
		startTimer();
	};

	const onSubmit = async (values: typeof initialValues) => {
		try {
			const { otp } = values;
			await authService.verifyOTP(otp);
			tiktokSubscribe();
			onSuccess(otp);
		} catch (e: any) {
			setError(e.message);
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<InputOtp
					value={optValue}
					numInputs={6}
					hasErrored={!!errors.otp}
					shouldAutoFocus
					isInputNum
					onChange={(value: string) =>
						setValue('otp', value, { shouldValidate: true })
					}
				/>

				<div className="mb-32">
					{counter ? (
						<Typography variant="body1">
							Code was resent. Resend again in 00:
							{zeroPad(counter, 2)}
						</Typography>
					) : (
						<Link
							color="desktop"
							underline="none"
							variant="p2"
							onClick={sendCode}
						>
							Resend code
						</Link>
					)}
				</div>

				<Button
					className="mb-32"
					color="primary"
					fullWidth={true}
					disabled={isSubmitting || !isValid}
				>
					Confirm
				</Button>
			</form>

			{error && <FormAlert type="error">{error}</FormAlert>}
		</>
	);
}
