import React, { useCallback } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useWallet } from 'hooks/useWallet';
import { useGetWalletsQuery, useDeleteWalletMutation } from 'services/nft.service';
import { Modal } from 'components/ui/Modal';
import { WalletsItem } from './WalletsItem';

export const WalletsModal = NiceModal.create(() => {
	const modal = useModal();

	const { data } = useGetWalletsQuery();
	const [deleteWallet] = useDeleteWalletMutation();
	const { account, disconnectWallet } = useWallet();

	const onDisconnect = useCallback(
		async (address: string) => {
			if (address === account) disconnectWallet();
			await deleteWallet(address).unwrap();
			if (!data || data.length <= 1) await modal.hide();
		},
		[data, account, disconnectWallet]
	);

	return (
		<Modal
			title="My Wallets"
			className="p-40 width-popupDesktop"
			titleClassName="ptb-24 m-0"
			isOpen={modal.visible}
			onClose={modal.hide}
		>
			<div>
				{(data || []).map((item) => (
					<WalletsItem
						key={item}
						name="ETH"
						address={item}
						onDisconnect={onDisconnect}
					/>
				))}
			</div>
		</Modal>
	);
});
