import { useState, useMemo, useEffect } from 'react';

export enum SortingType {
	Ascending,
	Descending
}

export interface SortConfig<K = string> {
	field: K;
	direction: SortingType;
}

export const useSortable = <T>(items: any[], config?: SortConfig) => {
	const [sortConfig, setSortConfig] = useState<SortConfig | undefined>(config);

	useEffect(() => {
		setSortConfig(config);
	}, [config]);

	const sortedItems = useMemo(() => {
		const sortableItems = [...items];

		if (sortConfig) {
			const { field } = sortConfig;
			const fieldType = typeof sortableItems[0][field];

			sortableItems.sort((a, b) => {
				if (sortConfig.direction === SortingType.Ascending) {
					return fieldType === 'string'
						? a[field].localeCompare(b[field])
						: a[field] - b[field];
				}
				return fieldType === 'string'
					? b[field].localeCompare(a[field])
					: b[field] - a[field];
			});
		}

		return sortableItems;
	}, [items, sortConfig]);

	const requestSort = (field: string) => {
		let direction = SortingType.Descending;
		if (
			sortConfig &&
			sortConfig.field === field &&
			sortConfig.direction === SortingType.Descending
		) {
			direction = SortingType.Ascending;
		}

		setSortConfig({ field, direction });
	};

	return { items: sortedItems as T[], requestSort, sortConfig };
};
