import { useEffect } from 'react';
import { Typography } from '../Typography';
import cn from 'classnames';
import { LifeTimeMembershipsProps } from './Memberships.model';
import { useCountdown } from 'hooks/useCountdown';
import { getDateString } from 'utils/date';

import style from './lifetime.module.scss';

export const LifeTimeMemberships = ({
	regularPrice = 299.99,
	preSalePrice,
	title = 'LIFETIME LEAGUE MEMBERSHIP',
	saleTime,
	titleColor,
	hideTitle,
	showTimeAndPresale,
	centered,
	className
}: LifeTimeMembershipsProps) => {
	const { counter: timeLeft, startTimer } = useCountdown(saleTime ?? 0, 1000);

	useEffect(() => {
		startTimer();
	}, [saleTime]);

	return (
		<div className={cn('f-width', centered ? [style.center] : false, className)}>
			{!hideTitle && (
				<Typography
					color={titleColor ? titleColor : 'primary-mobile'}
					component="h5"
					variant="h5"
					variantXl="h6"
					variantMd="h6"
					className="m-0"
					weight="semi-bold"
				>
					{title}
				</Typography>
			)}

			<div
				className={cn(style.price, 'mt-16', {
					// [style.price_timepresale]: false
				})}
			>
				<div
					className={cn(style.price_row, 'grid', {
						[style.price_crossOut]: showTimeAndPresale
					})}
				>
					<Typography component="p">REGULAR PRICE</Typography>
					<Typography
						component="h4"
						variant={'h4'}
						className="m-0"
						weight="bold"
					>
						${regularPrice}
					</Typography>
				</div>

				{showTimeAndPresale && (
					<>
						<div className={cn(style.price_row, style.price_presale, 'grid')}>
							<Typography component="p">SALE PRICE</Typography>
							<Typography
								component={showTimeAndPresale ? 'h3' : 'h4'}
								variant={showTimeAndPresale ? 'h3' : 'h4'}
								className="m-0"
								weight="bold"
							>
								${preSalePrice}
							</Typography>
						</div>
					</>
				)}
				{showTimeAndPresale && (
					<div className={style.price_time}>
						<Typography
							className="m-0"
							weight="semi-bold"
							component="h5"
							variant="h5"
						>
							{timeLeft <= 0 ? 'Time is out' : getDateString(timeLeft)}
						</Typography>
					</div>
				)}
			</div>
		</div>
	);
};
