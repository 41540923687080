import { StarIcon } from 'components/ui/Icon';

import style from './rating.module.scss';

interface RatingProps {
	stars: number;
}

const length = 5;

export const Rating = (props: RatingProps) => {
	const starsElements: any[] = Array.apply(null, Array(length));
	return (
		<div className={style.rating}>
			{starsElements.map((item, index) => {
				const active = index + 1 <= props.stars;
				return <StarIcon key={`starIcon${index}`} active={active} />;
			})}
		</div>
	);
};
