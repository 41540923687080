import { Header } from 'components/layout/Header';
import { Body } from 'components/layout/Body';
import { Main } from 'components/layout/Main';
import { Container } from 'components/layout/Grid';

import { LeagueWallet } from './LeagueWallet';
import { LeagueQuote } from './LeagueQuote';
import style from '../league.module.scss';
import useWindowDimensions from 'hooks/useWindowDimensions';

function LeagueConnect() {
	const { isLaptopSmall } = useWindowDimensions();
	return (
		<Body>
			<Header />

			<Main className={style.lcmain}>
				<Container className="flex justify-between f-height">
					{!isLaptopSmall && <LeagueQuote />}
					<LeagueWallet />
				</Container>
			</Main>
		</Body>
	);
}

export default LeagueConnect;
