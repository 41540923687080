import React from 'react';
import { NftToken } from 'common/interfaces/nft.interface';
import { TokenCard } from 'components/ui/TokenCard';
import { Grid, GridItem } from 'components/layout/Grid';

interface TokensListProps {
	items: NftToken[];
	columns?: number;
}

export function TokensList({ columns = 3, items }: TokensListProps) {
	return (
		<Grid cols={columns} gap={[24, 24]}>
			{items.map((item) => (
				<GridItem key={item.tokenId}>
					<TokenCard
						name={item.name}
						image={item.image}
						multiplier={item.multiplier}
						totalSpent={item.totalSpent}
					/>
				</GridItem>
			))}
		</Grid>
	);
}
