import React from 'react';
import { useLoggedIn } from 'hooks/useLoggedIn';
import { Modal } from 'components/ui/Modal';
import { LoginForm } from 'components/forms/LoginForm';
import { Typography } from 'components/ui/Typography';
import { Link } from 'components/ui/Link';
import logoSrc from 'assets/images/logo-vertical.png';
import useWindowDimensions from 'hooks/useWindowDimensions';

import style from './login.module.scss';
import { useNavigate } from 'react-router-dom';

export function LogInModal() {
	const { redirectTo, state } = useLoggedIn();
	const { isMobile } = useWindowDimensions();
	const navigate = useNavigate();

	return (
		<Modal
			className="p-40 width-popupDesktop"
			titleClassName={`ptb-24 m-0 ${style.title}`}
			title="Log In"
			isOpen
			forRoute
			onClose={() => navigate(-1)}
		>
			{isMobile && (
				<div className={style.logo}>
					<img src={logoSrc} alt="logo" />
				</div>
			)}
			<LoginForm />
			<Typography variant="p2" textAlign="center">
				<Typography className="pr-8" variant="p2" component="span">
					I don’t have an account.
				</Typography>

				<Link
					underline="always"
					color="desktop"
					variant="p2"
					href="/sign-up"
					state={{ ...state, from: redirectTo }}
				>
					Sign Up
				</Link>
			</Typography>
		</Modal>
	);
}
