import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { CheckCircleIcon } from 'components/ui/Icon';
import {
	passwordRules,
	PasswordRule,
	PasswordChecklistProps
} from './PasswordChecklist.model';
import style from './password-checklist.module.scss';

export function PasswordChecklist({ value, onChange }: PasswordChecklistProps) {
	const [validRules, setValidRules] = useState<Record<PasswordRule, boolean>>({
		longEnough: false,
		hasNumber: false,
		hasSymbol: false
	});

	const validateValue = () => {
		if (typeof value !== 'string') return;

		const result: typeof validRules = { ...validRules };

		result.hasNumber = /\d/.test(value);
		result.hasSymbol = /^.*[!@#$%^&*()_+\-=\]{};':"\\|,.<>?].*$/.test(value);
		result.longEnough = value.length >= 8;

		const isValid = result.hasNumber && result.hasSymbol && result.longEnough;
		setValidRules(result);

		if (onChange) onChange(isValid);
	};

	useEffect(() => {
		validateValue();
	}, [value]);
	return (
		<div className={style.root}>
			{Object.entries(validRules).map(([name, status]) => (
				<div
					className={classNames(status ? style.valid : style.invalid)}
					key={name}
				>
					{status && (
						<CheckCircleIcon
							className="mr-8"
							colorClass="States-Success_01"
						/>
					)}
					<span>{passwordRules[name as PasswordRule]}</span>
				</div>
			))}
		</div>
	);
}
