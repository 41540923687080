import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { customBaseQuery } from 'services/http.service';

interface AvailabilityResponse {
	time: number;
}

interface PrizeResponse {
	prize: number;
}

const WHEEL_ENDPOINT = '/wheel';

export const wheelApi = createApi({
	reducerPath: 'wheelApi',
	baseQuery: customBaseQuery,
	tagTypes: ['Wheel'],
	endpoints: (build) => ({
		getAvailability: build.query<AvailabilityResponse, void>({
			query: () => ({
				url: `${WHEEL_ENDPOINT}/available`,
				method: 'GET'
			}),
			providesTags: ['Wheel'],
			transformResponse: (data: AvailabilityResponse): any => {
				return data;
			}
		}),
		spin: build.mutation<number, void>({
			query: () => ({
				url: `${WHEEL_ENDPOINT}/spin`,
				method: 'POST'
			}),
			transformResponse: (data: PrizeResponse): any => {
				return data.prize;
			}
		})
	})
});

export const { useGetAvailabilityQuery, useSpinMutation } = wheelApi;
