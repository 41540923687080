import global from './types/globals';

import React from 'react';
import ReactDOM from 'react-dom/client';
import NiceModal from '@ebay/nice-modal-react';
import { Web3ReactProvider } from '@web3-react/core';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from 'store';
import { getLibrary } from 'utils/web3';
import httpService from 'services/http.service';
import WalletProvider from 'components/wallet/WalletProvider';
import reportWebVitals from './reportWebVitals';
import App from './App';

httpService.setupInterceptors(store);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<ReduxProvider store={store}>
			<BrowserRouter>
				<Web3ReactProvider getLibrary={getLibrary}>
					<WalletProvider>
						<GoogleReCaptchaProvider
							reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string}
						>
							<NiceModal.Provider>
								<App />
							</NiceModal.Provider>
						</GoogleReCaptchaProvider>
					</WalletProvider>
				</Web3ReactProvider>
			</BrowserRouter>
		</ReduxProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
