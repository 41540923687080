import React from 'react';
import { useGetLeaderboardUsersQuery } from 'services/nft.service';
import { Avatar } from 'components/ui/Avatar';
import { Typography } from 'components/ui/Typography';
import cn from 'classnames';

import style from './leaderboard.module.scss';

//icons
import { ReactComponent as CrownGold } from 'assets/images/icons/crownGold.svg';
import { ReactComponent as CrownSilver } from 'assets/images/icons/crownSilver.svg';
import { ReactComponent as CrownBronze } from 'assets/images/icons/crownBronze.svg';
import { ReactComponent as Trophy } from 'assets/images/icons/trophy.svg';

enum PrizePlaces {
	Gold,
	Silver,
	Bronze
}

interface LeaderboardUserProps {
	name: string;
	avatar: string;
	points: number;
	rank: number;
	money: number;
	index?: number;
}

const LeaderboardUser = ({
	name,
	avatar,
	points,
	rank,
	money,
	index
}: LeaderboardUserProps) => {
	let iconCrown!: React.ReactElement<React.ReactSVG>;
	switch (index) {
		case PrizePlaces.Gold:
			iconCrown = <CrownGold />;
			break;
		case PrizePlaces.Silver:
			iconCrown = <CrownSilver />;
			break;
		case PrizePlaces.Bronze:
			iconCrown = <CrownBronze />;
			break;
		default:
			break;
	}
	return (
		<div className={style.user}>
			<Typography className="m-0">{rank}</Typography>
			<div className="mt-4">{iconCrown}</div>
			<Avatar alt={name} className={style.user_avatar} src={avatar} />
			<Typography
				component="span"
				variant="body1"
				color="primary"
				className={style.user_points}
			>
				{points.toFixed(2)}
			</Typography>
			<Typography variant="p2" weight="bold" className="m-0">
				{name}
			</Typography>
			<Typography
				color="states-info-info"
				weight="semi-bold"
				className="m-0 mt-8 flex items-center"
			>
				<Trophy className="mr-4" />${money.toFixed(2)}
			</Typography>
		</div>
	);
};

export function MainLeaderboard() {
	const { data } = useGetLeaderboardUsersQuery({ page: 1, period: 365 });

	return (
		<div className={style.leaderboard_main}>
			<Typography component="h4" variant="h4" weight="bold" className="m-0">
				Leaderboard
			</Typography>
			<Typography
				component="h2"
				variant="big"
				weight="bold"
				color="neutral_03"
				className={cn('m-0', style.inside_title)}
			>
				THE LEAGUE TOP 10
			</Typography>

			<div className={cn('mt-32', style.leaderboard_main_users)}>
				{(data?.items || []).map((item, idx) => (
					<LeaderboardUser
						key={item.name}
						name={item.name}
						avatar={item.avatar}
						points={item.totalPoints}
						money={item.totalPrize}
						rank={item.leagueRank}
						index={idx}
					/>
				))}
			</div>
		</div>
	);
}
