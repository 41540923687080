import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from 'components/ui/Modal';
import { Typography } from 'components/ui/Typography';
import { Button } from 'components/ui/Button';

export const ResetSuccessModal = NiceModal.create(() => {
	const modal = useModal();

	return (
		<Modal
			className="p-40 width-popupDesktop"
			titleClassName="ptb-24 m-0"
			isOpen={modal.visible}
			onClose={modal.hide}
		>
			<i>🎉</i>

			<Typography variant="h5">Password Saved</Typography>
			<Typography variant="p2">
				You can manage it within your Profile tab.
			</Typography>

			<Button fullWidth={true} className="mt32" href="/login">
				Log In
			</Button>
		</Modal>
	);
});
