import React, { useMemo } from 'react';
import { useGetCompletedContestsQuery } from 'services/contest.service';
import { ContestsList } from '../ContestsList';

type Props = {
	isNft?: boolean;
};

export default function CompletedContests({ isNft }: Props) {
	const { data } = useGetCompletedContestsQuery();
	const items = useMemo(() => {
		const contests = data || [];
		return isNft ? contests.filter((contest) => contest.isNFT) : contests;
	}, [data, isNft]);
	return (
		<>
			<ContestsList items={items} />
		</>
	);
}
