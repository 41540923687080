import React from 'react';
import OtpInput, { OtpInputProps } from 'react-otp-input';
import classNames from 'classnames';
import style from './input.module.scss';

interface InputOTPProps extends OtpInputProps {}

export function InputOtp(props: InputOTPProps) {
	return (
		<OtpInput
			className={classNames('mb-24', style.telInput)}
			containerStyle={{
				display: 'grid',
				gridTemplateColumns: 'repeat(6, 1fr)',
				gap: '0 8px'
			}}
			{...props}
		/>
	);
}
