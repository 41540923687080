import React from 'react';
import classNames from 'classnames';

import { Icon } from './Icon';
import { ExternalIconProps } from './Icon.model';
import { ReactComponent } from 'assets/images/icons/arrow-right.svg';
import style from './icons.module.scss';

interface ArrowRightProps extends ExternalIconProps {
	fillClass?:
		| 'default-text'
		| 'desktop'
		| 'default'
		| 'neutral-black'
		| 'primary-mobile';
	rotate?: boolean;
}

export function ArrowRightIcon({
	className = 'none',
	fillClass,
	rotate = false,
	...props
}: ArrowRightProps) {
	return (
		<Icon
			{...props}
			className={classNames(
				fillClass && style[`fill-${fillClass}`],
				rotate && style.rotate,
				className,
				style['arrow-right']
			)}
			component={ReactComponent}
		/>
	);
}
