import React from 'react';
import styled from 'styled-components';

interface GridProps {
	className?: string;
	cols?: number;
	gap?: [number, number];
	width?: number;
	centered?: boolean;
	layout?: string[];
	customProps?: object;
	alignItems?: string;
}

const generateGridStyle = (
	cols: number = 3,
	gap: [number, number] = [5, 5],
	width: number = 100,
	centered: boolean = false,
	layout: [...any[]] = [],
	alignItems: string | false = false,
	customProps: object = {}
) => {
	let styleObj: any = {
		display: 'grid',
		gridGap: `${gap[0]}px ${gap[1]}px`,
		width: `${width}%`,
		gridTemplateColumns: `repeat(${cols}, 1fr)`
	};

	if (layout) {
		if (layout.length == 0) {
			styleObj.gridTemplateAreas = 'none';
		} else {
			styleObj.gridTemplateAreas = layout.reduce((property, item) => {
				property += `"${item}"`;
				return property;
			}, '');
		}
	}

	if (customProps) {
		styleObj = Object.assign(styleObj, customProps);
	}

	if (alignItems) {
		styleObj.alignItems = alignItems;
	}

	if (centered) {
		styleObj.margin = 'auto';
	}

	return styleObj;
};

const StyledGridComponent = styled.div<{ customStyles: object }>`
	${({ customStyles }) => ({ ...customStyles })}
`;
export const Grid = ({
	className = '',
	children,
	...props
}: React.PropsWithChildren<GridProps>) => {
	const style = generateGridStyle(
		props?.cols,
		props?.gap,
		props?.width,
		props?.centered,
		props?.layout,
		props?.alignItems,
		props?.customProps
	);
	return (
		<StyledGridComponent className={className} customStyles={style}>
			{children}
		</StyledGridComponent>
	);
};
