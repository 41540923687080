import { Grid, GridItem, Container } from 'components/layout/Grid';
import { Typography } from 'components/ui/Typography';
import useWindowDimensions from 'hooks/useWindowDimensions';
import {
	IPhoneFeature,
	WinningPhoneFeature,
	TableFeature,
	BoostsFeature
} from './Features/index';
import style from './style.module.scss';

import { ArrowRightIcon } from 'components/ui/Icon';
import { Button } from 'components/ui/Button';
import classNames from 'classnames';
import hbService, { LOCATION_TYPE } from '../../services/hubspot.service';

interface FeaturesProps {
	button?: JSX.Element;
}

const defaultButton = (
	<Button
		fullWidth
		size="txl"
		variant="presale"
		disableShadow
		href="/sale/membership"
		state={{
			from: '/sale/membership'
		}}
		onClick={() => {
			hbService.hbRegisterClick(LOCATION_TYPE.content);
		}}
		endIcon={<ArrowRightIcon size={18} fillClass="neutral-black" />}
	>
		JOIN NOW
	</Button>
);

export const Features = ({ button = defaultButton }: FeaturesProps) => {
	const { height, isLaptop, isTable } = useWindowDimensions();
	return (
		<div className="features">
			<Container centered={true} className={style.features}>
				<Grid
					cols={1}
					width={100}
					gap={isLaptop && !isTable ? [140, 0] : isTable ? [80, 0] : [220, 0]}
					layout={['2', '1']}
				>
					<Grid
						gap={[32, 32]}
						width={100}
						customProps={{
							gridTemplateColumns: `${
								isLaptop && !isTable
									? '1.1fr 0.7fr'
									: isTable
									? '1fr'
									: '1fr 0.95fr'
							}`,
							minHeight: `${!isTable && '90vh'}`
						}}
					>
						<GridItem
							customProps={
								!isTable
									? {
											alignSelf: 'center',
											marginLeft: 'auto',
											position: 'sticky',
											top: height / 4 + 'px'
									  }
									: { textAlign: 'center' }
							}
						>
							<Typography
								component="h2"
								variant="h3"
								variant2Xl="h3"
								variantXl="h4"
								variantMd="h5"
								weight={isTable ? 'semi-bold' : 'bold'}
								textTransform="capitalize"
								textStyle="italic"
								className="m-0 mb-20"
							>
								FREE{' '}
								<Typography
									component="span"
									color="primary-mobile"
									weight={isTable ? 'semi-bold' : 'bold'}
								>
									Weekly Contests
								</Typography>
							</Typography>
							<Typography
								component="p"
								variant="p1"
								variantXl="p1"
								variantMd="p1"
								className="mt-0"
								weight={isTable ? 'regular' : 'semi-bold'}
							>
								Every week, members will get FREE entry into an exclusive
								LEAGUE contest to compete for hundreds in cash prizes.
							</Typography>
							<Typography
								component="p"
								variantXl="p1"
								variantMd="p1"
								variant="p1"
								className="m-0"
								weight={isTable ? 'regular' : 'semi-bold'}
							>
								The prize pool starts at $500 per week, and will increase
								as more members join the LEAGUE!
							</Typography>
						</GridItem>
						<GridItem
							customProps={{
								alignSelf: 'center',
								order: isTable ? -1 : 1
							}}
						>
							<Grid
								cols={1}
								gap={[0, 20]}
								width={isTable ? 65 : 90}
								customProps={
									!isTable
										? {
												alignItems: 'center',
												marginLeft: 'auto'
										  }
										: {
												margin: '0px auto',
												position: 'relative',
												left: '10%'
										  }
								}
							>
								<GridItem>
									<IPhoneFeature />
								</GridItem>
							</Grid>
						</GridItem>
						{isTable && (
							<>
								{button}
								<Typography
									component="div"
									className={classNames(
										style.features_divider,
										'mt-80'
									)}
								></Typography>
							</>
						)}
					</Grid>
					<Grid
						cols={2}
						width={100}
						gap={[32, 32]}
						customProps={{
							alignItems: 'center',
							gridTemplateColumns: `${
								isLaptop && !isTable
									? '0.7fr 1.1fr '
									: isTable
									? '1fr'
									: '0.9fr 1.1fr '
							}`,
							minHeight: `${!isTable && '90vh'}`
						}}
					>
						<GridItem>
							<WinningPhoneFeature />
						</GridItem>
						<GridItem
							customProps={
								!isTable
									? {
											alignSelf: 'center',
											marginLeft: 'auto',
											position: 'sticky',
											top: height / 4 + 'px'
									  }
									: { textAlign: 'center' }
							}
						>
							<Typography
								component="h2"
								variant="h3"
								variant2Xl="h3"
								variantXl="h4"
								variantMd="h5"
								weight={isTable ? 'semi-bold' : 'bold'}
								textTransform="capitalize"
								textStyle="italic"
								className="m-0 mb-20"
							>
								WIN{' '}
								<Typography
									component="span"
									color="primary-mobile"
									weight={isTable ? 'semi-bold' : 'bold'}
								>
									Sports Experience Packages
								</Typography>
							</Typography>
							<Typography
								component="p"
								variant="p1"
								variantXl="p1"
								variantMd="p1"
								className="mt-0"
								weight={isTable ? 'regular' : 'semi-bold'}
							>
								For every 100 LEAGUE memberships sold, we'll draw one
								winner for an exclusive Sports Experience Package. The
								lucky winner will win a trip for 2 to see their favourite
								pro sports team play live in any city in North America.
							</Typography>
							<Typography
								component="p"
								variant="p1"
								variantXl="p1"
								variantMd="p1"
								className="m-0"
								weight={isTable ? 'regular' : 'semi-bold'}
							>
								Your membership will provide you with one raffle entry
								into every draw FOR LIFE!
							</Typography>
						</GridItem>
						{isTable && (
							<>
								{button}
								<Typography
									component="div"
									className={classNames(
										style.features_divider,
										'mt-80'
									)}
								></Typography>
							</>
						)}
					</Grid>
					<Grid
						cols={2}
						gap={[32, 32]}
						width={100}
						customProps={{
							alignItems: 'center',
							gridTemplateColumns: `${
								isLaptop && !isTable
									? '1.1fr 0.8fr'
									: isTable
									? '1fr'
									: '1fr 0.95fr'
							}`,
							minHeight: `${!isTable && '90vh'}`
						}}
					>
						<GridItem
							customProps={
								!isTable
									? {
											alignSelf: 'center',
											marginLeft: 'auto',
											position: 'sticky',
											top: height / 4 + 'px'
									  }
									: { textAlign: 'center' }
							}
						>
							<Typography
								component="h2"
								variant="h2"
								variant2Xl="h3"
								variantXl="h4"
								variantMd="h5"
								weight={isTable ? 'semi-bold' : 'bold'}
								textTransform="capitalize"
								textStyle="italic"
								className="m-0 mb-20"
							>
								Cash Prize{' '}
								<Typography
									component="span"
									color="primary-mobile"
									weight={isTable ? 'semi-bold' : 'bold'}
								>
									Multiplier
								</Typography>
							</Typography>
							<Typography
								component="p"
								variant="p1"
								variantXl="p1"
								variantMd="p1"
								className="mt-0"
								weight={isTable ? 'regular' : 'semi-bold'}
							>
								Increase the value of your Membership through the Prize
								Multiplier. For every $100 spent on regular Bounty
								contesting, LEAGUE members will have their LEAGUE winning
								prize amounts multiplied in all Weekly LEAGUE contests
								thereafter.
							</Typography>
							<Typography
								component="p"
								variant="p1"
								variantXl="p1"
								variantMd="p1"
								className="m-0"
								weight={isTable ? 'regular' : 'semi-bold'}
							>
								This allows LEAGUE members to grow the value of their
								investment by increasing their payouts; PLAY more, WIN
								more.
							</Typography>
						</GridItem>
						<GridItem
							customProps={{
								margin: '0 auto',
								width: '90%',
								order: isTable ? -1 : 1
							}}
						>
							<TableFeature />
						</GridItem>
						{isTable && (
							<>
								{button}
								<Typography
									component="div"
									className={classNames(
										style.features_divider,
										'mt-80'
									)}
								></Typography>
							</>
						)}
					</Grid>
					<Grid
						cols={2}
						gap={[32, 32]}
						width={100}
						customProps={{
							alignItems: 'center',
							gridTemplateColumns: `${
								isLaptop && !isTable
									? '0.7fr 1.1fr '
									: isTable
									? '1fr'
									: '0.9fr 1.1fr '
							}`,
							minHeight: `${!isTable && '90vh'}`
						}}
					>
						<GridItem
							customProps={
								isTable
									? {
											margin: '0 auto',
											width: '65%'
									  }
									: {}
							}
						>
							<BoostsFeature />
						</GridItem>
						<GridItem
							customProps={
								!isTable
									? {
											alignSelf: 'center',
											marginLeft: 'auto',
											position: 'sticky',
											top: height / 4 + 'px'
									  }
									: { textAlign: 'center' }
							}
						>
							<Typography
								component="h2"
								variant="h3"
								variant2Xl="h3"
								variantXl="h3"
								variantMd="h5"
								weight={isTable ? 'semi-bold' : 'bold'}
								textTransform="capitalize"
								textStyle="italic"
								color="primary-mobile"
								className="m-0 mb-20"
							>
								<Typography
									component="span"
									color="primary"
									weight={isTable ? 'semi-bold' : 'bold'}
								>
									Leaderboards, Balance Boosts,
								</Typography>{' '}
								{!isLaptop && <br />}
								<Typography
									component="span"
									color="primary"
									weight="bold"
								>
									and more
								</Typography>
							</Typography>
							<Typography
								component="p"
								variant="p1"
								variantXl="p1"
								variantMd="p1"
								className="m-0"
								weight={isTable ? 'regular' : 'semi-bold'}
							>
								LEAGUE members will get access to exclusive content,
								private Discord channels, and new Bounty contests only
								available to members
							</Typography>
						</GridItem>
						{isTable && button}
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};
