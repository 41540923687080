import React from 'react';
import Select, { Options, components, DropdownIndicatorProps } from 'react-select';
import { Typography } from 'components/ui/Typography';
import styled from 'styled-components';
import style from './leaderboard.module.scss';
import { DownArrowIcon } from 'components/ui/Icon';

interface Option {
	label: string;
	value: number;
}

export const periods: Options<Option> = [
	{
		label: 'This Year',
		value: 365
	},
	{
		label: 'This Month',
		value: 30
	},
	{
		label: 'This Week',
		value: 7
	},
	{
		label: 'Last Week',
		value: 1
	}
];

interface PeriodSelectProps {
	value: Option;
	onSelect: (value: any) => any;
}

const ReactSelectElement = styled(Select)`
	.react-select__control {
		margin-left: 5px;
		cursor: pointer;
		display: flex;
	}
	.react-select__value-container {
		padding: 0;
	}
	.react-select__indicator {
		padding: 0;
	}
	.react-select__single-value {
		color: var(--color-primary-mobile);
		text-decoration: underline;
	}
	.react-select__indicator-separator {
		display: none;
	}
	.react-select__menu {
		overflow: hidden;
		width: auto;
		background-color: var(--color-neutral_03);

		left: 50%;
		transform: translateX(-50%);
	}
	.react-select__option {
		padding: 8px 16px;
		white-space: nowrap;
		color: var(--color-default);

		cursor: pointer;
		transition: 0.2s linear;
		&--is {
			&-focused {
				background-color: var(--color-neutral_04);
			}
			&-selected {
				background-color: var(--color-neutral_05);
			}
		}
	}
	.react-select__menu-list {
		padding: 0;
	}
`;
const customStyles = {
	control: () => ({})
};

const DropdownIndicator = (props: DropdownIndicatorProps) => {
	return (
		<components.DropdownIndicator {...props}>
			<DownArrowIcon color="#31CF8E" size={16} />
		</components.DropdownIndicator>
	);
};

export function PeriodSelect({ value, onSelect }: PeriodSelectProps) {
	return (
		<div className={style.select}>
			<Typography color="states-info-info" className="m-0">
				Leaders of
			</Typography>
			<ReactSelectElement
				components={{ DropdownIndicator }}
				styles={customStyles}
				isSearchable={false}
				classNamePrefix="react-select"
				value={value}
				options={periods}
				onChange={onSelect}
			/>
		</div>
	);
}
