import React from 'react';
import classNames from 'classnames';

import { Icon } from './Icon';
import { ExternalIconProps } from './Icon.model';
import { ReactComponent } from 'assets/images/icons/question.svg';
import style from './icons.module.scss';

interface QuestionProps extends ExternalIconProps {
	active?: boolean;
}

export function QuestionIcon({
	className = 'none',
	active = false,
	...props
}: QuestionProps) {
	return (
		<div
			className={classNames(
				active && style.question_active,
				className,
				style.question
			)}
		>
			<Icon {...props} component={ReactComponent} />
		</div>
	);
}
