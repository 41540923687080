import React from 'react';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { quickRegisterUser } from 'store/user/user.actions';
import { selectUserError } from 'store/user/user.slice';
import { FormAlert } from 'components/ui/FormAlert';
import { InputGroup, Input, InputPassword, Empty } from 'components/ui/Input';
import { Button } from 'components/ui/Button';
import { PasswordChecklist } from 'components/ui/PasswordChecklist';
import { Typography } from 'components/ui/Typography';
import { Checkbox } from 'components/ui/Checkbox';
import { Link } from 'components/ui/Link';
import { FormTip } from 'components/ui/FormTip';
import { DatePicker } from 'components/ui/DatePicker';

import style from './register-form.module.scss';
import { useLoggedIn } from '../../../hooks/useLoggedIn';
import { useNavigate } from 'react-router-dom';

const initialValues = {
	email: '',
	username: '',
	password: '',
	firstName: '',
	lastName: '',
	birthdate: '',
	agreeRules: false
};
const validationSchema = Yup.object({
	email: Yup.string().required().email(),
	username: Yup.string().required(),
	password: Yup.string()
		.required()
		.min(8)
		.test('passwordRequirements', 'Information', (value) =>
			[/\d/, /^.*[!@#$%^&*()_+\-=\]{};':"\\|,.<>?].*$/].every((pattern) =>
				pattern.test(value || '')
			)
		),
	firstName: Yup.string().required(),
	lastName: Yup.string().required(),
	birthdate: Yup.string().required(),
	agreeRules: Yup.boolean().oneOf([true], 'Checkbox selection is required').required()
});

type Props = {
	data: {
		phone: string;
		referral?: string;
	};
};

export function QuickRegisterForm({ data }: Props) {
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		control,
		formState: { errors, isSubmitting }
	} = useForm<typeof initialValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: initialValues,
		mode: 'onTouched'
	});

	const dispatch = useAppDispatch();
	const error = useAppSelector(selectUserError);
	const watchPassword = watch('password');
	const { redirectTo, state } = useLoggedIn();
	console.log('redirectTo:', redirectTo);

	const onSubmit = (values: typeof initialValues) => {
		const { firstName, lastName, agreeRules, ...user } = values;
		if (data?.phone) {
			dispatch(
				quickRegisterUser({
					mobileNo: data.phone,
					referByCode: data.referral,
					fname: firstName,
					lname: lastName,
					agree_rules: agreeRules,
					...user
				})
			);
		}
	};

	return (
		<>
			<form className={style.form} onSubmit={handleSubmit(onSubmit)}>
				<InputGroup className="mb-24">
					<Input
						{...register('firstName')}
						label="Your first name"
						error={!!errors.firstName}
						helperText={errors.firstName?.message}
						placeholder="Your first name"
						fullWidth
					/>
					<Input
						{...register('lastName')}
						label="Last name"
						error={!!errors.lastName}
						helperText={errors.lastName?.message}
						placeholder="Your last name"
						fullWidth
					/>
				</InputGroup>

				<InputGroup className="mb-24">
					<Input
						{...register('email')}
						label="Email"
						error={!!errors.email}
						helperText={errors.email?.message}
						fullWidth={true}
						placeholder="Type your email"
					/>
					<Input
						{...register('username')}
						label="Username"
						error={!!errors.username}
						helperText={errors.username?.message}
						fullWidth={true}
						placeholder="Choose a username"
					/>
				</InputGroup>
				<InputGroup className="mb-48">
					<InputPassword
						{...register('password')}
						label="Password"
						error={!!errors.password}
						fullWidth={true}
						className="mb-16"
						placeholder="Your password"
					/>
					<Empty />
					<PasswordChecklist value={watchPassword} />
					<Empty />
				</InputGroup>

				<InputGroup className="mb-48">
					<DatePicker
						{...register('birthdate')}
						onChange={(date) => {
							setValue('birthdate', date.toISOString(), {
								shouldValidate: true
							});
						}}
						label="Birthdate"
						error={!!errors.birthdate}
						helperText={errors.birthdate?.message}
						fullWidth={true}
					/>
					<Empty />
					<FormTip text="To sign up, you need to be at least 18. Other users won't see your birthdate." />
				</InputGroup>

				<div>
					<label htmlFor="agreeRules" className={style.checkbox}>
						<Controller
							control={control}
							name="agreeRules"
							render={({ field: { value, ...field } }) => (
								<Checkbox
									{...field}
									checked={value}
									id="agreeRules"
									onCheckedChange={(status: boolean) =>
										setValue(field.name, status)
									}
								/>
							)}
						/>

						<Typography variant="body1" className="pl-16">
							I have read the rules and I agree to the
							<Link className="plr-8" underline="always" href="/terms">
								Terms & Conditions
							</Link>
							and{' '}
							<Link className="plr-8" underline="always" href="/privacy">
								policy
							</Link>{' '}
							of Bounty Sports
						</Typography>
					</label>
				</div>

				<Button
					className="plr-160 mt-32 md:plr-30"
					type="submit"
					color={state?.from?.includes('london') ? 'default' : 'primary'}
					disabled={isSubmitting}
				>
					Sign Up & Play
				</Button>
			</form>

			{error && <FormAlert type="error">{error}</FormAlert>}
		</>
	);
}
