import style from './style.module.scss';
import { Container, Grid, GridItem } from '../../components/layout/Grid';
import { Typography } from '../../components/ui/Typography';

import imageSrc from 'assets/images/Jamie.png';

import classNames from 'classnames';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export const LukeBannerFeature = () => {
	const { isMobile } = useWindowDimensions();

	return (
		<div className={classNames(style.luke_feature, isMobile ? 'mb-40' : 'mb-160')}>
			<Container centered={true}>
				<div className={style.luke_feature_wrapper}>
					<div className={classNames('ptb-56', style.luke_feature_content)}>
						<Typography
							className={classNames('m-0')}
							component="h2"
							variant="h3"
							weight="bold"
							textTransform="capitalize"
							textAlign={isMobile ? 'center' : ''}
						>
							Official partner of{' '}
							<Typography component="span" color="desktop" weight="bold">
								Luke Rockhold{' '}
							</Typography>
						</Typography>
						<Typography
							className={classNames('m-0')}
							textAlign={isMobile ? 'center' : ''}
						>
							Former UFC Middleweight and Light Heavyweight Champion{' '}
						</Typography>
						<div className={style._partner}>
							<Typography
								className="mlr-12"
								variant="p1"
								weight="bold"
								color="inherit"
								textAlign={isMobile ? 'center' : ''}
							>
								THE OFFICIAL PARTNER OF BOUNTY SPORTS
							</Typography>
						</div>
					</div>
					<div className={style.luke_feature_image}>
						<img src={imageSrc} alt="luke rockhold" />
					</div>
				</div>
			</Container>
		</div>
	);
};
