import React from 'react';
import { useGetTokensQuery } from 'services/nft.service';
import { TokensList } from '../Tokens/List';

import classNames from 'classnames';
import style from './dashboard.module.scss';
import { Typography } from '../../../components/ui/Typography';
import { NavLink } from 'react-router-dom';

export const DashboardTokens = () => {
	const { data } = useGetTokensQuery();
	return (
		<div className={classNames(style.tokens, 'pr-40')}>
			<div className={classNames(style.tokens_title, 'mb-24')}>
				<Typography className="m-0" variant="h5" weight="bold">
					My Tokens
				</Typography>

				<NavLink to={'/league/tokens'}>
					<Typography component="a" variant="p2" color="league">
						View all Tokens
					</Typography>
				</NavLink>
			</div>
			<TokensList columns={1} items={data?.length ? [data[0]] : []} />
		</div>
	);
};
