import IPhone from 'assets/images/reviews/review1-iphone.png';
import Contest from 'assets/images/reviews/review1-contest.png';

import { ParallaxModule, DependentImage, BaseImage } from 'components/ui/ParallaxModule';

export const IPhoneFeature = () => {
	const dependentImage = (
		<DependentImage
			src={Contest}
			alt="Contest example"
			width="100%"
			centered="both"
			topOffset="45%"
		/>
	);
	const baseImage = (
		<BaseImage src={IPhone} alt="IPhone image" dependentImage={dependentImage} />
	);
	return (
		<div
			style={{
				position: 'relative'
			}}
		>
			<ParallaxModule baseImage={baseImage} width="80%" rotate="10deg" />
		</div>
	);
};
