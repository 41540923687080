import React from 'react';
import { useGetTokensQuery } from 'services/nft.service';
import { Typography } from 'components/ui/Typography';
import { TokensWallet } from './Wallet';
import { TokensList } from './List';
import { LeagueLayout } from 'pages/League/Layout/Layout';

import style from './tokens.module.scss';
import classNames from 'classnames';

export default function LeagueTokens() {
	const { data } = useGetTokensQuery();

	return (
		<LeagueLayout>
			<div className={classNames(style.header, 'mb-32')}>
				<Typography className="p-0 m-0" variant="h4" weight="bold">
					My Tokens
				</Typography>

				<TokensWallet />
			</div>
			<div className="pb-24">
				<TokensList items={data || []} />
			</div>
		</LeagueLayout>
	);
}
