import React from 'react';

import { BaseLayout } from 'components/layout/BaseLayout';
import { LeagueNavigation } from '../Navigation';
import { Theme } from 'components/layout/Body/Body';

import style from './league-layout.module.scss';
import classNames from 'classnames';

interface LeagueLayoutProps extends React.PropsWithChildren {
	theme?: Theme;
	mainClassName?: string;
}

export const LeagueLayout = ({ theme, mainClassName, children }: LeagueLayoutProps) => {
	return (
		<BaseLayout theme={theme} mainClassName={classNames(mainClassName, style.main)}>
			<LeagueNavigation />
			<div className={classNames(style.content, 'mt-48', 'plr-65')}>{children}</div>
		</BaseLayout>
	);
};
