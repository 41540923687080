import React, { useMemo } from 'react';
import { useAppSelector } from 'hooks/redux';
import { useSortable } from 'hooks/useSortable';
import { Contest } from 'common/interfaces/contest.interface';
import { ContestSortConfig, selectContestsSorting } from 'store/contest/contest.slice';
import { Sort } from 'components/ui/Sort';
import { ContestList } from 'components/ui/Contest';

interface ContestsListProps {
	items: Contest[];
	sport?: string;
	showSort?: boolean;
}

type SortControl = ContestSortConfig['field'];

const sortControls: Partial<Record<SortControl, string>> = {
	// sport: 'Sport',
	title: 'Contest Title',
	prize: 'Prize',
	totalEntries: 'Entries #',
	finishAt: 'End Date & Time',
	isLive: 'Starts in',
	entryFee: 'Entry Fee'
};

export function ContestsList({ sport, items, showSort = false }: ContestsListProps) {
	const storeSorting = useAppSelector(selectContestsSorting);

	const itemsBySport = useMemo(
		() => (sport ? items.filter((item) => item.sport === sport) : items),
		[items, sport]
	);
	const { items: sortedItems, requestSort } = useSortable<Contest>(
		itemsBySport,
		showSort ? storeSorting : undefined
	);

	return (
		<>
			{showSort && (
				<Sort
					className="mt-26 mb-12"
					controls={sortControls}
					onSort={requestSort}
				/>
			)}
			<ContestList items={sortedItems} />
		</>
	);
}
