import React from 'react';
import { routes } from 'routes';
import { useGeolocation } from 'hooks/useGeolocation';
import { useMobileDevice } from 'hooks/useMobileDevice';
import 'swiper/css';
import 'swiper/css/navigation';
import 'assets/styles/main.scss';
import { TransitionRouter } from 'components/layout/Transition';

import { useGTM } from 'hooks/useGTM';

function App() {
	const { init: gtmInit } = useGTM();

	gtmInit();

	useGeolocation();
	useMobileDevice();

	return <TransitionRouter routes={routes} />;
}

export default App;
