import React from 'react';
import classNames from 'classnames';
import { Root as TabsRoot, TabsProps } from '@radix-ui/react-tabs';
import style from './tabs.module.scss';

type Props = {
	children: React.ReactNode;
	variant?: 'default' | 'outlined' | 'outlinedbg' | 'minimal';
	centered?: boolean;
} & TabsProps;

export function Tabs({
	children,
	variant = 'default',
	centered = false,
	...props
}: Props) {
	return (
		<TabsRoot
			{...props}
			className={classNames(style.container, style[variant], style.centered)}
		>
			{children}
		</TabsRoot>
	);
}
