import React, { useMemo, useState, useCallback } from 'react';
import { selectUserData } from 'store/user/user.slice';
import { useAppSelector } from 'hooks/redux';
import { Modal } from 'components/ui/Modal';
import { Button } from 'components/ui/Button';
import { PaymentForm } from 'components/forms/PaymentForm';
import { PurchaseInfo } from './PurchaseInfo';
import useWindowDimensions from 'hooks/useWindowDimensions';
import classNames from 'classnames';
import style from './purchase.module.scss';

interface PaymentModalProps {
	name: string;
	price: number;
	onConfirm: () => any;
	onError: () => any;
	onClose?: () => any;
}

export function PurchaseModal({ price, name, onConfirm, onError, onClose }: PaymentModalProps) {
	const user = useAppSelector(selectUserData);
	const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
	const [showForm, setShowForm] = useState<boolean>(false);
	const { isMobile } = useWindowDimensions();

	const balanceAfterTx = useMemo(() => {
		const balance = user?.balance || 0;
		return Math.round((balance - price) * 100) / 100;
	}, [user, price]);

	const handleConfirmation = useCallback(() => {
		setIsConfirmed(true);
		onConfirm();
	}, [onConfirm, setIsConfirmed]);

	return (
		<Modal
			title="Payment Confirmation"
			className={classNames('p-40 width-popupDesktop', style.modal)}
			titleClassName="ptb-24 m-0"
			isOpen
			forRoute
			onClose={onClose}
		>
			{showForm ? (
				<PaymentForm
					price={Math.abs(balanceAfterTx)}
					onSuccess={onConfirm}
					onError={onError}
				/>
			) : (
				<div className={style.wrapper}>
					<PurchaseInfo
						balance={user?.balance || 0}
						futureBalance={balanceAfterTx}
						product={{ name, price }}
					/>

					{balanceAfterTx >= 0 ? (
						<Button
							color={isMobile ? 'default' : 'primary'}
							disabled={isConfirmed}
							onClick={handleConfirmation}
							fullWidth={isMobile}
						>
							Purchase
						</Button>
					) : (
						<Button
							color={isMobile ? 'default' : 'primary'}
							fullWidth={isMobile}
							onClick={() => {
								setShowForm(true);
							}}
							onTouchStart={() => setShowForm(true)}
						>
							Checkout
						</Button>
					)}
				</div>
			)}
		</Modal>
	);
}
