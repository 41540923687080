import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from 'components/ui/Modal';
import style from './alert.module.scss';

import errorImage from 'assets/images/error_image.png';
import successImage from 'assets/images/success_icon.png';
import classNames from 'classnames';
import { Typography } from '../../ui/Typography';
import { Button } from '../../ui/Button';

interface AlertModalProps {
	success: boolean;
	heading: string;
	content: string;
	button?: boolean;
	onActionClose?: () => void;
}

export const AlertModal = NiceModal.create(
	({
		success = false,
		heading = '',
		content = '',
		button = true,
		onActionClose = () => {}
	}: AlertModalProps) => {
		const modal = useModal();

		return (
			<Modal
				className="p-40 width-popupDesktop"
				isOpen={modal.visible}
				onClose={() => {
					onActionClose();
					modal.hide();
				}}
			>
				<div className={classNames(style.image, 'align-center', 'mb-16')}>
					<img
						src={success ? successImage : errorImage}
						alt={success ? 'succeed transaction' : 'error during transaction'}
					/>
				</div>
				<div className={classNames('mt-16')}>
					<Typography textAlign="center" variant="h5">
						{heading}
					</Typography>
				</div>
				<div className={classNames('mt-16')}>
					<Typography textAlign="center" variant="h5">
						{content}
					</Typography>
				</div>
				{button && (
					<div className={classNames('mt-32')}>
						<Button
							fullWidth
							variant={success ? 'default' : 'neutral'}
							onClick={() => {
								onActionClose();
								modal.hide();
							}}
						>
							Close
						</Button>
					</div>
				)}
			</Modal>
		);
	}
);
