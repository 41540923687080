import React from 'react';
import cn from 'classnames';
import { Grid, GridItem } from 'components/layout/Grid';
import { Container } from 'components/layout/Grid';
import { Typography } from 'components/ui/Typography';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { DownArrowIcon } from 'components/ui/Icon';

import style from './banner.module.scss';

interface BannerLeagueProps {
	children?: React.ReactNode;
	dataBenefits?: Array<any>;
	scrollTo?: string;
	className?: string;
}
interface DataBenefitsProps {
	icon?: string;
	title?: string;
	subtitle?: string;
}

export const BannerLeague = ({
	children,
	dataBenefits,
	className,
	scrollTo
}: BannerLeagueProps) => {
	const { isDesktop, isLaptop, isTable } = useWindowDimensions();

	const scrollToBlock = (section: string) => {
		let exploreBlock = document.querySelector(section);
		exploreBlock?.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<div className={cn(style.banner, className ?? '')}>
			<Container>
				<Typography component="div" className={style.banner_title}>
					<Typography component="div" textStyle="italic">
						Join
					</Typography>
					{!isTable && (
						<Typography
							component="div"
							weight="bold"
							textStyle="italic"
							color="primary-mobile"
						>
							the
						</Typography>
					)}
					<Typography
						component="div"
						weight="bold"
						textStyle="italic"
						color="primary-mobile"
					>
						{isTable && (
							<Typography component="span" weight="bold">
								the
							</Typography>
						)}
						league
					</Typography>
				</Typography>
				<Typography component="div" className={style.banner_line}>
					{!isLaptop ? (
						<Typography
							component="h5"
							variant="h6"
							className="m-0"
							textStyle="italic"
							color="states-info-info"
						>
							be part of a new era <br />
							in Daily Fantasy Sports
						</Typography>
					) : (
						<Typography
							component="h5"
							variant="h6"
							variantXl="p1"
							variantMd="p2"
							className="m-0"
							weight="semi-bold"
						>
							<Typography
								component="span"
								variant="h6"
								variantXl="p1"
								variantMd="p2"
								weight="semi-bold"
								color="primary-mobile"
							>
								FREE Lifetime Entry
							</Typography>{' '}
							into Weekly, Monthly, and Annual League contesting
						</Typography>
					)}
				</Typography>
				<div>
					{!isLaptop && dataBenefits && (
						<Grid
							cols={3}
							gap={[0, 24]}
							width={isDesktop ? 65 : 75}
							customProps={{
								marginLeft: 'auto',
								flexShrink: 0
							}}
						>
							{dataBenefits.map(
								({ icon, title, subtitle }: DataBenefitsProps) => (
									<GridItem className={cn(style.banefits_item)}>
										<Typography
											className={style.banefits_icon}
											component="span"
											weight="bold"
										>
											{icon}
										</Typography>
										<Typography
											color="states-info-info"
											className="m-0"
											weight="semi-bold"
											component="p"
											variant="p1"
										>
											{title}
										</Typography>
										<Typography
											className="m-0 mt-8"
											weight="bold"
											component="p"
											variant="p1"
										>
											{subtitle}
										</Typography>
									</GridItem>
								)
							)}
						</Grid>
					)}
					{children}
				</div>
			</Container>
			{scrollTo && (
				<Typography
					className={cn(style.banner_more)}
					component="div"
					color="primary"
				>
					<Typography
						component="span"
						weight="bold"
						onClick={() => scrollToBlock(scrollTo ?? '')}
					>
						{!isTable && 'See More'}
						<DownArrowIcon size={17} color="#fff" />
					</Typography>
				</Typography>
			)}
		</div>
	);
};
