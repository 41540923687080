import React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import style from './tooltip.module.scss';

interface TooltipContentProps extends TooltipPrimitive.TooltipContentProps {
	children: React.ReactNode;
}

export function TooltipContent({ children, side }: TooltipContentProps) {
	return (
		<TooltipPrimitive.Content side={side} className={style.content}>
			{children}
		</TooltipPrimitive.Content>
	);
}
