import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useGetTokensQuery, useGetMembershipQuery } from 'services/nft.service';

interface LeagueRouteProps {
	redirectPath?: string;
	component?: React.ComponentType;
	isConnected: boolean;
}

export const LeagueRoute = ({
	redirectPath = '/league/login',
	component: RouteComponent,
	isConnected = true
}: LeagueRouteProps) => {
	const { isSuccess: isSuccessTokens, data: tokens } = useGetTokensQuery();
	const { isSuccess: isSuccessMembership, data: membership } = useGetMembershipQuery();

	const fallbackComponent = RouteComponent ? <RouteComponent /> : <Outlet />;

	if (!isSuccessTokens || !isSuccessMembership) {
		return <></>;
	}
	
	if (tokens.length === 0 || !membership) {
		return <Navigate to={redirectPath} replace />;
	}else if(membership && membership.expired){
		return <Navigate to={'/league-renewal/expired'} replace />;
	}
	else if(tokens.length === 0 && membership){
		return <Navigate to={'/league/connect'} replace />;
	} else{
		if(membership && membership.expired){
			return <Navigate to={redirectPath} replace />;
		}else{
			return fallbackComponent;
		}
	}
};
