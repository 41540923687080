import React from 'react';
import { Body } from 'components/layout/Body';
import { Container } from 'components/layout/Grid';
import { RoundUpForm } from 'components/forms/Promotion/RoundUpForm';
import { Logo } from 'components/ui/Logo';

import style from './promotion.module.scss';

export function RoundUp() {
	return (
		<Body className={style.body}>
			<div className={style.logo}>
				<Logo />
			</div>
			<Container centered={true}>
				<div className={style.roundup}>
					<div className={style.form}>
						<RoundUpForm />
					</div>
				</div>
			</Container>
		</Body>
	);
}
