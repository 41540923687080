import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { Body } from 'components/layout/Body';
import { Header } from 'components/layout/Header';
import { Main } from 'components/layout/Main';
import { Banner } from './Banner';
import { Features } from './Features';
import { SaleMemberships } from './SaleMemberships';
import { GoUp } from './GoUp';

import style from './style.module.scss';

import useWindowDimensions from 'hooks/useWindowDimensions';
import React, { useEffect } from 'react';
import { useMobileDevice } from 'hooks/useMobileDevice';
import { useGetMembershipQuery } from "../../services/nft.service";

const Presale = () => {
	const { isSuccess, data: membership } = useGetMembershipQuery();
	const navigation = useNavigate();

	useEffect(() => {
		if(isSuccess && membership && !membership.expired && !membership.isExpiring){ //to-do
			navigation('/');
		}
	},[membership]);

	const { isLaptopSmall } = useWindowDimensions();
	const { isIOS } = useMobileDevice();
	useEffect(() => {
		if (isIOS) {
			try {
				window.location.href = 'bountysports://membership ';
			} catch (e) {
				// redirects to appstore is here
				console.log('Proceed to a website.');
			}
		}
	}, []);
	return (
		<Body theme="presale">
			<Header />
			<Main className={style.main}>
				<Banner />
				<Features />
				{!isLaptopSmall && <SaleMemberships />}
			</Main>
			<GoUp />
			<Outlet />
		</Body>
	);
};

export default Presale;
