import React from 'react';
import { Header } from 'components/layout/Header';
import { Body } from 'components/layout/Body';
import { Main } from 'components/layout/Main';
import { Container } from 'components/layout/Grid';
import { Typography } from 'components/ui/Typography';
import { Button } from 'components/ui/Button';

function InDevelopmentPage() {
	return (
		<Body>
			<Header />

			<Main>
				<Container centered className="ptb-52">
					<Typography variant="h5" weight="semi-bold">
						This page is in the development
					</Typography>
					<Typography variant="p3">
						We do our best to make it live as soon as possible
					</Typography>

					<Button href="/contests">
						Browse Contests <span className="pl-12">→</span>
					</Button>
				</Container>
			</Main>
		</Body>
	);
}

export default InDevelopmentPage;
