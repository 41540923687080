import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'components/ui/Modal';
import { Typography } from 'components/ui/Typography';
import {useModal } from "@ebay/nice-modal-react";
import style from './expire.membership.module.scss';
import {Button} from "../../ui/Button";
import { PurchaseModal } from '../Purchase';
import { AlertModal } from 'components/modals/Alert';
import paymentService from 'services/payment.service';
import { useGetProfileQuery } from 'services/user.service';
import { useGetMembershipQuery } from "../../../services/nft.service";

export const ExpireMembershipModal = () =>  {
	const navigate = useNavigate();
	const location = useLocation();
	const [remainingTime,setRemainingTime] = useState("");
	const alertModal = useModal(AlertModal);
	const { refetch } = useGetProfileQuery();
	let redirectTo = '/';
	if(location?.state?.redirectTo){
		redirectTo = location.state.redirectTo;
	}
	const { isSuccess: isSuccessMembership, data: membership } = useGetMembershipQuery();
	
	useEffect(()=>{
		if(membership){
			let remainingTime = "";
			if(membership.weeksRemaining>0){
			  remainingTime = `${membership.weeksRemaining} ${ membership.weeksRemaining === 1 ? 'week' : 'weeks'}`;
			}else{
			   const daysRemaining = Math.ceil( (membership.expiredAt - new Date().getTime()) / (1000 * 60 * 60 * 24));
			   remainingTime = `${daysRemaining} ${ daysRemaining === 1 ? 'day' : 'days'}`;
			}
			setRemainingTime(remainingTime)
			if(!membership.isExpiring){
				navigate('/');
			}
		}
	},[membership]);

	

	const purchaseModal = useModal(PurchaseModal,{
		name: 'Renew Membership',
		price: 199.99,
		onConfirm: function () {
			handleSuccess();
		},
		onError: function () {
			handleError();
		},
		onClose: function () {
			purchaseModal.remove();
		}
	});

	const handleSuccess = async () => {
		try {
			    alertModal.show({
				success: true,
				heading: 'We are processing your request.',
				content: '',
				button: false
			});

			await paymentService.renewMembership('');
			//refetch();
			purchaseModal.remove();
			await alertModal.show({
				success: true,
				heading: 'Membership Renewed!',
				content: 'Your LEAGUE Membership has been Extended automatically',
				onActionClose: () => {
					window.location.reload();
					//navigate('/');
				}
			});
		} catch (e: any) {
			navigate(redirectTo);

			await alertModal.show({
				success: false,
				heading: 'Purchase error',
				content: e.message
			});
		}
	};

	const handleError = async () => {
		navigate(redirectTo);
		alertModal.show({
			success: false,
			heading: 'Failed Transition',
			content: 'Please, try using different payment method\n or contact our support'
		});
	};

     const renewMembership =  ()=>{
		purchaseModal.show();
	 }

	 const handleNotNow = () =>{
		console.log("redirectTo ",redirectTo);
		navigate(redirectTo);
		localStorage.setItem('postponedPopup', String(new Date().getTime()));
		
	 }

	 const onClose = ()=>{
	 	navigate(redirectTo);
	 	localStorage.setItem('postponedPopup', String(new Date().getTime()));
	 }

	return (
		<Modal
			className="p-40 width-popupDesktop"
			titleClassName={`m-0 ${style.title}`}
			title=""
			forRoute
			onClose={onClose}
			isOpen
			leagueRenew
		>
		<Typography variant="p2" textAlign="center">
			<Typography className="pr-8" variant="p2" component="span">
			Your League membership is about the expire! You only have {remainingTime} left. Want to renew? It's cheapest to renew before it expires.
			</Typography>
		</Typography>
		<Typography textAlign="center" variant='h4'>
		Renewal Price: $199.99
		</Typography>
		<Typography textAlign="center">
			<Button onClick={()=>{renewMembership()}}>Renew For Year Two</Button>
		</Typography>

		<Typography variant='p1' textAlign="right">
			<Button variant='text' color='primary' size='sm' onClick={handleNotNow}><Typography variant='p1'>Not Now</Typography></Button>
		</Typography>

		</Modal>
	);
};

