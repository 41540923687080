import React, { ChangeEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormAlert } from 'components/ui/FormAlert';
import { Input } from 'components/ui/Input';
import { Button } from 'components/ui/Button';
import authService from 'services/auth.service';
import { useMobileDevice } from 'hooks/useMobileDevice';
import { useLoggedIn } from '../../../hooks/useLoggedIn';

const phoneRegExp =
	/^((\+1|1)?(-)?)?(\([2-9]\d{2}\)|[2-9]\d{2})(-)?([2-9]\d{2}(-)?\d{4})$/;

const initialValues = {
	phone: '',
	referral: ''
};
const validationSchema = Yup.object({
	phone: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
	referral: Yup.string().optional()
});

type Props = {
	onSuccess?: (data: typeof initialValues) => any;
};

export function SignUpForm({ onSuccess }: Props) {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isSubmitting }
	} = useForm<typeof initialValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: initialValues,
		mode: 'onTouched'
	});
	const { isIOS } = useMobileDevice();
	const [error, setError] = useState<string>();
	const [referralBlocked, setReferralBlocked] = useState<boolean>(false);
	let { code } = useParams<{ code?: string }>();

	const { redirectTo, state } = useLoggedIn();
	if (!code && state?.promo) {
		code = String(state.promo).toUpperCase();
	}

	useEffect(() => {
		if (!code) {
			reset({ referral: 'FREE5' });
			setReferralBlocked(false);
		} else {
			reset({ referral: String(code).toUpperCase() || '' });
			//setReferralBlocked(Boolean(code));
		}
	}, [code]);

	const onSubmit = async (values: typeof initialValues) => {
		try {
			await authService.checkPhoneAndPromo(values);
			if (onSuccess) onSuccess(values);
		} catch (e: any) {
			setError(e.message);
		}
	};

	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		let val = event.currentTarget.value;
		val = val.replace(/ /gm, '');
		if (val.length == 10) {
			let num = `1${val}`;
			/*  let num = `${val.substring(0, 3)}${val.substring(3, 6)}${val.substring(
				6,
				val.length
			)}`; */

			num = num.trim();
			//console.log(num);
			//reset({ phone: num || '' });
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Input
					{...register('phone')}
					label="Phone"
					error={!!errors.phone}
					helperText={errors.phone?.message}
					placeholder="Ex. 541-754-3010"
					className="mb-24"
					fullWidth={true}
					onChange={onChange}
				/>
				<Input
					{...register('referral')}
					label="Referral code"
					error={!!errors.referral}
					helperText={errors.referral?.message}
					placeholder=""
					className="mb-24"
					fullWidth={true}
					disabled={referralBlocked}
					highlighted={state?.from?.includes('london')}
				/>

				<Button
					type="submit"
					className="mb-32"
					color={state?.from?.includes('london') ? 'default' : 'primary'}
					fullWidth={true}
					disabled={isSubmitting}
				>
					Sign Up
				</Button>
			</form>

			{error && <FormAlert type="error">{error}</FormAlert>}
		</>
	);
}
