import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from 'components/ui/Modal';
import { SupportForm } from 'components/forms/SupportForm';

export const SupportRestrictedModal = NiceModal.create(() => {
	const modal = useModal();
	return (
		<Modal
			title="Support Request"
			className="p-40 width-popupDesktop"
			titleClassName="ptb-24 m-0"
			isOpen={modal.visible}
			onClose={modal.hide}
		>
			<SupportForm />
		</Modal>
	);
});
