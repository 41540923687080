import React from 'react';
import { API_URL } from 'services/http.service';
import { Body } from 'components/layout/Body';
import { Main } from 'components/layout/Main';
import { Typography } from 'components/ui/Typography';
import { Logo } from 'components/ui/Logo';
import { DownArrowIcon } from 'components/ui/Icon';
import { Link } from 'components/ui/Link';

import DownloadApk from 'assets/images/androidapk.png';
import DownloadStep1 from 'assets/images/download-app.png';
import DownloadStep2 from 'assets/images/open-apk.png';
import DownloadStep3 from 'assets/images/install-apk.png';
import style from './download.module.scss';

function Download() {
	function clickHandle(){
		window.open(`${API_URL}/apk/BS_LIVE_SERVER_12_3_21.apk`, '_blank');
	}
	return (
		<Body>
			<Main>
				<div className={style.download}>
					<div className={style.logo}>
						<Logo size="lg" />
					</div>

					<Typography
						className="mlr-16"
						textTransform="capitalize"
						variant="h4"
						weight="bold"
					>
						Why am I downloading this app here instead of the play store
					</Typography>
					<div>
						<Typography className="mlr-16" variant="h6" weight="bold">
							Paid fantasy sports games and apps are not available on the
							app store, However the app that you are downloading here is
							the exact same application that IOS users are downloading on
							the apple app store
						</Typography>
					</div>
					<DownArrowIcon color="white" size={50}></DownArrowIcon>
					<div className={style.options}>
						<div className={style.download_btn} onClick={()=>clickHandle()} >
							<img
								style={{
									maxWidth: '300px'
								}}
								src={DownloadApk}
								alt=""
								className=""
							/>
						</div>
					</div>
					<div className={style.howto}>
						<Typography className="m-0" weight="bold" variant="h4">
							How To Install Apk
						</Typography>
					</div>
					<div className={style.steps}>
						<Typography variant="h3">Step1</Typography>
						<div>
							<img src={DownloadStep1} alt="" className="" />
						</div>
						<div className={style.step_info}>
							<Typography className="mtb-8" variant="h4">
								Download Apk
							</Typography>
							<Typography variant="p1">
								“Download” our app by clicking the button above!
							</Typography>
						</div>
					</div>
					<div className={style.steps}>
						<Typography variant="h3">Step2</Typography>
						<div>
							<img src={DownloadStep2} alt="" className="" />
						</div>
						<div className={style.step_info}>
							<Typography className="mtb-8" variant="h4">
								Open APK
							</Typography>
							<Typography variant="p1">
								Tap the notification to open the APK you may need to
								navigate to your setting & turn ON “Trust unknown sources”
							</Typography>
						</div>
					</div>
					<div className={style.steps}>
						<Typography variant="h3">Step3</Typography>
						<div>
							<img src={DownloadStep3} alt="" className="" />
						</div>

						<div className={style.step_info}>
							<Typography className="mtb-8" variant="h4">
								Install APK
							</Typography>
							<Typography variant="p1">
								Tap on “install” to complete the installation of Bounty
								Sports start playing right away!
							</Typography>
						</div>
					</div>
					<div>
						<Typography className="mlr-16" variant="h4" weight="bold">
							Get The Bounty Sports App Below
						</Typography>
						<DownArrowIcon color="white" size={50}></DownArrowIcon>
						<div className={style.options}>
							<div className={style.download_btn} onClick={()=>clickHandle()}>
								<img
									style={{
										maxWidth: '300px'
									}}
									src={DownloadApk}
									alt=""
									className=""
								/>
							</div>
						</div>
					</div>
				</div>
			</Main>
		</Body>
	);
}

export default Download;
