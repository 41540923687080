import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useModal } from '@ebay/nice-modal-react';
import { formatSum } from 'utils/format-sum';
import { useGetProfileQuery } from 'services/user.service';
import { Logo } from 'components/ui/Logo';
import { Button } from 'components/ui/Button';
import { ProfileDropdown } from 'components/ui/ProfileDropdown';
import { ShareIcon } from 'components/ui/Icon';
import { Notifications } from 'components/ui/Notifications';
import { ShareModal } from 'components/modals/Share';
import { DepositModal } from 'components/modals/Deposit';
import { Navbar } from './Navbar';

import style from './header.module.scss';
// import { Balance } from '../../ui/Balance';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { getFlooredFixed } from 'utils/to-fixed';
import { useAcceptNewTerms } from 'hooks/useAcceptNewTerms';
import hbService, { LOCATION_TYPE } from 'services/hubspot.service';

interface HeaderProps {
	signUpBtn?: JSX.Element;
}

const defaultSignUp = (
	<Button href="/sign-up" color="primary" className="xl:ptb-12">
		Sign Up Today
	</Button>
);

export function Header({ signUpBtn = defaultSignUp }: HeaderProps) {
	const { isTable } = useWindowDimensions();
	let { pathname } = useLocation();
	const shareModal = useModal(ShareModal);
	const depositModal = useModal(DepositModal);

	const isPresalePage = useMemo(
		() => (pathname ? pathname.includes('sale') : false),
		[pathname]
	);

	const { data: profile } = useGetProfileQuery();
	useAcceptNewTerms(profile);
	return (
		<header
			className={classNames(style.header, {
				[style.header_presale]: isPresalePage
			})}
		>
			<Logo />
			{!isPresalePage ? (
				<>
					<Navbar />
					<div
						className={classNames(
							style.header_auth,
							profile && style.logged_in
						)}
					>
						{profile ? (
							<>
								{/*<Notifications />*/}

								<Button
									variant="outlined"
									endIcon={<ShareIcon className="ml-20" />}
									onClick={() => shareModal.show()}
								>
									Invite friends
								</Button>
								<Button
									onClick={() => {
										depositModal.show();
									}}
									variant="outlined"
								>
									{/*formatSum(profile.balance) */}
									{getFlooredFixed(profile.balance)} $
								</Button>
								<ProfileDropdown />
							</>
						) : (
							<>
								{signUpBtn}
								<Button
									variant="outlined"
									color="primary"
									href="/login"
									className="xl:ptb-11"
								>
									Log in
								</Button>
							</>
						)}
					</div>
				</>
			) : (
				!isTable && (
					<Button
						key="claim-league-header"
						size="xl"
						variant="presale"
						className={style.header_presale}
						href="/sale/membership"
						onClick={() => {
							hbService.hbRegisterClick(LOCATION_TYPE.header);
						}}
						state={{
							from: '/sale/membership'
						}}
					>
						CLAIM SALE OFFER
					</Button>
				)
			)}
		</header>
	);
}
