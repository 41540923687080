import TagManager from 'react-gtm-module';

const tagManagerArgs = {
	gtmId: 'GTM-WX8DD82'
};

export const useGTM = () => {
	const init = () => {
		TagManager.initialize(tagManagerArgs);
	};

	const tiktokSubscribe = (
		email: string | null = null,
		user_id: string | null = null
	) => {
		TagManager.dataLayer({
			dataLayer: {
				event: 'tiktokSubscribe',
				email: email,
				external_id: user_id
			}
		});
	};

	const tiktokCompleteRegistration = (
		email: string | undefined,
		user_id: string | undefined
	) => {
		TagManager.dataLayer({
			dataLayer: {
				event: 'complete_registration',
				email: email,
				external_id: user_id
			}
		});
	};

	const tiktokEnrollment = (email: string, user_id: string) => {
		TagManager.dataLayer({
			dataLayer: {
				event: 'enroll',
				email: email,
				external_id: user_id
			}
		});
	};

	return { init, tiktokSubscribe, tiktokCompleteRegistration, tiktokEnrollment };
};
