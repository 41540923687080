import {Typography} from "../../components/ui/Typography";

export const TermsContent = () =>{
	const defaultTitle = 'h4';
	const defaultSubTitle = 'h6';
	const defaultP = 'p2';
	return (
		<>
			<Typography variant={defaultTitle} textAlign="center">
				BOUNTY SPORTS TERMS OF USE
			</Typography>
			<Typography variant={defaultP}>
				The following document outlines the terms of use of Bounty Sports
				Inc.’s website. Before using any of Bounty Sports Inc. (“
				<Typography variant={defaultP} component="span" weight="bold">
					Bounty
				</Typography>
				”) Services (as hereinafter defined), you are required to read,
				understand, and agree to these terms.
			</Typography>
			<Typography variant={defaultP}>
				Your access to this website, [www.bountysports.com] (the “
				<Typography variant={defaultP} component="span" weight="bold">
					Site
				</Typography>
				”), provided to you by Bounty is subject to these legally binding
				terms and conditions. Please, carefully read all of the following
				terms of use (the “
				<Typography variant={defaultP} component="span" weight="bold">
					Terms of Use
				</Typography>
				” or “
				<Typography variant={defaultP} component="span" weight="bold">
					Terms
				</Typography>
				”) before proceeding. Your continued use of the Site indicates your
				acceptance of the Terms and that you intend to be legally bound by
				them. If you do not agree to be bound by these Terms, you should not
				use this Site. If you consider future amendments made to these Terms
				to be unacceptable, please do not continue to use the Site.
			</Typography>
			<Typography variant={defaultP}>
				These Terms are a validly enforceable agreement between you (“you”,
				and also “
				<Typography variant={defaultP} component="span" weight="bold">
					your
				</Typography>
				” or “
				<Typography variant={defaultP} component="span" weight="bold">
					yours
				</Typography>
				”) and Bounty. Throughout these Terms, the words “
				<Typography variant={defaultP} component="span" weight="bold">
					ourselves
				</Typography>
				”, “
				<Typography variant={defaultP} component="span" weight="bold">
					us
				</Typography>
				”, “
				<Typography variant={defaultP} component="span" weight="bold">
					we
				</Typography>
				”, and “
				<Typography variant={defaultP} component="span" weight="bold">
					our(s)
				</Typography>
				” will refer to Bounty, its affiliates, subsidiaries, assignees and
				successors; and also our Site and Services, as appropriate in the
				context of the use of such words. Your use of the Site is entirely at
				your own risk, for which we shall not be held liable. It shall be your
				own responsibility to ensure that any Services or information
				available through this Site meet your specific purposes.
			</Typography>
			<Typography variant={defaultP}>
				Any failure on your part to comply with these Terms of use may lead to
				Bounty: (i) prohibiting you from using this Site and any related
				privileges; or (ii) instituting any other remedies Bounty or its
				affiliates, in their sole, absolute and unfettered discretion, deem
				appropriate. To prevent any breach of the Terms hereof and to enforce
				any remedy available at law, we shall take any technical, legal or
				managerial measures that we deem, in our sole, absolute and unfettered
				discretion, necessary and appropriate, without prior notice to you or
				any other user.
			</Typography>
			<Typography variant={defaultP}>
				Bounty’s privacy policy (“
				<Typography variant={defaultP} component="span" weight="bold">
					Privacy Policy
				</Typography>
				”) [www.bountysports.com/privacy] is incorporated herein by reference
				and forms an integral part of these Terms.
			</Typography>
			<Typography variant={defaultSubTitle}>1. DEFINITIONS</Typography>
			<Typography variant={defaultP}>
				"
				<Typography variant={defaultP} component="span" weight="bold">
					Applicable Law
				</Typography>
				” means any local, municipal, provincial, state, federal and/or
				administrative laws, regulations, rules, policies, notices and/or
				guidelines and all other applicable laws in effect as of the date of
				your access of the Site, or at the date the application of such law is
				being considered, including all statutes, ordinances, decrees,
				regulations, municipal by-laws, judicial or arbitral or administrative
				or ministerial or departmental or regulatory judgments, orders,
				decisions, ruling or awards, and general principles of common and
				civil law and equity including, without limitation, all applicable
				gaming, gambling and lottery laws, regulations, rules, policies,
				notices and/or guidelines or similar legislation, legally binding on
				the person or entity referred to in the context in which the word is
				used.
			</Typography>
			<Typography variant={defaultP}>
				"
				<Typography variant={defaultP} component="span" weight="bold">
					Content
				</Typography>
				" means any text, image, audio, video, or other forms of data or
				information used or displayed in connection with the Site. "
				<Typography variant={defaultP} component="span" weight="bold">
					User Content
				</Typography>
				" means Content that site users submit or transmit to, or in
				connection with the Site. "
				<Typography variant={defaultP} component="span" weight="bold">
					Bounty Content
				</Typography>
				" means Content that we create and publish in connection with the
				Site. "
				<Typography variant={defaultP} component="span" weight="bold">
					Third Party Content
				</Typography>
				" means Content that originates from parties other than Bounty or its
				users, which is made available through the Site. "
				<Typography variant={defaultP} component="span" weight="bold">
					Site Content
				</Typography>
				" means all of the Content that is made available in connection with
				the Site, including your Content, User Content, Bounty Content and
				Third Party Content.
			</Typography>
			<Typography variant={defaultP}>
				“
				<Typography variant={defaultP} component="span" weight="bold">
					Services
				</Typography>
				” mean any and all contests and offerings made available on the Bounty
				Sports platform. This may include, but is not limited to, sports
				forecasting contests that are free to play, sports forecasting
				contests that require paid enrollment to play, and any related contest
				or competition made available to users on Bounty Sports.
			</Typography>
			<Typography variant={defaultSubTitle}>1. USE OF SITE</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					Account
				</Typography>{' '}
				- In order to use part or all of our Services, you may need to create
				a profile and register on our Site.
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					Permission to use the Site
				</Typography>{' '}
				- You are permitted to use the Site subject to the restrictions set
				out in these Terms. You use the Site at your own risk, including the
				risk that you might be exposed to Content you may find offensive,
				indecent, inaccurate, or objectionable. You hereby represent and
				warrant that you are at least eighteen (18) years of age, or older, as
				of the date of first access to our Site.
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					Site Access
				</Typography>{' '}
				- You agree that our Site may not always be error-free, continuous, or
				undisrupted. We may update, delete, disable, modify functionalities or
				otherwise discontinue the Site, in our sole, absolute and unfettered
				discretion, and we do not guarantee that it will always be available,
				work, or be accessible at any particular time. We cannot guarantee
				that the Site will work as advertised, or that it will give you the
				desired results. You agree not to distribute or make available any
				Site Content found on our Site without our prior written authorization
				unless such act is done through sharing functionalities offered by our
				Service, and to only access Site Content through our Site or as
				otherwise authorized by us. Use of the site from a restricted region
				is strictly prohibited. Users who violate the Site Access policy may
				have their account suspended indefinitely and all funds contained
				within forfeited.
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					Compliance
				</Typography>{' '}
				– Bounty is not responsible for your violation of any Applicable Law
				while using our Site. You must comply with all Applicable Law
				regarding your use of our Site. We hereby reserve the right (but not
				the obligation) to remove and/or edit any Content you or other user
				posts on the Site, and we have the right (but not the obligation) to
				monitor and edit or remove any activity; thereby enacting the
				necessary measures to moderate any comments and to control user
				behavior within our Site.
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					User Accounts
				</Typography>{' '}
				- You must create an account and profile and may be asked to provide
				certain information about yourself in order to use some features of
				the Site. You are responsible for the confidentiality of your Bounty
				account password and also for any activities that occur in connection
				with this account. You agree to notify us immediately of any
				unauthorized use of your account. We reserve the right to close your
				account at any time for any or no reason in our sole, absolute and
				unfettered discretion. In creating your Bounty profile, you represent
				and warrant that all information about yourself is accurate. You may
				not impersonate any other person. If you use a pseudonym as an online
				name, please note that other site users may still be able to identify
				you if, for example, you include identifying information or if you use
				the same account information on other sites.
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					One Person per Account
				</Typography>
				: As part of our commitment to ensuring a safe and secure playing
				environment and to, without limitation, ensure compliance with
				Applicable Law, Bounty Sports implements measures to validate that
				accounts reflect the correct identity of individuals who own them. You
				may not open an account in any name (a “
				<Typography variant={defaultP} component="span" weight="bold">
					Fake Name
				</Typography>
				”) other than your own correct full legal name (“
				<Typography variant={defaultP} component="span" weight="bold">
					Correct Identity
				</Typography>
				”). Bounty Sports reserves the right to immediately suspend and/or
				permanently block you and/or your account in the event it identifies
				information that is inconsistent with your Correct Identity. If you
				violate these terms, you irrevocably acknowledge, agree and consent to
				the complete forfeiture of any balance in your account in the event
				Bounty Sports elects to suspended and/or block the account. In
				addition to the foregoing, without limitation, you agree to the
				following conditions: (i) you are not permitted to have more than one
				account; (ii) your first name, last name, date of birth and home
				address must match the information on your government-issued
				identification; (iii) by creating an account, you understand and
				accept that Bounty Sports holds the right to request, for any reason
				whatsoever or no reason at all, i.e. random selection, the
				verification of your Correct Identity using a reputable third party at
				any time; and (iv) failure to provide information sufficient for
				validation of your Correct Identity may result in suspension and/or
				blockage of your account.
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					Communications from Bounty and other Users
				</Typography>{' '}
				- By creating an account, you agree to receive certain communications
				from the Site regarding your account.
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					Links to Third-Party Sites
				</Typography>{' '}
				- This Site contains links to third-party sites. Bounty is not
				responsible for third-party linked sites. Your use of these sites is
				at your own risk and Bounty is not responsible for Third-Party Content
				or changes to these sites. Bounty makes no endorsement about any other
				website which you may access through this Site. The terms of use and
				privacy policies of those websites will likely differ from that of
				this Site. It is your responsibility to review the terms of use and
				privacy policy of any third-party site. You agree that Bounty shall
				not have any liability whatsoever to you for any such third-party
				material, data or information. When you click on a link for another
				website, you are leaving Bounty, and we are not responsible.
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					Updates
				</Typography>{' '}
				- Bounty hereby reserves the right to update, modify, discontinue or
				terminate the Site at any time and in our sole, absolute and
				unfettered discretion. Any changes to these Terms will be displayed in
				the Site. Your continued use of the Site after the last effective date
				of amendment to these Terms – either by registration or simple use –
				indicates your acceptance of any modifications thereto.
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					Privacy
				</Typography>{' '}
				- You represent and warrant that you have read, acknowledged and agree
				with the terms of our{' '}
				<Typography variant={defaultP} component="span" weight="bold">
					Privacy Policy
				</Typography>
				. Please take into account that we may need to disclose certain
				personally identifiable information about you, if we or our affiliates
				have a good faith belief that such a disclosure is necessary to: (i)
				take any action regarding factual or alleged illegal or inappropriate
				activities by you or any user of the Site; (ii) enforce or apply our
				Terms and{' '}
				<Typography variant={defaultP} component="span" weight="bold">
					Privacy Policy
				</Typography>
				; (iii) comply with due legal process, judicial proceeding or other
				valid government request served on us or our affiliates; and/or (iv)
				protect our rights, goodwill and assets, or that of our users,
				affiliates, or the general public. If you use the Site outside of
				Canada, you consent to have your personally identifiable information
				transferred to and processed in Canada.
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					Banking Information must Match Identification
				</Typography>
				: Your banking information (hereafter collectively, “
				<Typography variant={defaultP} component="span" weight="bold">
					Bank Details
				</Typography>
				”) must match and be consistent with your Correct Identity. It is a
				violation of these Terms of Use to request a funds withdrawal or other
				transaction (hereafter collectively, a “
				<Typography variant={defaultP} component="span" weight="bold">
					Funds Withdrawal Request
				</Typography>
				”) where your identification details do not match the Bank Details.
				Bounty Sports reserves the right to refuse any Funds Withdrawal
				Request where it believes, on a commercially reasonable basis, that:
				(i) you have used a Fake Name; (ii) your identification details do not
				precisely match your Bank Details; and/or (iii) Bounty Sports has
				reason to believe a Funds Withdrawal Request is suspicious and/or in
				violation of Applicable Law. If a Funds Withdrawal Request is
				suspicious and/or suspected to be in violation of Applicable Law,
				Bounty Sports will conduct an internal investigation and reserves the
				right, in the event it concludes that your Funds Withdrawal Request is
				suspicious and/or in violation of Applicable Law, to refuse your
				request.
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					Promotional Funds, not Withdrawable
				</Typography>
				: Funds you have received from any source other than your direct
				deposit of cash or proceeds from winning a contest, are not
				withdrawable (hereafter collectively, “
				<Typography variant={defaultP} component="span" weight="bold">
					Promotional Funds
				</Typography>
				”). For the purposes of clarity, Promotional Funds may only be used
				for contest entry and cannot be redeemed for cash or transferred to
				other players.{' '}
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					No Purchase to Play Required for Users from Canada
				</Typography>
				: If you access our Site from Canada, Bounty Sports does not require
				you to make a purchase to play. You can receive free entry into any
				contest by e-mailing Bounty Sports at{' '}
				<Typography variant={defaultP} component="span" weight="bold">
					fanexperience@bountysports.com
				</Typography>
				with the e-mail subject line titled "Request for Free Entry". The body
				of the e-mail should include the contest name, entry size, start date,
				start time and the number of spots available for entry at the time of
				the e-mail. Bounty Sports will return an e-mail with a list of 100 UFC
				fights of no particular order and you are required to return a summary
				of no less than 200 words describing each fight including the date and
				start time of the fight, the fighters previous records, a brief
				summary of each of their careers, a round by round description of the
				fight itself including the judge's scores for each round and a summary
				of the final result of the fight, including the method of victory (“
				<Typography variant={defaultP} component="span" weight="bold">
					User Essay
				</Typography>
				”). The User Essay must be accurate, in real words and complete
				sentences and in the English language.
			</Typography>
			<Typography variant={defaultSubTitle}>3. RESTRICTIONS</Typography>
			<Typography variant={defaultP}>
				Your use of, and any interaction with, the Site and Content must at
				all times comply with Applicable Law. You may not use the Site to
				bully other users, upload illegal material, modify Site Content, send
				spam or hack into user information. We will not tolerate, nor allow
				others to use any information from the Site, for the transmission of
				unsolicited bulk communication to any of our users or to any
				third-party. You may not access the Site to harvest and/or collect any
				information about our users, for any purpose.
			</Typography>
			<Typography variant={defaultP}>
				Bounty reserves the right, in its sole, absolute and unfettered
				discretion, to decide what data, Content, or information is published
				to, or removed from, the Site that is deemed to violate any of the
				above restrictions.
			</Typography>
			<Typography variant={defaultSubTitle}>
				4. INTELLECTUAL PROPERTY
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					Ownership
				</Typography>{' '}
				- We own the Bounty Content including but not limited to visual
				interfaces and graphics, interactive features, design features,
				compilation, products, computer code, software, user review and
				ratings, and any and all other multimedia elements and/or components
				of the Site, excluding User Content and any Third Party Content
				displayed there. We own the copyrights, trademarks, trade names, trade
				secrets, service marks, patents and any and all other intellectual and
				proprietary rights whatsoever (hereafter, as applicable, “
				<Typography variant={defaultP} component="span" weight="bold">
					Intellectual Property
				</Typography>
				”) associated with the Bounty Content and the Site throughout the
				world and it is protected under Intellectual Property laws and any and
				all other applicable intellectual and proprietary rights. As such, you
				may not reproduce, modify, create derivative works or adaptations
				thereof, distribute, publicly display or in any way exploit any of the
				Bounty Content, in whole or in part, except as expressly authorized by
				us or our affiliates in writing. Except as expressly set forth herein,
				we do not grant you any express or implied rights, authorizations or
				licenses, and any and all rights in and to the Site and the Bounty
				Content are ours.
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					Limited License and Copyright
				</Typography>{' '}
				- Subject to these Terms, Bounty grants you a non-exclusive,
				non-transferable, limited right to access, use and display this Site
				and the visible text, graphics or images thereon (the “
				<Typography variant={defaultP} component="span" weight="bold">
					Materials
				</Typography>
				”) and to view and download the Materials, only in connection with
				your personal and non-commercial use of the Site. This authorization
				is revocable at any time, in the sole, absolute and unfettered
				discretion of Bounty, is not a transfer of title in the Materials, and
				is subject to the restrictions in the Terms.
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					Trade-marks
				</Typography>{' '}
				- The trade-marks, logos and company names of Bounty or any of its
				affiliates used on this Site may not be copied, imitated or used, in
				whole or in part, without the prior written consent of Bounty or any
				such affiliate. Other products, services, logos and company names
				mentioned on this Site may be the trade-marks of their respective
				owners. Except as expressly provided herein, Bounty and its affiliates
				do not grant any express or implied Intellectual Property right or
				license to you under any Intellectual Property law.
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					Non-infringement
				</Typography>{' '}
				- You may not post or otherwise make available to the Site any
				material that is protected by Intellectual Property laws without the
				express permission of the owner. You agree to assume sole liability
				and to indemnify Bounty for any damage resulting from infringement of
				any third-party's Intellectual Property rights or any other harm
				resulting from your use of such infringing materials in connection
				with the Site.
			</Typography>
			<Typography variant={defaultSubTitle}>
				5. LIMITATIONS ON LIABILITY AND DISCLAIMERS
			</Typography>
			<Typography variant={defaultP}>
				In no event shall Bounty or its affiliates, subsidiaries, assignees,
				successors, agents, licensors, suppliers or their respective
				directors, officers, employees and/or contractors be liable to you for
				any direct, indirect, incidental, special, punitive, or consequential
				damages whatsoever, whether based on warranty, contract, tort, or any
				other legal theory, and whether or not you or we are advised of the
				possibility of such damages. The foregoing limitation of liability
				shall apply to the fullest extent permitted by Applicable Law in the
				applicable jurisdiction. Bounty’s maximum cumulative liability to you
				for any losses or damages arising out of or in connection with your
				access of the Site and any Services therein shall be limited to: (i)
				the amount paid, if any, by you to us in connection with the Site and
				any Services therein during the 12 months prior to the action giving
				rise to such liability (if any); or (ii) CDN$100 (one hundred Canadian
				dollars), whichever amount is less. The use of this Site is at your
				own risk and Bounty assumes no liability or responsibility pertaining
				to the Content, your use of the Site, or the receipt, storage,
				transmission, or other use of your personal information.
			</Typography>
			<Typography variant={defaultP}>
				The materials and information accessible through this Site may contain
				inaccuracies and typographical errors. Bounty and its affiliates make
				no representations or warranties about the accuracy or completeness of
				the materials or information accessible on or through this Site, or
				the reliability of any advice, opinion, statement, or other
				information displayed or distributed through this Site. You
				acknowledge and agree that any reliance on any of the foregoing shall
				be at your sole risk. Bounty will not be responsible to you or any
				third-party for any damages, expenditures, loss of profits or
				prospective profits of any kind or nature sustained or arising out of
				or alleged to have been sustained or to have arisen out of your use of
				this Site. You acknowledge and agree that any funds you expend in
				using our Site will not be reimbursed in whole or in part and you
				accept the complete loss of any such funds so expended.
			</Typography>
			<Typography variant={defaultP}>
				Your use of our Site is at your own risk, and therefore you hereby
				acknowledge and agree that we supply our services “as is” and “as
				available”, including all Content, software, materials, services,
				functions, and/or information made available through the Site. Bounty
				is not responsible for any Content or information that you may find
				undesirable or objectionable. Bounty hereby expressly disclaims any
				and all liability whatsoever arising out of or related to any
				purported facts or information and description of any products
				displayed on our Site, including all warranties of any kind, either
				express or implied, including, without limitation, warranties of
				title, merchantability, fitness for a particular purpose or
				non-infringement, and any damages suffered as a result of the use,
				inability to use, failure of, or any omissions or inaccuracies in,
				this website, any linked websites or linked social media platforms, or
				any of the services or content of the foregoing. You understand that
				Bounty is a private corporation and has no connection to any
				governmental body. Neither Bounty, nor its affiliates, subsidiaries,
				assignees, successors, agents, directors, officers, employees, and/or
				contractors warrant that the Site will be error-free, uninterrupted,
				secure, or produce particular results, that any figures, competition,
				and/or game results and/or odds quoted are current, valid and/or
				un-expired, or that the information obtained therefrom will be
				reliable or accurate. No advice or information was given by Bounty or
				its affiliates, subsidiaries, assignees, successors, agents,
				directors, officers, employees, and/or contractors shall create a
				guarantee.
			</Typography>
			<Typography variant={defaultP}>
				If you are dissatisfied with the Site and the Content therein, or the
				Terms, your sole, and exclusive remedy are to discontinue the use of
				the Site.
			</Typography>
			<Typography variant={defaultSubTitle}>6. CANCELLATION</Typography>
			<Typography variant={defaultP}>
				Bounty may, at its sole, absolute and unfettered discretion, cancel or
				suspend any of your privileges related to the use of the Site in whole
				or in part for any reason, including without limitation, the
				non-compliance with these Terms of Use.
			</Typography>
			<Typography variant={defaultSubTitle}>7. LEGAL DISPUTES</Typography>
			<Typography variant={defaultP}>
				You and Bounty agree to resolve any dispute that arises out of these
				Terms or our Services (a "
				<Typography variant={defaultP} component="span" weight="bold">
					Claim
				</Typography>
				") in accordance with one of the subsections below or as Bounty and
				you otherwise agree in writing.
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					Get in touch
				</Typography>
				: We encourage you to contact us by phone or email us before resorting
				to alternatives.
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					Law and Forum for Legal Disputes
				</Typography>{' '}
				– These Terms shall be governed in all respects by the laws of the
				Province of Ontario, Canada as they apply to agreements entered into
				and to be performed entirely within Ontario between Ontario residents,
				without regard to conflict of law provisions. Except as otherwise
				agreed by the parties or as described in the Arbitration Option
				paragraph below, for the purpose of litigating all Claims, you agree
				to irrevocably attorn and submit to the jurisdiction of the courts
				located within Toronto, Ontario, Canada.
			</Typography>
			<Typography variant={defaultP}>
				<Typography variant={defaultP} component="span" weight="bold">
					Arbitration Option
				</Typography>{' '}
				- For any Claim (excluding claims for injunctive or other equitable
				relief) totalling an amount less than $25,000, the party requesting
				relief will elect to resolve the dispute through binding
				non-appearance-based arbitration. Arbitration shall be initiated
				through an established arm’s-length alternative dispute resolution
				(ADR) provider located in Toronto, Ontario, Canada which shall be
				mutually agreed upon by all parties and, in the absence of agreement,
				retained by us in our sole, absolute and unfettered discretion. The
				following rules shall be complied with by the ADR provider and the
				parties: (a) the arbitration shall be conducted by telephone, online
				and/or be solely based on written submissions. The initiating party
				shall choose the specific manner by which the arbitration will be
				conducted; (b) the arbitration shall not involve any personal
				appearances by the parties or witnesses unless otherwise mutually
				agreed by the parties; and (c) any judgment on the award rendered by
				the arbitrator may be entered in any court of competent jurisdiction.
			</Typography>
			<Typography variant={defaultSubTitle}>8. COOKIES</Typography>
			<Typography variant={defaultP}>
				A cookie is a small computer file or piece of information that may be
				stored in your computer's hard drive when you visit our Site. We may
				use cookies to improve our Site’s functionality and in some cases, to
				provide you with a customized online experience.
			</Typography>
			<Typography variant={defaultP}>
				Cookies are widely used and most web browsers are configured initially
				to accept cookies automatically. You may change your Internet browser
				settings to prevent your computer from accepting cookies or to notify
				you when you receive a cookie so that you may decline its acceptance.
				Please note, however, if you disable cookies, you may not experience
				optimal performance of our Site.
			</Typography>
			<Typography variant={defaultSubTitle}>
				9. USE OF INFORMATION AND DATA
			</Typography>
			<Typography variant={defaultP}>
				You acknowledge and agree that we may, subject to our Privacy Policy,
				share, transfer and/or convey information and data generated by our
				Site to third parties when we engage in business or transactions with
				third parties including, without limitation, a financing, partnership,
				joint venture or sale of part or all of our assets, business or
				undertaking.
			</Typography>
			<Typography variant={defaultSubTitle}>10. GENERAL</Typography>
			<Typography variant={defaultP}>
				These Terms constitute the entire agreement and understanding between
				you and Bounty and govern your use of the Site and Services provided
				therein, superseding any prior agreements between you and Bounty. The
				failure of Bounty to exercise or enforce any right or provision of
				these Terms will not constitute a waiver of such right or provision.
				If any provision of these Terms is found by a court of competent
				jurisdiction to be unlawful, void or for any reason unenforceable,
				then that provision shall be deemed severed from these Terms and, if
				possible, modified to reflect the parties' intention and shall not
				affect the validity and enforceability of any remaining provisions.
				Bounty may assign any or all of its rights hereunder to any party
				without your knowledge or consent. You are not permitted to assign any
				of your rights or obligations under these Terms of use without the
				prior written consent of Bounty, and any such attempted assignment
				will be void and unenforceable. No waiver by Bounty of any breach or
				default hereunder shall be deemed a waiver of any preceding or
				subsequent breach or default. The section and paragraph headings in
				these Terms are for convenience only and will not affect their
				interpretation. Bounty will not be liable for any failure to perform
				our obligations hereunder, where such failure results from any cause
				beyond our reasonable control, including, without limitation,
				mechanical, electronic, or communications failure or degradation. You
				and Bounty are independent contractors, and no agency, partnership,
				joint venture, employee-employer, or franchiser-franchisee
				relationship is intended or created by these Terms.
			</Typography>
			<Typography variant={defaultSubTitle}>11. THE LEAGUE</Typography>
			<Typography variant={defaultP}>
				The League (“<Typography variant={defaultP} component="span" weight="bold">The League</Typography>”) is a subscription service available to Bounty Users. Bounty Users can become members of The League (“<Typography variant={defaultP} component="span" weight="bold">League Member</Typography>”) by purchasing and activating a League membership (“<Typography variant={defaultP} component="span" weight="bold">Subscription Fee</Typography>”), available on the Bounty Sports website. League members will be offered various privileges and offers which may include: free entry into certain contests, free promotional credits, free entry into various giveaways, and exclusive access to a dashboard that hosts various community-oriented features. While Bounty intends on providing as many privileges as possible to it’s members, by purchasing a membership, League Members acknowledge and agree to the following:
			</Typography>
			<Typography variant={defaultP} >
				1) Bounty, in its sole and unfettered discretion, reserves the right to temporarily or permanently suspend League access to any League Member if they; (1) deem the League Member to be fraudulent, (2) have broken the general terms of use of the website, or (3) if the member has engaged in abusive behavior within the community. League Members who are suspended are not subject to receive a refund of their Subscription Fee.
			</Typography>
			<Typography variant={defaultP} >
				2) Bounty will attempt to make League access, privileges, and offers (“<Typography variant={defaultP} component="span" weight="bold">League Feature</Typography>”) available on a best-effort basis to all League Members. Bounty reserves the right to temporarily or permanently suspend access to any League Feature at any time, without prior written notice to League Members.
			</Typography>
			<Typography variant={defaultP}>
				3) Bounty does not guarantee that free contests (“<Typography variant={defaultP} component="span" weight="bold">League Contests</Typography>”) will be made available on a weekly basis. Without prior written notice, Bounty may temporarily or indefinitely suspend all League Contests. League Contest prize amounts are not guaranteed by Bounty and may vary by week.</Typography>
			<Typography variant={defaultP}>
				4) Any prizes or giveaways made available to League Members are free to enter for and do not represent any form of paid lottery. Bounty reserves the right to make adjustments to prizes and giveaways after they are announced or after the winner is selected. Prizes and giveaways are only available in North America and Bounty reserves the right to limit the total cost of any prize. Prizes and giveaways are not guaranteed to League Members.
			</Typography>
			<Typography variant={defaultP}>
				5) League Memberships may vary in price. Advertised League Membership prices are not guaranteed at the time of purchase. All league Members are subject to an annual renewal fee, which may vary in cost. Bounty reserves the right to temporarily or indefinitely suspend any League Member who does not pay the annual renewal fee.
			</Typography>
			<Typography variant={defaultP}>
				6) By registering for The League, you consent and authorize Bounty Sports to initiate a Recurring Payment (“<Typography variant={defaultP} component="span" weight="bold">Recurring Payment</Typography>”) on your account. The Recurring Payment will be charged annually on the day and month in which you purchased your League Membership. Your Bounty Sports Account will be charged the annual renewal fee (“<Typography variant={defaultP} component="span" weight="bold">Renewal Fee</Typography>”) and this Renewal Fee will be deducted from your balance. The cost of the Renewal Fee may vary and is considered a final sale and non-refundable. League Members may be prompted to upload their credit card information to their Account, which will be stored in a PCI compliant manner by Bounty Sports (“<Typography variant={defaultP} component="span" weight="bold">Saved Credit Card</Typography>”). If the balance of your Account is less than the Renewal Fee, the difference will be charged to the Saved Credit Card. If your Account balance is less than the Renewal Fee and you do not have a Saved Credit Card, you may be charged a portion of the Renewal Fee equalling the total balance of your account. Members who do not pay the Renewal Fee in full may have their League Membership suspended or revoked.
			</Typography>
		</>
	);
}
