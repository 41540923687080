import React from 'react';
import { useGetAvailableContestsQuery } from 'services/contest.service';
import { useAppSelector } from 'hooks/redux';
import { selectNftContests } from 'store/contest/contest.slice';
import { ContestList } from 'components/ui/Contest';

import style from './dashboard.module.scss';
import { Typography } from 'components/ui/Typography';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

export const DashboardContest = () => {
	useGetAvailableContestsQuery();
	const items = useAppSelector(selectNftContests);
	return (
		<div className={classNames(style.contests, 'mt-30', 'mb-80')}>
			<div className={style.contests_title}>
				<Typography className="p-0 m-0 mb-26" variant="h5" weight="bold">
					Available Contests
				</Typography>

				<NavLink to={'/contest'}>
					<Typography component="a" variant="p2" color="league">
						View All Contests
					</Typography>
				</NavLink>
			</div>

			<ContestList items={items.length > 0 ? [items[0]] : []} />
		</div>
	);
};
