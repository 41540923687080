import React from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { loginUser } from 'store/user/user.actions';
import { selectUserError } from 'store/user/user.slice';
import { LoginData } from 'common/interfaces/auth.interface';
import { FormAlert } from 'components/ui/FormAlert';
import { Input, InputPassword } from 'components/ui/Input';
import { Button } from 'components/ui/Button';
import { Link } from 'components/ui/Link';
import { Typography } from 'components/ui/Typography';

const initialValues: LoginData = {
	email: '',
	password: ''
};
const validationSchema = Yup.object({
	email: Yup.string().email().required(),
	password: Yup.string().required()
});

export function LoginForm() {
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting }
	} = useForm<LoginData>({
		resolver: yupResolver(validationSchema),
		defaultValues: initialValues,
		mode: 'onTouched'
	});
	const { executeRecaptcha } = useGoogleReCaptcha();

	const dispatch = useAppDispatch();
	const error = useAppSelector(selectUserError);

	const onSubmit = async (values: LoginData) => {
		if (!executeRecaptcha) return;
		const token = await executeRecaptcha('login');
		dispatch(loginUser({ ...values, recaptcha: token }));
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Input
					{...register('email')}
					label="Email"
					error={!!errors.email}
					helperText={errors.email?.message}
					placeholder=""
					className="mb-24"
					fullWidth={true}
				/>
				<InputPassword
					{...register('password')}
					label="Password"
					error={!!errors.password}
					helperText={errors.password?.message}
					placeholder=""
					className="mb-24"
					fullWidth={true}
				/>

				<div className="mb-32">
					<Link underline="none" href="/forgot">
						<Typography color="desktop" variant="p2" component="span">
							Forgot your password?
						</Typography>
					</Link>
				</div>

				<Button
					className="mb-32"
					color="primary"
					fullWidth={true}
					disabled={isSubmitting}
				>
					Log In
				</Button>
			</form>

			{error && <FormAlert type="error">{error}</FormAlert>}
		</>
	);
}
