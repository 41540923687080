import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Mutex } from 'async-mutex';
import {
	BaseQueryFn,
	FetchArgs,
	fetchBaseQuery,
	FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import { RootState } from 'store';
import { Store } from '@reduxjs/toolkit';
import { storageService } from 'services/storage.service';
import { logoutUser } from 'store/user/user.actions';
import authService from 'services/auth.service';

export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8089';

interface HttpError {
	message: string;
	status: number;
}

export interface ServerResponse<T> {
	status: number;
	message: string;
	data: T;
}

const httpClient = axios.create({
	baseURL: API_URL,
	headers: {
		'Content-Type': 'application/json'
	}
});

const mutex = new Mutex();

const httpService = {
	get: httpClient.get,
	post: httpClient.post,
	put: httpClient.put,
	delete: httpClient.delete,
	patch: httpClient.patch,
	setupInterceptors(store: Store<RootState>) {
		httpClient.interceptors.request.use(
			async (config: AxiosRequestConfig) => {
				await mutex.waitForUnlock();

				const token = storageService.getAccessToken();
				config.headers = {
					...config.headers,
					Authorization: token || ''
				};

				return config;
			},
			(error: AxiosError<HttpError>) => {
				return Promise.reject(error);
			}
		);

		httpClient.interceptors.response.use(
			async (config: AxiosRequestConfig) => {
				return config;
			},
			async (error: AxiosError<HttpError>) => {
				const config = error?.config;
				const data = error.response?.data;
				if (!data) return Promise.reject(error);

				if (data.status === 401) {
					if (!mutex.isLocked()) {
						const release = await mutex.acquire();
						const tokens = await authService.refreshAccess();

						release();

						if (tokens?.accessToken) {
							config.headers = {
								...config.headers,
								Authorization: tokens.accessToken
							};
							return axios(config);
						} else {
							store.dispatch<any>(logoutUser());
						}
					} else {
						await mutex.waitForUnlock();
						return axios(config);
					}
				}

				return Promise.reject(new Error(data.message));
			}
		);
	}
};
export default httpService;

const baseQuery = fetchBaseQuery({
	baseUrl: API_URL,
	prepareHeaders: (headers) => {
		const token = storageService.getAccessToken();
		if (token) {
			headers.set('Authorization', token);
		}
		headers.set('Content-Type', 'application/json');
		return headers;
	}
});
export const customBaseQuery: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	// wait until the mutex is available without locking it
	await mutex.waitForUnlock();

	let result = await baseQuery(args, api, extraOptions);

	if (result?.error?.status === 401) {
		// checking whether the mutex is locked
		if (!mutex.isLocked()) {
			const release = await mutex.acquire();
			const tokens = await authService.refreshAccess();

			if (tokens?.accessToken) {
				// retry the initial query
				result = await baseQuery(args, api, extraOptions);
			} else {
				api.dispatch(logoutUser());
			}

			// release must be called once the mutex should be released again.
			release();
		} else {
			// wait until the mutex is available without locking it
			await mutex.waitForUnlock();
			result = await baseQuery(args, api, extraOptions);
		}
	}

	return result;
};
export const selectDataFromResponse = <T>(response: ServerResponse<T>) => response.data;
