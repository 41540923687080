import React from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { Navigation, NavigationItem } from 'components/layout/Navigation';
import { Typography } from 'components/ui/Typography';

import style from './league-navigation.module.scss';

export const LeagueNavigation = () => {
	const routes = [
		{
			path: '/league',
			label: (
				<Typography
					variant="p2"
					className="m-0 ptb-15"
					textTransform="capitalize"
				>
					Dashboard
				</Typography>
			)
		},
		{
			path: '/league/contests',
			label: (
				<Typography
					variant="p2"
					className="m-0 ptb-15"
					textTransform="capitalize"
				>
					Contests
				</Typography>
			)
		},
		{
			path: '/league/tokens',
			label: (
				<Typography
					variant="p2"
					className="m-0 ptb-15"
					textTransform="capitalize"
				>
					My Tokens
				</Typography>
			)
		},
		{
			path: '/league/leaderboard',
			label: (
				<Typography variant="p2" textTransform="capitalize">
					Leaderboard
				</Typography>
			)
		}
		// {
		// 	path: '/league/boost',
		// 	label: (
		// 		<Typography variant="p2" textTransform="capitalize">
		// 			Balance Boost
		// 		</Typography>
		// 	)
		// }
	];

	const loc = useLocation();
	return (
		<div className={style.wrapper}>
			<Navigation className={classNames(style.navigation, 'p-0 pt-56')}>
				{routes.map((route) => {
					return (
						<NavigationItem
							key={route.path}
							to={route.path}
							className={classNames('plr-24 f-width', {
								[style.navigation_active]: loc.pathname === route.path
							})}
						>
							{loc.pathname === route.path
								? React.cloneElement(route.label, { weight: 'bold' })
								: route.label}
						</NavigationItem>
					);
				})}
			</Navigation>
		</div>
	);
};
