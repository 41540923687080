import React from 'react';
import { selectNftContests } from 'store/contest/contest.slice';
import { ContestList } from 'components/ui/Contest';
import { useGetAvailableContestsQuery } from 'services/contest.service';
import { useAppSelector } from 'hooks/redux';

export default function AvailableContests() {
	useGetAvailableContestsQuery();
	const items = useAppSelector(selectNftContests);

	return (
		<>
			<ContestList items={items} />
		</>
	);
}
