import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { storageService } from 'services/storage.service';
import { Button } from 'components/ui/Button';
import { ArrowRightIcon } from 'components/ui/Icon/ArrowRightIcon';

import style from './betswap.module.scss';

const BetSwap = () => {
	const navigate = useNavigate();

	useEffect(() => {
		// script.src = 'https://bounty.betswap-testing.com/bounty/marketplace.js';
		const head = document.getElementsByTagName('head')[0];
		const link = document.createElement('link');
		link.rel = 'stylesheet';
		link.type = 'text/css';
		link.href =
			'https://bounty.betswap-testing.com/bounty/static/css/main.775e6a4a.css';
		link.media = 'all';
		head.appendChild(link);

		const scriptTag = document.createElement('script');
		scriptTag.src =
			'https://bounty.betswap-testing.com/bounty/static/js/main.3605eb1e.js';
		scriptTag.defer = true;
		document.body.appendChild(scriptTag);

		return () => {
			document.head.removeChild(link);
			document.body.removeChild(scriptTag);
		};
	}, []);


	return (
		<>
			<div style={{overflow: "auto"}}>
			<div
				id="betswap"
				//data-u="5fc7399f4f7e5427c5e2a11b"
				data-t="2sLSpPZCEuhnDzP4ZP"
				 data-u={storageService.getUserId()}
				// data-t={storageService.getAccessToken()}
			></div>
			</div>
			<div
				className="ptb-24"
				style={{
					backgroundColor: '#324664',
					textAlign: 'center'
				}}
			>
				<Button
					startIcon={
						<ArrowRightIcon
							className="mr-20"
							fillClass="default"
							rotate={true}
						/>
					}
					variant="outlined"
					onClick={() => navigate(-1)}
				>
					Back to Bounty Sports
				</Button>
			</div>

		</>
	);
};

export default BetSwap;
