import { Typography } from 'components/ui/Typography';

import { Container, Grid, GridItem } from 'components/layout/Grid';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'components/ui/Button';
import { ArrowRightIcon } from 'components/ui/Icon';

import { Input } from 'components/ui/Input';

import style from './style.module.scss';

import { format, parse } from 'date-fns';
import cn from 'classnames';

import { useCountdown } from 'hooks/useCountdown';
import useWindowDimensions, { getWindowDimensions } from 'hooks/useWindowDimensions';

import { variantsMapping } from 'components/ui/Typography/Typography.model';

import { useGetMembershipPriceQuery } from 'services/payment.service';
import { getJoinBtn } from '../london150';

const PROMO_CODE = 'LONDON150';

const dataBenefits = [
	{
		icon: '🌟',
		title: 'Next Trip',
		subtitle: 'Las Vegas Raiders Trip for 2'
	},
	{
		icon: '💰',
		title: 'Average Member Earnings',
		subtitle: '$180 after 10 weeks'
	},
	{
		icon: '🏆',
		title: 'Top Earner',
		subtitle: 'Ryan, $580 after 10 weeks'
	}
];

interface SizesType {
	title: keyof typeof variantsMapping;
	subtitle: keyof typeof variantsMapping;
}

export const Banner = () => {
	const { isMobile } = useWindowDimensions();

	const { data, isSuccess } = useGetMembershipPriceQuery(PROMO_CODE);

	const [promo, setPromo] = useState(PROMO_CODE);

	const onInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setPromo(event.target.value);
	}, []);

	const [preSalePrice, setPreSalePrice] = useState(0);
	const [regularPrice, setRegularPrice] = useState(0);

	useEffect(() => {
		if (isSuccess) {
			setPreSalePrice(data.current_price);
			setRegularPrice(data.basic_price);
		}
	}, [data, isSuccess]);

	const saleTime = parse('31/12/2022', 'dd/LL/yyyy', new Date()).getTime();
	const [sizes, setSizes] = useState<SizesType>({
		title: 'big',
		subtitle: 'h5'
	});

	const counterValue =
		saleTime <= new Date().getTime() ? 0 : saleTime - new Date().getTime();

	const { counter: timeLeft, startTimer } = useCountdown(counterValue ?? 0, 1000);

	useEffect(() => {
		startTimer();
	}, []);

	const { width: windowWidth } = getWindowDimensions();

	useEffect(() => {
		switch (true) {
			case windowWidth < 1320:
				setSizes({
					...sizes,
					title: 'h3',
					subtitle: 'body1'
				});
				break;
			case windowWidth < 1400:
				setSizes({
					...sizes,
					title: 'h1',
					subtitle: 'p1'
				});
				break;
			case windowWidth < 1580:
				setSizes({
					...sizes,
					title: 'main',
					subtitle: 'h6'
				});
				break;
			default:
				setSizes({
					...sizes,
					title: 'big',
					subtitle: 'h5'
				});
		}
	}, [windowWidth]);

	return (
		<div className={style.banner_container}>
			<Container>
				<div className={cn(style.benefits, !isMobile ? 'ptb-48' : 'pb-48')}>
					{dataBenefits && !isMobile && (
						<Grid
							cols={3}
							gap={[0, 24]}
							width={65}
							customProps={{
								marginLeft: 'auto',
								flexShrink: 0
							}}
							className={style.benefits_flex}
						>
							{dataBenefits.map(({ icon, title, subtitle }) => (
								<GridItem className={style.item}>
									<Typography
										className={''}
										component="span"
										weight="bold"
									>
										{icon}
									</Typography>
									<Typography
										color="states-info-info"
										className="m-0"
										weight="semi-bold"
										component="p"
										variant="p1"
									>
										{title}
									</Typography>
									<Typography
										className="m-0 mt-8"
										weight="bold"
										component="p"
										variant="p1"
									>
										{subtitle}
									</Typography>
								</GridItem>
							))}
						</Grid>
					)}
				</div>
			</Container>

			<div className={style.title_wrapper}>
				<div className={style.heading}>
					<Container className={style.container}>
						<div className={style.title}>
							<Typography
								className="m-0"
								component="h2"
								variant={sizes.title}
								textStyle="italic"
							>
								JOIN
							</Typography>
							<Typography
								className="m-0"
								component="h2"
								variant={sizes.title}
								textStyle="italic"
								weight="bold"
								color="league"
							>
								THE
							</Typography>
							<Typography
								className="m-0"
								component="h2"
								variant={sizes.title}
								textStyle="italic"
								weight="bold"
								color="league"
							>
								LEAGUE
							</Typography>
						</div>
					</Container>
					<div className={style.subtitle}>
						<Container className="pl-100">
							<Typography
								component="h5"
								variant={sizes.subtitle}
								textTransform="uppercase"
								color="states-info"
								className="m-0 ptb-28"
							>
								be part of a new era
								<br />
								in Daily Fantasy Sports
							</Typography>
						</Container>
					</div>
				</div>
			</div>
			<div className={style.banner_wrapper}>
				<Container>
					<div className={style.banner_block}>
						<div className={style.banner}>
							<div className={style.banner_header}>
								<Typography
									color={'league'}
									component="h5"
									variant="h5"
									variantXl="h6"
									variantMd="h6"
									className="m-0 mb-16"
									weight="semi-bold"
								>
									LIFETIME LEAGUE MEMBERSHIP
								</Typography>

								<div className={style.price}>
									<div className={cn(style.value, style.regular)}>
										<Typography component="p" color="inherit">
											REGULAR PRICE
										</Typography>
										<Typography
											component="h6"
											variant={'h6'}
											className="m-0"
											weight="bold"
											color="inherit"
										>
											<span className={style.crossed}>
												${regularPrice}
											</span>
										</Typography>
									</div>
									{preSalePrice && (
										<div className={cn(style.sale, style.value)}>
											<Typography component="p">
												SALE PRICE
											</Typography>
											<Typography
												component={'h6'}
												variant={'h6'}
												className="m-0"
												weight="bold"
											>
												${preSalePrice}
											</Typography>
										</div>
									)}
								</div>
							</div>
							<div className={style.banner_content}>
								<div className={style.bg}>
									<Typography className={''} component="div">
										<Typography className={''} component="div">
											<Typography
												component="h6"
												variant={'h6'}
												className="m-0"
												weight="bold"
											>
												<Typography
													component="span"
													color="league"
													weight="bold"
												>
													FREE Lifetime Entry
												</Typography>{' '}
												into Weekly LEAGUE contesting to win
												hundreds of thousands in cash prizes
											</Typography>
											<Typography component="ul" className="mt-24">
												<Typography component="li">
													1 lifetime raffle entry into Sports
													Experience Package draws for a chance
													to win an all-expenses paid trip to
													watch your favorite pro team play in
													their home city
												</Typography>
												<Typography component="li">
													Prize Multiplier to earn up to 2X cash
													payouts on all Weekly LEAGUE
													contesting
												</Typography>
												<Typography component="li">
													Ability to sell LEAGUE Membership in
													future in the open marketplace
												</Typography>
											</Typography>
											<Input
												className={cn('mt-24')}
												label="REFERRAL CODE"
												placeholder="Referral code"
												fullWidth={true}
												value={promo}
												onChange={onInputChange}
												color={'default'}
												highlighted={true}
												// disabled={true}
											/>
											{getJoinBtn(isMobile, promo)}
										</Typography>
									</Typography>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</div>
		</div>
	);
};
