import React, { useCallback } from 'react';
import { Typography } from 'components/ui/Typography';
import { EthereumIcon } from 'components/ui/Icon';

interface WalletItemProps {
	name: string;
	address: string;
	onDisconnect: (address: string) => any;
}

export function WalletsItem({ name, address, onDisconnect }: WalletItemProps) {
	const handleDisconnect = useCallback(
		() => onDisconnect(address),
		[address, onDisconnect]
	);

	return (
		<div>
			<i>
				<EthereumIcon />
			</i>

			<div>
				<Typography>{name}</Typography>
				<Typography>{address}</Typography>
			</div>

			<Typography
				variant="p3"
				color="danger"
				component="span"
				onClick={handleDisconnect}
			>
				Disconnect
			</Typography>
		</div>
	);
}
