import React from 'react';
import style from './body.module.scss';
import cn from 'classnames';
import { useMembershipExpiring } from "../../../hooks/useMembershipExpiring";
import { useGetProfileTermsQuery } from "../../../services/user.service";

export type Theme = 'presale' | 'memberExp';
interface BodyProps {
	theme?: Theme;
	children?: React.ReactNode;
	className?: string;
}

export const Body = ({ children, theme, className = '' }: BodyProps) => {
	const { data:profile } = useGetProfileTermsQuery();
	useMembershipExpiring(profile);
	return (
		<div
			className={cn(style.body, className, {
				[style.presale]: theme === 'presale',
				[style.memberExp]: theme === 'memberExp'
			})}
		>
			<div
				style={{
					backgroundColor: '#3A5481',
					textAlign: 'center',
					color: '#fff',
					fontSize: '16px',
					lineHeight: '22px',
					padding: '10px',
					zIndex: 10,
					display: 'none'
				}}
			>
				<span style={{ color: '#fff' }}>Welcome to Bounty 2.0!</span> If you wish
				to use Bounty 1.0, please click{' '}
				<a href="https://legacy.bountysports.com" style={{ color: '#5EC5C8' }}>
					here
				</a>
				.<br />
				Bounty's old website will only be available until September 11th.
			</div>
			{children}
		</div>
	);
};
