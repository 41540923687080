import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { customBaseQuery, selectDataFromResponse } from 'services/http.service';
import {
	LeaderboardResponse,
	NftToken,
	StatsResponse
} from 'common/interfaces/nft.interface';

const NFT_ENDPOINT = '/nft';

export const nftApi = createApi({
	reducerPath: 'nftApi',
	baseQuery: customBaseQuery,
	tagTypes: ['Token', 'Wallet'],
	endpoints: (build) => ({
		getLeaderboardUsers: build.query<
			LeaderboardResponse,
			{ userId?: string; period?: number; limit?: number; page: number }
		>({
			query: ({ userId, ...params }) => ({
				url: `${NFT_ENDPOINT}/leaderboard`,
				params: { ...params, user: userId }
			}),
			transformResponse: selectDataFromResponse
		}),
		getStats: build.query<StatsResponse, void>({
			query: () => `${NFT_ENDPOINT}/stats`,
			transformResponse: selectDataFromResponse
		}),
		getTokens: build.query<NftToken[], void>({
			query: () => `${NFT_ENDPOINT}/tokens`,
			providesTags: ['Token'],
			transformResponse: selectDataFromResponse
		}),
		getMembership: build.query<any, void>({
			query: () => `${NFT_ENDPOINT}/membership`,
			transformResponse: selectDataFromResponse
		}),
		getWallets: build.query<string[], void>({
			query: () => `${NFT_ENDPOINT}/wallets`,
			providesTags: ['Wallet'],
			transformResponse: selectDataFromResponse
		}),
		addWallet: build.mutation<void, string>({
			query: (address) => ({
				url: `${NFT_ENDPOINT}/wallets`,
				method: 'POST',
				body: {
					wallet: address
				}
			}),
			invalidatesTags: ['Token', 'Wallet']
		}),
		deleteWallet: build.mutation<void, string>({
			query: (address) => ({
				url: `${NFT_ENDPOINT}/wallets`,
				method: 'DELETE',
				body: {
					wallet: address
				}
			}),
			invalidatesTags: ['Token', 'Wallet']
		})
	})
});

export const {
	useGetStatsQuery,
	useGetLeaderboardUsersQuery,
	useGetTokensQuery,
	useGetMembershipQuery,
	useGetWalletsQuery,
	useAddWalletMutation,
	useDeleteWalletMutation
} = nftApi;
