import React, { useEffect } from 'react';
import usePlacesAutocomplete, { getDetails, Suggestion } from 'use-places-autocomplete';
import style from './places.module.scss';
import { Typography } from '../Typography';

export interface PreparedPlace {
	street: string;
	city: string;
	country: string;
	state: string;
	zipCode: string;
}

interface PlacesAutocompleteProps {
	value: string;
	onSelect: (place: PreparedPlace) => void;
}

export const PlacesAutocomplete = ({ value, onSelect }: PlacesAutocompleteProps) => {
	const {
		ready,
		value: autocompleteValue,
		suggestions: { status, data },
		setValue,
		clearSuggestions
	} = usePlacesAutocomplete({
		requestOptions: {
			types: ['address'],
			language: 'en',
			componentRestrictions: { country: ['us', 'ca'] }
		},
		debounce: 300
	});

	useEffect(() => {
		if (ready) {
			setValue(value, autocompleteValue !== value);
		}
	}, [value, ready]);

	const getPreparedPlace = (
		address: google.maps.GeocoderAddressComponent[]
	): PreparedPlace => {
		let street = '';
		let city = '';
		let country = '';
		let state = '';
		let zipCode = '';

		for (const component of address as google.maps.GeocoderAddressComponent[]) {
			const componentType = component.types[0];

			switch (componentType) {
				case 'street_number':
					street = `${component.long_name} ${street}`;
					break;
				case 'route':
					street += component.short_name;
					break;
				case 'postal_code':
					zipCode = `${component.long_name}${zipCode}`;
					break;
				case 'postal_code_suffix':
					zipCode = `${zipCode}-${component.long_name}`;
					break;
				case 'locality':
					city = component.long_name;
					break;
				case 'administrative_area_level_1':
					state = component.short_name;
					break;
				case 'country':
					country = component.long_name;
					break;
				default:
					break;
			}
		}

		return { street, city, country, state, zipCode };
	};

	const handleSelect = async (suggestion: Suggestion) => {
		const details = (await getDetails({
			placeId: suggestion.place_id,
			fields: ['address_components']
		})) as google.maps.places.PlaceResult;

		if (details.address_components) {
			const place = getPreparedPlace(details.address_components);
			setValue(place.street, false);
			onSelect(place);
		}

		clearSuggestions();
	};

	const renderSuggestions = () =>
		data.map((suggestion) => {
			const {
				place_id,
				structured_formatting: { main_text, secondary_text }
			} = suggestion;

			return (
				<Typography
					variant="p2"
					key={place_id}
					onClick={() => handleSelect(suggestion)}
					className={style.item}
				>
					<strong>{main_text}</strong> <small>{secondary_text}</small>
				</Typography>
			);
		});

	return (
		<div className={style.suggestion}>
			{status === 'OK' && (
				<div className="p-16">
					<Typography variant="body1" color="states-info">
						suggested addresses
					</Typography>
					{renderSuggestions()}
				</div>
			)}
		</div>
	);
};
