import { Container } from 'components/layout/Grid';
import { Typography } from 'components/ui/Typography';
import { Button } from 'components/ui/Button';
import style from './style.module.scss';

export const CTA = () => {
	return (
		<div className={style.cta}>
			<Container centered={true} align="center">
				<Typography
					variant="h1"
					variant2Xl="h2"
					variantXl="h3"
					textAlign="center"
					textTransform="uppercase"
					weight="bold"
					textStyle="italic"
					className="m-0"
				>
					Sign Up And{' '}
					<Typography component="span" color="desktop" weight="bold">
					Start Playing Today
					</Typography>
				</Typography>
				<Typography variant="p1" textAlign="center" className="m-0 mt-24">
					Pick Winning Teams | Play Friends & Fans | Win Cash Prizes
				</Typography>
				<Button className="mt-24" color="primary" style={{ minWidth: 369 }}>
					SIGN UP Today
				</Button>
			</Container>
		</div>
	);
};
