import React from 'react';
import classNames from 'classnames';

import { Typography } from 'components/ui/Typography';
import { Progress } from 'components/ui/Progress';
import { QuestionIcon } from 'components/ui/Icon';
import { Tooltip } from 'components/ui/Tooltip';

import style from './token-card.module.scss';

interface TokenCardProps {
	name: string;
	image: string;
	multiplier: number;
	totalSpent: number;
}

export function TokenCard({ name, image, multiplier, totalSpent }: TokenCardProps) {
	return (
		<div className={classNames(style.card, 'p-24')}>
			<div className={style.image}>
				<img src={image} alt={name} />
			</div>

			<div className={classNames(style.info, 'ptb-4')}>
				<Typography
					className={classNames('p-0 m-0 mb-20')}
					variant="h6"
					weight="bold"
				>
					{name}
				</Typography>

				<div className={style.items}>
					<div className={style.item}>
						<Typography className={classNames('p-0 m-0')} variant="p3">
							Multiplier
						</Typography>
						<Typography
							className={classNames('p-0 m-0')}
							variant="p1"
							weight="semi-bold"
						>
							{multiplier}x
						</Typography>
					</div>

					<div className={style.item}>
						<Typography
							className={classNames('p-0 m-0', style.itemWrap)}
							variant="p3"
						>
							Total Spent
							<Tooltip
								className={classNames(style.tooltip, style.itemTooltip)}
								side="bottom"
								trigger="hover"
								content={
									<Typography
										className={classNames(
											style.tooltipContent,
											'plr-8 ptb-4 m-0'
										)}
										variant="body2"
										color="neutral_black"
									>
										The Money spent on Entry Fees using the token
									</Typography>
								}
							>
								<Typography className="p-0 m-0">
									<QuestionIcon size={20} />
								</Typography>
							</Tooltip>
						</Typography>
						<Typography
							className={classNames('p-0 m-0')}
							variant="p1"
							weight="semi-bold"
						>
							{totalSpent}
						</Typography>
					</div>
				</div>

				<div className={classNames(style.infoItem, 'mt-24')}>
					<Typography
						className={classNames('p-0 m-0 mb-4', style.itemWrap)}
						variant="p3"
						textVAlign="center"
					>
						<span>Reach the next level</span>
						<Tooltip
							className={classNames(style.tooltip, style.itemTooltip)}
							side="right"
							trigger="hover"
							content={
								<Typography
									className={classNames(
										style.tooltipContent,
										'plr-8 ptb-4 m-0'
									)}
									variant="body2"
									color="neutral_black"
								>
									Spending your money on entry fees, you increase the
									Multiplier Cooficient
								</Typography>
							}
						>
							<Typography className="p-0 m-0">
								<QuestionIcon size={20} />
							</Typography>
						</Tooltip>
					</Typography>

					<div className={classNames(style.progress, 'mb-4')}>
						<Typography
							className={classNames('p-0 m-0')}
							variant="body2"
							component="span"
						>
							{multiplier}x
						</Typography>
						<Typography
							className={classNames('p-0 m-0')}
							variant="body2"
							component="span"
						>
							{multiplier + 0.1}x
						</Typography>
					</div>
					<Progress
						height={4}
						value={multiplier}
						max={multiplier + 0.1}
						color="neutral_02"
					/>
				</div>
			</div>
		</div>
	);
}
