import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { SortConfig } from 'hooks/useSortable';
import { Contest } from 'common/interfaces/contest.interface';
import { RootState } from 'store/index';
import { contestApi } from 'services/contest.service';

export type ContestSortConfig = SortConfig<keyof Contest>;

interface ContestState {
	sorting?: ContestSortConfig;
	filters: {
		sports: string[];
		entryFee: [number, number];
		prize: [number, number];
	};
}

const initialState: ContestState = {
	filters: {
		sports: [],
		entryFee: [0, 500],
		prize: [0, 10000]
	}
};

export const contestSlice = createSlice({
	name: 'contest',
	initialState,
	reducers: {
		setFilters: (state, action: PayloadAction<Partial<ContestState['filters']>>) => {
			state.filters = { ...state.filters, ...action.payload };
		},
		setSorting: (state, action: PayloadAction<ContestSortConfig>) => {
			state.sorting = action.payload;
		},
		resetFilters: (state) => {
			state.filters = initialState.filters;
		}
	}
});
export const { setFilters, setSorting, resetFilters } = contestSlice.actions;
export const selectContestsSorting = (state: RootState) => state.contest.sorting;
export const selectContestFilters = (state: RootState) => state.contest.filters;

export const selectContestsResult = contestApi.endpoints.getAvailableContests.select();
export const selectAvailableContests = createSelector(
	selectContestsResult,
	(result) => result.data || []
);

const isSumInRange = (sum: number, range: [number, number]) => {
	return sum >= range[0] && sum <= range[1];
};
const isSelectedSport = (sport: string, sports: string[]) => {
	return sports.length ? sports.includes(sport) : true;
};

export const selectFilteredContests = createSelector(
	selectContestFilters,
	selectAvailableContests,
	(filters, items) =>
		items.filter(
			(item) =>
				!item.isFeatured &&
				isSelectedSport(item.sport || '', filters.sports) &&
				isSumInRange(item.prize, filters.prize) &&
				isSumInRange(item.entryFee, filters.entryFee)
		)
);
export const selectFeaturedContests = createSelector(selectAvailableContests, (items) =>
	items.filter((item) => item.isFeatured)
);
export const selectNftContests = createSelector(selectAvailableContests, (items) =>
	items.filter((item) => item.isNFT)
);

const createEnrolledContestsSelector = (isNft?: boolean) =>
	createSelector(selectAvailableContests, (items) => {
		const live: Contest[] = [];
		const upcoming: Contest[] = [];

		items.forEach((item) => {
			if (!item.enrolled || (isNft && !item.isNFT)) return;
			item.isClosed && !item.isContestEnded ? live.push(item) : upcoming.push(item);
		});

		return { live, upcoming };
	});

export const selectEnrolledContests = createEnrolledContestsSelector();
export const selectNftEnrolledContests = createEnrolledContestsSelector(true);
