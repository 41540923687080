export const variantsMapping = {
	big: 'h1',
	hero: 'h1',
	main: 'h1',

	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h3modal: 'h3',
	h4: 'h4',
	h5: 'h5',
	h6: 'h6',

	p1: 'p',
	p2: 'p',
	p3: 'p',

	body1: 'p',
	body2: 'p',
	body3: 'p',

	small: 'p'
};

type FontWeight = 'semi-bold' | 'bold' | 'regular' | 'bolder';
type Color =
	| 'primary'
	| 'league'
	| 'primary-mobile'
	| 'neutral_black'
	| 'danger'
	| 'Neutral-07'
	| 'States-Info'
	| 'States-Info-BG'
	| 'Neutral-Black'
	| 'desktop'
	| 'neutral_07'
	| 'neutral_03'
	| 'states-info'
	| 'states-info-info'
	| 'color-default'
	| 'inherit'
	| 'states-success';

export interface TypographyProps {
	component?: keyof JSX.IntrinsicElements;
	className?: string;
	variant?: keyof typeof variantsMapping;
	variant2Xl?: keyof typeof variantsMapping;
	variantXl?: keyof typeof variantsMapping;
	variantMd?: keyof typeof variantsMapping;
	weight?: FontWeight;
	color?: Color;
	centered?: boolean;
	textTransform?: string;
	textStyle?: string;
	textAlign?: string;
	textVAlign?: string;
	onClick?: any;
	cursorPointer?: boolean;
}
