import { Body } from 'components/layout/Body';
import { Main } from 'components/layout/Main';
import { Header } from 'components/layout/Header';
import { Footer } from 'components/layout/Footer';
import { Banner } from 'pages/ComponentsGrid/Banner';
import { Features } from 'pages/ComponentsGrid/Features';
import { LukeBannerFeature } from 'pages/ComponentsGrid/LukeBannerFeature';
import { CTA } from 'pages/ComponentsGrid/CTA';
import { Reviews } from 'pages/ComponentsGrid/Reviews';
import { Button } from 'components/ui/Button';
import React from 'react';
import style from './partials/style.module.scss';
import { Logo } from '../../components/ui/Logo';
import classNames from 'classnames';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export const London5 = () => {
	const { isMobile } = useWindowDimensions();
	const getSignUpBtn = (styleClass = 'mt-16') => {
		return (
			<Button
				href="/london5/sign-up/LONDON5"
				state={{
					from: '/london5',
					redirectTo: isMobile ? '/download-app' : '/'
				}}
				color="primary"
				className={`xl:ptb-12 ${styleClass}`}
				fullWidth={isMobile}
			>
				Sign Up & Get $5
			</Button>
		);
	};
	return (
		<Body>
			<Header signUpBtn={getSignUpBtn('')} />
			<header className={classNames(style.mobile_header)}>
				<Logo />
				<Button
					key="claim-league-header"
					size="md"
					variant="neutral"
					// color="primary"
					href="/london5/sign-up/LONDON5"
					state={{
						from: '/london5',
						redirectTo: isMobile ? '/download-app' : '/'
					}}
				>
					Sign Up
				</Button>
			</header>
			<Main>
				<Banner showReferral={true} bannerButton={getSignUpBtn()} />
				<Features customButton={getSignUpBtn()} />
				<LukeBannerFeature />
				{!isMobile && <CTA />}
				<Reviews />
				<Footer />
			</Main>
		</Body>
	);
};
