import React, { useState } from 'react';
import { Header } from 'components/layout/Header';
import { Body } from 'components/layout/Body';
import { Main } from 'components/layout/Main';
import { Container } from 'components/layout/Grid';
import { Typography } from 'components/ui/Typography';
import style from './restricted.module.scss';
import { Button } from 'components/ui/Button';
import { Logo } from 'components/ui/Logo';
import { SupportRestrictedModal } from 'components/modals/SupportRestricted';
import { useModal } from '@ebay/nice-modal-react';
import { Link } from '../../components/ui/Link';
import { ReactComponent as DefaultLogo } from 'assets/images/logo.svg';
import { Icon } from '../../components/ui/Icon';
function RestrictedPage() {
	const [option1, setoption1] = useState(false);
	const supportModal = useModal(SupportRestrictedModal);
	function handelClick() {
		setoption1(true);
	}
	return (
		<Body>
			<Main>
				<Container centered className="ptb-52">
					<div className={style.restricted}>
						<div className={style.logo}>
							<Link href="/">
								<DefaultLogo />
							</Link>
						</div>

						<Typography variant="h3" weight="bold">
							THANK YOU FOR VISITING BOUNTY SPORTS!
						</Typography>
						{!option1 ? (
							<div>
								<Typography variant="h5" weight="bold">
									We’ve detected that you’re in a restricted region.
									Please choose from one of the following options
								</Typography>
								<div className={style.options}>
									<Button
										onClick={() => {
											handelClick();
										}}
										className="mr-24 mtb-8"
									>
										WHY AM I BEING BLOCKED
									</Button>
									<Button
										className="mr-24 mtb-8"
										onClick={() => {
											supportModal.show();
										}}
									>
										CONTACT TO SUPPORT
									</Button>
								</div>
							</div>
						) : (
							<div className={style.whyblocked}>
								<Typography variant="h5" weight="bold">
									Our site has detected you’re in a restricted region.
									We are currently available in the following states:
								</Typography>
								<Typography variant="h5" weight="bold">
									(1)Florida (2) Texas (3) Alaska (4) California (5)
									Georgia (6)Illinois (7) Kentucky (8) Minnesota (9)
									Nebraska (10) New Mexico (11) North Carolina (12)
									North Dakota (13) Oklahoma (14) Oregon (15) Rhode
									Island (16) South Carolina (17) South Dakota (18) Utah
									(19) West Virginia (20) Wisconsin (21) Wyoming (22)
									Kansas (23) Maryland (24) Massachusetts (25) Canada
									(26) Arkansas
								</Typography>
								<Typography variant="h5" weight="bold">
									Are you currently in one of these states? There may be
									an issue with your connection. Please take the
									following steps to permit access.
								</Typography>
								<Typography variant="h5" weight="bold">
									A. Turn off your VPN if you are currently using one
								</Typography>
								<Typography variant="h5" weight="bold">
									Have you been able to sign-up previously and are now
									blocked?
								</Typography>
								<Typography variant="h5" weight="bold">
									If you are in an available region but have now been
									blocked, it may be due to your IP being hosted by an
									internet service provider in a restricted region.
									Turning on your location services allows us to check
									and see if you’re in an available region.
								</Typography>
								<Typography variant="h5" weight="bold">
									Still having difficulty accessing Bounty Sports?
									Please contact support for assistance.
								</Typography>
								<div className={style.options}>
									<Button
										onClick={() => {
											supportModal.show();
										}}
									>
										CONTACT TO SUPPORT
									</Button>
								</div>
							</div>
						)}
					</div>
				</Container>
			</Main>
		</Body>
	);
}

export default RestrictedPage;
