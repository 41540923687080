import { LifeTimeMemberships } from 'components/ui/Memberships';
import { Container } from 'components/layout/Grid';
import style from './style.module.scss';
import { Button } from 'components/ui/Button';
import { ArrowRightIcon } from 'components/ui/Icon';
import { FlipTimer } from 'components/ui/FlipTimer';
import cn from 'classnames';
import hbService, { LOCATION_TYPE } from 'services/hubspot.service';

export const SaleMemberships = () => {
	return (
		<div className={cn('bg-neutral_01')}>
			<Container className={style.salemember}>
				<div className={style.salemember_price}>
					<LifeTimeMemberships preSalePrice="299.99" />
					<Button
						className="mt-16"
						fullWidth
						size="txl"
						variant="presale"
						disableShadow
						href="/sale/membership"
						state={{
							from: '/sale/membership'
						}}
						onClick={() => {
							hbService.hbRegisterClick(LOCATION_TYPE.footer);
						}}
						endIcon={<ArrowRightIcon size={18} fillClass="neutral-black" />}
					>
						claim sale offer
					</Button>
				</div>
				<FlipTimer
					// countDays={10}
					timestamp={Date.parse('12/31/2022')}
				/>
			</Container>
		</div>
	);
};
