import React from 'react';
import {
	DropdownMenuTrigger,
	DropdownMenuTriggerProps
} from '@radix-ui/react-dropdown-menu';
import style from './dropdown.module.scss';

type Props = {} & DropdownMenuTriggerProps;

export const DropdownToggle = ({ children, ...props }: Props) => {
	return (
		<DropdownMenuTrigger
			{...props}
			className={props.className ? props.className : style.toggle}
		>
			{children}
		</DropdownMenuTrigger>
	);
};
