import React from 'react';
import { Modal } from 'components/ui/Modal';
import { VerifyOtpForm } from 'components/forms/VerifyOtpForm';
import { Link } from 'components/ui/Link';
import { Typography } from 'components/ui/Typography';
import { useLoggedIn } from '../../../hooks/useLoggedIn';

type Props = {
	phone: string;
	onSuccess: () => void;
};

export function VerifyPhoneModal({ phone, onSuccess }: Props) {
	const { redirectTo, state } = useLoggedIn();

	return (
		<Modal
			className="p-40 width-popupDesktop"
			titleClassName="ptb-24 m-0"
			title="Verify phone"
			isOpen
			forRoute
		>
			<VerifyOtpForm type="phone" to={phone} onSuccess={onSuccess} />
			<Typography variant="p2" textAlign="center">
				<Typography className="pr-8" variant="p2" component="span">
					I already have an account.
				</Typography>
				<Link underline="always" href="/login" color="desktop">
					<Typography
						color={state?.from?.includes('london') ? 'league' : 'desktop'}
						variant="p2"
						component="span"
					>
						Log In
					</Typography>
				</Link>
			</Typography>
		</Modal>
	);
}
