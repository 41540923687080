import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from 'components/ui/Modal';
import { ChangePasswordForm } from 'components/forms/PasswordForm';

export const ChangePasswordModal = NiceModal.create(() => {
	const modal = useModal();

	return (
		<Modal
			title="Change Password"
			className="p-40 width-popupDesktop"
			titleClassName="ptb-24 m-0"
			isOpen={modal.visible}
			onClose={modal.hide}
		>
			<ChangePasswordForm onSuccess={modal.hide} />
		</Modal>
	);
});
