import { createApi } from '@reduxjs/toolkit/dist/query/react';
import hbService from 'services/hubspot.service';

import { ChangePassword } from 'common/interfaces/auth.interface';
import {
	CommunicationOptions,
	SupportRequest,
	UserProfile,
	ReferralItem,
	BankDetails,
	WithdrawalRequest
} from 'common/interfaces/user.interface';
import httpService, {
	customBaseQuery,
	selectDataFromResponse,
	ServerResponse
} from './http.service';
import { storageService } from './storage.service';

const USER_ENDPOINT = '/users';

const changePassword = async (payload: ChangePassword): Promise<void> => {
	const { data } = await httpService.post<ServerResponse<number>>(
		`${USER_ENDPOINT}/changepassword`,
		payload
	);
	if (data.status !== 200) {
		return Promise.reject(new Error(data.message));
	}
};

const getCommunicationOptions = async (): Promise<CommunicationOptions> => {
	const { data } = await httpService.post<ServerResponse<CommunicationOptions>>(
		`${USER_ENDPOINT}/getcommunicationpref`
	);
	return data.data;
};

const updateCommunicationOptions = async (
	payload: CommunicationOptions
): Promise<void> => {
	await httpService.post(`${USER_ENDPOINT}/editcommunicationpref`, {
		...payload,
		mobile_push_options: JSON.stringify(payload.mobile_push_options),
		sports_pref: JSON.stringify(payload.sports_pref),
		text_msg_options: JSON.stringify(payload.text_msg_options),
		app_type: 1
	});
};

const sendSupportMail = async (payload: SupportRequest): Promise<void> => {
	await httpService.post(`${USER_ENDPOINT}/sendsupportmail`, payload);
};

export const userApi = createApi({
	reducerPath: 'userApi',
	baseQuery: customBaseQuery,
	tagTypes: ['Bank', 'Profile'],
	endpoints: (build) => ({
		getProfile: build.query<UserProfile, void>({
			query: () => `${USER_ENDPOINT}/profile`,
			providesTags: ['Profile'],
			transformResponse: selectDataFromResponse,
			async onCacheEntryAdded(arg, { cacheDataLoaded }) {
				const data = await cacheDataLoaded;
				if (data.data) {
					hbService.hbIdentify(data.data.email);
				}
			}
		}),
		getProfileTerms: build.query<UserProfile, void>({
			query: () => `${USER_ENDPOINT}/profile`,
			providesTags: ['Profile'],
			transformResponse: selectDataFromResponse
		}),
		updateProfile: build.mutation<void, Partial<UserProfile>>({
			query: (arg) => ({
				url: `${USER_ENDPOINT}/profile`,
				method: 'PATCH',
				body: arg
			}),
			invalidatesTags: ['Profile']
		}),
		getReferrals: build.query<ReferralItem[], string>({
			query: (code) => `${USER_ENDPOINT}/referrals?code=${code}`,
			transformResponse: selectDataFromResponse
		}),
		getBankDetails: build.query<BankDetails, void>({
			query: () => ({
				url: `${USER_ENDPOINT}/getbankdetails`,
				method: 'POST'
			}),
			providesTags: ['Bank'],
			transformResponse: selectDataFromResponse
		}),
		addBankDetails: build.mutation<any, BankDetails>({
			query: (arg) => ({
				url: `${USER_ENDPOINT}/savebankdetails`,
				method: 'POST',
				body: {
					user_id: storageService.getUserId(),
					...arg
				}
			}),
			invalidatesTags: ['Bank']
		}),
		updateBankDetails: build.mutation<void, BankDetails>({
			query: (arg) => ({
				url: `${USER_ENDPOINT}/updatebankdetails`,
				method: 'POST',
				body: {
					user_id: storageService.getUserId(),
					...arg
				}
			}),
			invalidatesTags: ['Bank']
		}),
		withdrawBalance: build.mutation<void, WithdrawalRequest>({
			query: (arg) => ({
				url: `${USER_ENDPOINT}/withdrawal`,
				method: 'POST',
				body: {
					user_id: storageService.getUserId(),
					...arg
				}
			}),
			invalidatesTags: ['Profile']
		})
	})
});

export const withdrawalHistory = async (page: Number = 0): Promise<any> => {
	const { data } = await httpService.post(
		`${USER_ENDPOINT}/mywithdrawalrequests`,
		{
			page: page
		},
		{ headers: { Authorization: String(storageService.getAccessToken()) } }
	);

	return data;
};

export const depositHistory = async (page: Number = 0): Promise<any> => {
	const { data } = await httpService.post(
		`${USER_ENDPOINT}/transactions`,
		{
			page: page,
			type: 10,
			user_id: storageService.getUserId()
		},
		{ headers: { Authorization: String(storageService.getAccessToken()) } }
	);

	return data;
};

export const checkLocation = async (): Promise<any> => {
	const { data } = await httpService.get(`${USER_ENDPOINT}/checklocation`);
	return data;
};

export const acceptNewTerms = async (): Promise<any> => {
	const { data } = await httpService.post(
		`${USER_ENDPOINT}/acceptnewterms`,
		{
			user_id: storageService.getUserId()
		},
		{ headers: { Authorization: String(storageService.getAccessToken()) } }
	);

	return data;
};

export const {
	useGetProfileQuery,
	useGetReferralsQuery,
	useGetBankDetailsQuery,
	useUpdateProfileMutation,
	useAddBankDetailsMutation,
	useUpdateBankDetailsMutation,
	useWithdrawBalanceMutation,
	useGetProfileTermsQuery
} = userApi;

const userService = {
	changePassword,
	getCommunicationOptions,
	updateCommunicationOptions,
	sendSupportMail,
	withdrawalHistory
};
export default userService;
