import { Swiper, SwiperSlide } from 'swiper/react';

import { Container } from 'components/layout/Grid';
import { Review } from 'components/ui/Review';
import { Avatar } from 'components/ui/Avatar';
import { Typography } from 'components/ui/Typography';

import Review1 from 'assets/images/reviews/reviewIcon.jpeg';
import Review2 from 'assets/images/reviews/reviewIcon.jpeg';
import Review3 from 'assets/images/reviews/reviewIcon.jpeg';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export const Reviews = () => {
	const { isMobile } = useWindowDimensions();

	return (
		<div
			style={{
				padding: isMobile ? '0' : '88px 0 80px'
			}}
		>
			<Container centered={true}>
				<Typography
					variant="h4"
					weight="bold"
					textAlign={isMobile ? 'center' : ''}
				>
					Putting Our{' '}
					<Typography component="span" color="desktop" weight="bold">
						Fans First
					</Typography>
				</Typography>
				<Swiper spaceBetween={170} slidesPerView={isMobile ? 1 : 3}>
					<SwiperSlide>
						<Review
							stars={5}
							quote="“Easy to use, lots of options each week depending if you want to go big or small. Highly recommend.”"
							author="- Cooley34 "
							avatar={<Avatar src={Review1} border="desktop" size="lg" />}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<Review
							stars={5}
							quote="“Solid experience. It’s great and simple to use. I rarely write reviews but this one earned it. 5 stars.
”"
							author="- DrewC"
							avatar={<Avatar src={Review2} border="desktop" size="lg" />}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<Review
							stars={5}
							quote="“I suggested this app to my husband who is a huge sports fan. He LOVES it and can’t get enough! Very easy to use!”"
							author="- carvette333"
							avatar={<Avatar src={Review3} border="desktop" size="lg" />}
						/>
					</SwiperSlide>
				</Swiper>
			</Container>
		</div>
	);
};
